import React, { useCallback, useEffect, useState } from 'react';
import { finalize } from 'rxjs';
import { hideLoader, showLoader } from '../../../services/core/loader-service';
import { productSearch } from '../../../services/duty-calc-service';
import * as constantsType from "../../../utility/constants/common-constants";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { showWarnToaster } from '../../../utils/toaster/toaster';

export const ProductSearchModal = (props) => {

    let [products, setProductList] = useState([]);
    let [selectProducts, setSelectProduct] = useState([]);


    useEffect(() => {
        fetchProducts();

    }, [])

    const fetchProducts = (params = {},) => {
        showLoader();

        let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE };
        let subscription = productSearch({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let productList = response.results;

                setProductList(productList);

            });
    };

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    let searchHsCode = (query) => {
        console.log(query, "query");
        fetchProducts({
            q: query
        })
    }

    const optimizedFn = useCallback(debounce(searchHsCode), []);



    function onConfirmHandler() {
        if (selectProducts.length > 1) {
            showWarnToaster("Max Selection is 1");
            return
        }

       
        props.onClose(selectProducts);
        props.hideModal(false)
    }



    const columns = [
        // { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'hs_code',
            headerName: 'HS Code',
            width: 150,
            valueGetter: (params) =>
                `${params.row.hs_code || ''}`
        },
        {
            field: 'product_desc',
            headerName: 'Product Description',
            width: 300,
        },

    ];



    return <>
        <input type='text' onChange={(e) => optimizedFn(e.target.value)} placeholder='Search Hs code' className='input-control'></input>
        <br></br><br></br>
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={products}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                sx={{
                    fontWeight:400,
                    boxShadow: 2,
                    '& .MuiDataGrid-columnHeaderTitle': {
                     fontWeight:600
                    },
                  }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    console.log(newRowSelectionModel, "newRowSelectionModel")
                    setSelectProduct(newRowSelectionModel);

                }}

            />
        </div><br></br>
        <Button className='btn-theme' variant="contained" onClick={() => onConfirmHandler()}>Select</Button>
    </>
}