import React, { useCallback, useEffect, useState } from 'react';
import { finalize } from 'rxjs';
import { hideLoader, showLoader } from '../../../services/core/loader-service';
import { getHsCode, productLocator } from '../../../services/duty-calc-service';
import * as constantsType from "../../../utility/constants/common-constants";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { showWarnToaster } from '../../../utils/toaster/toaster';

export const HsCodeModal = (props) => {

    let [hsCodes, setHsCodeList] = useState([]);
    let [selectHsCode, setSelectHsCode] = useState([]);
    let [countries, setcountryList] = useState([]);
    const [showHsCodeModal, setShowHsCodeModal] = useState(false);
    const [showProductSearchModal, setShowProductSearchModal] = useState(false);


    useEffect(() => {
        fetchHsCode();

    }, [])

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 1000);
        };
    };

    const fetchHsCode = (params = {},) => {
        showLoader();

        let hs_code_filter = props.initialData.hs_code_filter ? props.initialData.hs_code_filter : {};
        let filter = { ...params, ...hs_code_filter, app_source: constantsType.ADMIN_APP_SOURCE };

        let subscription = productLocator({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let hscodeList = response.results;

                setHsCodeList(hscodeList);

            });
    };

    let searchHsCode = (query) => {
        console.log(query, "query");
        const valueType = !isNumeric(query) ? 'descroption' : 'number';

        let obj = {
            value_exists: true
        }

        let hs_code_filter = props.initialData.hs_code_filter ? props.initialData.hs_code_filter : null;
        if(hs_code_filter){
            obj['level'] = 8;
        }

        if (valueType == 'number') {
            obj['hs_code'] = query;
        } else {
            obj['product_desc'] = query;
        }
        fetchHsCode(obj)
    }

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
      }

    const optimizedFn = useCallback(debounce(searchHsCode), []);



    function onConfirmHandler() {
        if (selectHsCode.length > 1) {
            showWarnToaster("Max Selection is 1");
            return
        }
        const selectedId = selectHsCode.length ? selectHsCode.pop() : null;
        let value = hsCodes.filter((val) => val.id == selectedId).map((val) => val.code)
        // debugger
        localStorage.setItem('selectedHsCode', value[0]);
        props.onClose(value);
        props.hideModal(false)
    }



    const columns = [
        // { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'code',
            headerName: 'HS Code',
            headerClassName: 'datagrid-head',
            width: 150,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300,
        },

    ];



    return <>

        <input type='text' onChange={(e) => optimizedFn(e.target.value)} placeholder='Enter Product Keyword' className='input-control'></input>
        <br></br><br></br>
        <div style={{ height: 330, width: '100%' }}>

            <DataGrid
                rows={hsCodes}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                sx={{
                    fontWeight: 400,
                    boxShadow: 2,
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600
                    },
                }}

                onRowSelectionModelChange={(newRowSelectionModel) => {
                    if (newRowSelectionModel.length > 1) {
                        showWarnToaster("Max Selection is 1");
                        return
                    }

                    setSelectHsCode(newRowSelectionModel);

                }}



            />
        </div><br></br>
        <Button className='btn-theme' variant="contained" onClick={() => onConfirmHandler()}>Select</Button>
    </>

}