import React from 'react'

export const AboutUs = () => {
  return (
    <section className='about-wrapper'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='title'>About page</h2>
          </div>
        </div>
      </div>
    </section>
  )
}
