import React, { useState, useEffect, useCallback } from 'react';
import "../../../static/css/pages/users/user.css";
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { finalize } from "rxjs";
import "../../../../lib/static/css/global/global.css";
import { getCustomDutyRates, getCustomDutyTotal, getImportPolicy, getTradeRamides } from '../../../services/duty-calc-service';
import { TradeRemedies } from '../SubComponents/TradeRemedies';
import { RegulatoryRequirement } from '../SubComponents/RegulatoryRequirements';
import { Button, Popover, Typography } from '@mui/material';
import { ImportPolicyModal } from '../../Common/ImportPolicy/ImportPolicyModal';
import Modal from '../../Common/Modal/Modal';
import { GstModal } from '../../Common/GST/GstModal';
import { ReportIncorrectModal } from '../../Common/ReportIncorrect/ReportIncorrectModal';


export const Step2 = () => {
    const [showPolicyModal, setShowPolicyModal] = useState(false);

    const [rateList, setRateList] = useState([]);
    const [totalCustomDuty, setTotalCustomDuty] = useState(0);
    const [totalPriferCustomDuty, setTotalPriferCustomDuty] = useState(0);
    const [showGstModal, setGstModal] = React.useState(false);
    const [showIncorrectReportModal, setIncorrectReportModal] = React.useState(false);
    const [selectedGST, setSelectedGst] = React.useState(false);
    const [policy, setPolicy] = useState({});
    const [isOtherUnitExists, setOtherUnitExists] = useState(false);
    const [BcdVal, setBcdVal] = useState('');
    const [selectionArr, setSelectionCaseArr] = useState([]);
    const [dynamicColumnName, SetDynamicColumnName] = useState('');
    const [dynamicColumnKey, SetDynamicColumnKey] = useState('');
    const [selectionTotalRes, setSelectionTotalRes] = useState({
        isSelection: false,
        total: 0,
        pta_total: 0
    });

    const PTA = () => {
        let data = localStorage.getItem("ptaData");
        return (data) ? JSON.parse(data) : {};
    }

    const GST = () => {
        let data = localStorage.getItem("selectedGST");
        return (data) ? JSON.parse(data) : null;
    }

    const dutyCess = (column_key) => {
        let data = localStorage.getItem(column_key + "-custom-cess");
        let val = (data) ? JSON.parse(data) : null;
        console.log(val, "val")
        return val;
    }



    const updateTotal = (res) => {
        let rateListArr = [...rateList];
        rateListArr.push(res);
        let finalRateList = rateListArr.filter((obj) => {
            return obj.value
        });
        setTotalCustomDuty(calculateCustomDuty(finalRateList));
    }


    useEffect(() => {

        fetchCustomDutyRates();
        fetchImportPolicy();


        const savedGst = GST()
        if (savedGst) {
            updateTotal(savedGst);
        }

    }, []);




    const getGstObj = () => {
        let data = localStorage.getItem("selectedGST");
        return (data) ? JSON.parse(data) : null;
    }



    const fetchCustomDutyRates = (params = {},) => {
        showLoader();

        let hscode = localStorage.getItem('selectedHsCode');
        let ptaData = PTA();
        let country = localStorage.getItem('selectedCountry');
        let subscription = getCustomDutyRates(hscode, ptaData, country)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;

                console.log(response, "response");
                let totalCusDuty = response.data ? response.data['total_custom_duty'] : null;
                let totalPreferentialCusduty = response.data ? response.data['total_preferential_duty'] : null;

                setTotalCustomDuty(totalCusDuty);
                setTotalPriferCustomDuty(totalPreferentialCusduty);

                let rateList = response.data ? response.data.duties : [];

                let unitArr = rateList.filter((list) => {
                    return list.unit != "%"
                });

                let selectgst = rateList.filter((list) => {
                    return list.tariff_text == "Select GST";
                });

                let bcdObj = rateList.find((list) => {
                    return list.column_key == 'BCD';
                });

                localStorage.setItem('query_id', response.data.query_id);
                localStorage.setItem('BCD_Unit', bcdObj ? bcdObj.unit : '')
                setBcdVal(bcdObj);




                if (selectgst.length) {

                    localStorage.setItem('gstfield', JSON.stringify(selectgst[0]));
                } else {
                    localStorage.removeItem('gstfield')
                }

                // other than % - hide ->means unit arr have length

                let unitVal = unitArr.length ? true : false;
                setOtherUnitExists(unitVal);
                localStorage.setItem('isUnitOtherthanPerctange', unitVal);
                const unit = unitArr.length ? unitArr[0]['unit'] : '%';
                localStorage.setItem('OtherthanPerctangeUnit', unit);


                setRateList(rateList);

                let selectionCaseArr = rateList.map((obj) => {
                    return {
                        key: obj.column_key,
                        has_custom_value: obj.has_custom_value
                    }
                }).filter((row) => {
                    return row.has_custom_value
                });
                setSelectionCaseArr(selectionCaseArr)

                let customCessArr = rateList.filter((list) => {
                    return list.has_custom_value
                }).map((obj) => {
                    return {
                        key: obj.column_key,
                        name: obj.column_name
                    }
                })
                localStorage.setItem('CustomCess', JSON.stringify(customCessArr));

                rateList.forEach((obj) => {
                    if (obj.has_custom_value) {
                        if (obj.column_key.toLowerCase() == 'gst') {
                            let selectedGST = localStorage.getItem('selectedGST') ? JSON.parse(localStorage.getItem('selectedGST')) : {};
                            obj.value = selectedGST.value;
                        }

                        if (obj.column_key.toLowerCase() !== 'gst') {
                            let otherObj = localStorage.getItem(obj.column_key + '-custom-cess') ? JSON.parse(localStorage.getItem(obj.column_key + '-custom-cess')) : {};
                            obj.value = otherObj.value;
                        }
                    }
                })
                // calculateTotal();
                // checkAndGetTotal(null,true,selectionCaseArr);
                // setTotalCustomDuty(calculateCustomDuty(rateList));
            });
    };





    const fetchImportPolicy = (params = {},) => {
        showLoader();

        let hscode = localStorage.getItem('selectedHsCode');
        let subscription = getImportPolicy(hscode)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let data = response.results[0];
                setPolicy(data);

            });
    };

    const calculateCustomDuty = (arr) => {
        let val = 0;
        arr.forEach((data) => {
            if (data.value) {
                val += parseFloat(data.value)
            }
        })

        return val;
    }


    const openGSTModal = (column_name, column_key) => {
        SetDynamicColumnName(column_name);
        SetDynamicColumnKey(column_key)
        setGstModal(true)
    }


    const onGstModalDismiss = (res) => {



        checkAndGetTotal(res)

        if (dynamicColumnKey.toLowerCase() == 'gst') {
            localStorage.setItem("selectedGST", JSON.stringify(res));
        } else {
            localStorage.setItem(dynamicColumnKey + "-custom-cess", JSON.stringify(res));
        }
        setSelectedGst(res);
        calculateTotal(res);
    }

    const checkAndGetTotal = (res, fromModel = false, rawSelectionArr) => {
        console.log(res, "res");
        // debugger
        if (selectionArr.length) {
            selectionArr.forEach((obj) => {
                if (obj.key === res.column_key) {
                    obj['value'] = res.value;
                    obj['unit'] = res.unit;
                }
            });
            setSelectionCaseArr(selectionArr)
        }

        if (fromModel) {
            debugger
            rawSelectionArr.forEach((obj) => {
                if (obj.column_key == 'GST') {
                    let selectedGST = localStorage.getItem('selectedGST');
                    if (selectedGST) {
                        let gstObj = JSON.parse(selectedGST);
                        obj['value'] = gstObj.value;
                        obj['unit'] = gstObj.unit;
                    } else {
                        return null;
                    }
                } else {
                    let selectedCess = localStorage.getItem(obj.column_key + '-custom-cess');
                    if (selectedCess) {
                        let cessObj = JSON.parse(selectedCess);
                        obj['value'] = cessObj.value;
                        obj['unit'] = cessObj.unit;
                    } else {
                        return null;
                    }
                }
            });
            setSelectionCaseArr(rawSelectionArr);
        }

        let isNotFilled = selectionArr.find((row) => {
            return !row.hasOwnProperty('value')
        })

        if (!isNotFilled) {
            fetchCustomDutyTotal();
        }
    }

    const onincorrectReportModalDismiss = (res) => {
        console.info(res)
    }

    const reportIncorrect = () => {
        setIncorrectReportModal(true)
    }

    const getModelName = () => {
        return `Applicable ${dynamicColumnName} Rates`;
    }

    const calculateTotal = (obj = null) => {

        rateList.forEach((item) => {

            if (obj) {
                if (item.column_name == obj.column_name) {
                    item.value = obj.value
                }
            }
        });
        // setTotalCustomDuty(calculateCustomDuty(JSON.parse(JSON.stringify(rateList))));
    }

    const fetchCustomDutyTotal = (params = {},) => {
        showLoader();
        const ptaData = PTA();
        const hscode = localStorage.getItem('selectedHsCode');

        let payload = {
            hs_code: hscode,
            custom_values: {
                preferential_rates: {}
            },

        };


        if (Object.entries(ptaData).length) {
            payload.custom_values['preferential_rates']['specific_rate'] = ptaData.specific_rate || "";
            payload.custom_values['preferential_rates']['tariff_text'] = ptaData.tariff_text || "";
            payload.custom_values['preferential_rates']['value'] = ptaData.value !== null ? ptaData.value : "";
            payload.custom_values['preferential_rates']['unit'] = ptaData.unit;
        };

        selectionArr.forEach((obj) => {
            payload.custom_values[obj.key] = {
                specific_rate: obj.value,
                tariff_text: obj.value,
                value: obj.value,
                unit: obj.unit
            }
        })

        console.log(payload, "payload")
        let subscription = getCustomDutyTotal(payload)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;

                let totalCusDuty = response.data ? response.data['total_custom_duty'] : null;
                let totalPreferentialCusduty = response.data ? response.data['total_preferential_duty'] : null;
            // alert("fetch calculation")
                localStorage.setItem('calculated_total', response.data ? JSON.stringify(response.data) : "");
                setTotalCustomDuty(totalCusDuty);
                setTotalPriferCustomDuty(totalPreferentialCusduty);

            })
    }

    const getTotal = (key) => {
        let data = localStorage.getItem('calculated_total');
        let obj = (data) ? JSON.parse(data) : null;
        console.log(obj, "val")
        return obj ? obj[key] : ''
    }



    return (
        <React.Fragment>
            <div className='primary-panel'>
                <h4 className='theme-statement'>Custom Duty of {localStorage.getItem('selectedHsCode')} </h4><br></br>

                <>
                    <table className="table table-user">

                        <thead className='theme-widget' style={{ background: 'red' }}>
                            <tr>
                                <th>Duty/Cess </th>
                                <th>Tariff</th>
                                <th style={{ "textAlign": "center" }}>PTA <br />
                                    <sub>{PTA()?.pta_name && (PTA()?.pta_name)}</sub>
                                </th>
                                <th style={{ "textAlign": "center" }}>Exemptions &nbsp;<i className="fa fa-info-circle" style={{ color: 'white' }} title='Please refer exemptions document' aria-hidden="true"></i></th>
                            </tr>

                        </thead>
                        <tbody>
                            {rateList && rateList.map((item, index) => {
                                return <>
                                    <tr key={index}>
                                        <td>{item.column_name}{item.help_text && <>&nbsp;<i title={item.help_text} className="fa fa-info-circle"></i></>}</td>
                                        <td>{
                                            item.has_custom_value ?
                                                <>
                                                    {(getGstObj() || dutyCess(item.column_key)) ?
                                                        <>


                                                            {item.column_key.toLowerCase() == 'gst' && localStorage.getItem('selectedGST') ?
                                                                <>
                                                                    {getGstObj()?.value}%&nbsp; <i onClick={() => openGSTModal(item.column_name, item.column_key)} className="fa fa-pencil gst-link" aria-hidden="true"></i>
                                                                </> : null

                                                            }

                                                            {item.column_key.toLowerCase() !== 'gst' ?
                                                                <>
                                                                    {localStorage.getItem(item.column_key + "-custom-cess") ?
                                                                        <>
                                                                            {dutyCess(item.column_key)?.value}%&nbsp; <i onClick={() => openGSTModal(item.column_name, item.column_key)} className="fa fa-pencil gst-link" aria-hidden="true"></i>

                                                                        </> :
                                                                        <span className='gst-link' onClick={() => openGSTModal(item.column_name, item.column_key)}>{item.tariff_text}</span>
                                                                    }


                                                                </> : null
                                                            }

                                                        </> :
                                                        <>
                                                            <span className='gst-link' onClick={() => openGSTModal(item.column_name, item.column_key)}>{item.tariff_text}</span>
                                                        </>

                                                    }
                                                </> : <>
                                                    {item.tariff_text}
                                                </>
                                        }
                                        </td>
                                        <td style={{ "textAlign": "center" }}>
                                            {item.column_key == 'BCD' ? PTA()?.tariff_text :
                                                (PTA()?.tariff_text ?
                                                    <>
                                                        {
                                                            item.has_custom_value ?
                                                                <>
                                                                    {(getGstObj() || dutyCess(item.column_key)) ?
                                                                        <>


                                                                            {item.column_key.toLowerCase() == 'gst' && localStorage.getItem('selectedGST') ?
                                                                                <>
                                                                                    {getGstObj()?.value}%&nbsp;
                                                                                </> : null

                                                                            }

                                                                            {item.column_key.toLowerCase() !== 'gst' ?
                                                                                <>
                                                                                    {localStorage.getItem(item.column_key + "-custom-cess") ?
                                                                                        <>
                                                                                            {dutyCess(item.column_key)?.value}%&nbsp;

                                                                                        </> :
                                                                                        <>
                                                                                            {'-'}
                                                                                        </>
                                                                                        // <span className='gst-link' onClick={() => openGSTModal(item.column_name, item.column_key)}>{item.tariff_text}</span>
                                                                                    }


                                                                                </> : null
                                                                            }

                                                                        </> :
                                                                        <>
                                                                            {'-'}
                                                                        </>

                                                                    }
                                                                </> : <>
                                                                    {item.tariff_text}
                                                                </>
                                                        }
                                                    </>

                                                    : null)


                                            } </td>
                                        <td style={{ "textAlign": "center" }}>
                                            {item.link &&
                                                <>
                                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;&nbsp;<a download="file.pdf" target="_blank" href={item.link}>Pdf</a>
                                                </>
                                            }
                                        </td>

                                    </tr>
                                </>
                            })}
                            {/* totalCustomDuty : {totalCustomDuty} */}

                            {totalCustomDuty ? <>
                                <tr className='theme-row'>
                                    <td className='theme-statement' >Total Custom Duty</td>
                                    <td  >{totalCustomDuty}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        {/* {PTA()?.tariff_text ?
                                            <>{(totalCustomDuty - (BcdVal?.value ? BcdVal?.value : 0)) + PTA()?.value}%</> : null
                                        } */}

                                        {totalPriferCustomDuty ? totalPriferCustomDuty : null}
                                    </td>
                                    <td >
                                        <div onClick={() => reportIncorrect()} style={{ color: 'brown', 'textAlign': 'center', 'cursor': 'pointer' }}>Report Incorrect</div> </td>
                                </tr>
                            </> :
                                <tr className='theme-row'>
                                    <td className='theme-statement' >{getTotal('total_custom_duty') ? 'Total Custom Duty': ''}</td>
                                    <td  >{getTotal('total_custom_duty')}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        {/* {PTA()?.tariff_text ?
                                     <>{(totalCustomDuty - (BcdVal?.value ? BcdVal?.value : 0)) + PTA()?.value}%</> : null
                                 } */}

                                        {getTotal('total_preferential_duty')}
                                    </td>
                                    <td >
                                        <div onClick={() => reportIncorrect()} style={{ color: 'brown', 'textAlign': 'center', 'cursor': 'pointer' }}>Report Incorrect</div> </td>
                                </tr>
                            }
                        </tbody>
                    </table >
                </>


                {policy && <>
                    <br></br>
                    <h5><span className='policy-line theme-sub-statement' onClick={() => setShowPolicyModal(true)}>Import policy and conditions</span> <br></br>


                        {policy?.chapter_notes ?

                            <a target='_blank' className='policy-line theme-sub-statement' href={policy?.chapter_notes}>Chapter Notes</a>
                            : null
                        }
                    </h5>
                </>}

                <br /> <br />

                <TradeRemedies />
                <br /><br />
                <RegulatoryRequirement />








                {
                    showPolicyModal && <Modal modalStyle={{

                    }} show={showPolicyModal} onClose={() => setShowPolicyModal(false)} modal_title="Import Policy And Conditions">
                        <ImportPolicyModal initialData={{
                            'policy': policy,
                        }}
                            hideModal={setShowPolicyModal} />
                    </Modal>
                }

                {showGstModal && <Modal modalStyle={{
                    height: '560px'
                }} show={showGstModal} onClose={() => setGstModal(false)} modal_title={getModelName()}>
                    <GstModal initialData={{
                        'data': null,
                        column_key: dynamicColumnKey,
                        column_name: dynamicColumnName,
                    }} onClose={onGstModalDismiss}
                        hideModal={setGstModal} />
                </Modal>}


                {showIncorrectReportModal && <Modal modalStyle={{
                    height: '300px'
                }} show={showIncorrectReportModal} onClose={() => setIncorrectReportModal(false)} modal_title="Report Incorrect">
                    <ReportIncorrectModal initialData={{
                        'data': null,
                    }} onClose={onincorrectReportModalDismiss}
                        hideModal={setIncorrectReportModal} />
                </Modal>}



            </div>
        </React.Fragment>
    );
}
