import React from 'react';
import PropTypes from 'prop-types';

export default function DropDown({ itemlist, onFilterChange, itemLabelKey, filterKey }) {
    return (
        <select placeholder='Select' className="input-control" onChange={onFilterChange}>
            <option value="">Select</option>
            {itemlist && itemlist.map((list, i) => {
                return <option key={i} value={list[filterKey]}>{list[itemLabelKey]}</option>
            })}
        </select>
    );
}

DropDown.propTypes = {
    itemlist: PropTypes.array.isRequired,
    itemLabelKey: PropTypes.string.isRequired,
    filterKey: PropTypes.string.isRequired,
    onFilterChange: PropTypes.func.isRequired,
};
