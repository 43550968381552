import axios from 'axios';

import { toast } from "react-toastify";
import { logout } from "../../services/auth-service";
import { HTTP_STATUS } from "../../utility/constants/common-constants";
import { hideLoader } from '../../services/core/loader-service';
import { Link } from '@mui/material';
import { SUBSCRIPTION_PLAN_MGMT_URL } from '../../router/router-constatns';

export function errorInterceptor() {

    axios.interceptors.response.use(null, (error) => {
        const { response } = error;
        console.log(response);
        if (!response) {
            console.log(response,"response");
            return;
        }


        if (response.data.code == "token_not_valid") {
            let layout = document.getElementById("page-wrapper");
            layout.classList.remove('header-upper')
            layout.classList.add('hide');
            layout.classList.remove('show');
            logout();
            return
        }

        let errorMessage = response?.data?.message;
        if (!errorMessage) {
            let arr = Object.entries(response.data);
            let msg = arr[0][1][0];
            errorMessage = arr.length ? msg : 'Someting Went Wrong';
        }

        const expirePlanMsg = "upgrade your subscription plan";
        const CustomToastWithLink = () => (
            <div className='text-link' >
                {errorMessage.toLowerCase().split(expirePlanMsg)[0]}
                <Link to={SUBSCRIPTION_PLAN_MGMT_URL}><a href={SUBSCRIPTION_PLAN_MGMT_URL}>{expirePlanMsg}</a></Link>
            </div>
        );
        if (errorMessage && errorMessage.toLowerCase().includes(expirePlanMsg)) {
            errorMessage = CustomToastWithLink()
        }


        const subscribeMsg = "subscribe now";
        const htmlToastWithLink = () => {
            let msgArr = errorMessage.toLowerCase().split(subscribeMsg);
            console.log(msgArr, "msg");
            return <div className='text-link' >
                {msgArr[0]}
                <Link to={SUBSCRIPTION_PLAN_MGMT_URL}><a href={SUBSCRIPTION_PLAN_MGMT_URL}>{subscribeMsg}</a></Link>
                {msgArr[1]}
            </div>
        }
        if (typeof errorMessage == 'string' && errorMessage.toLowerCase().includes(subscribeMsg)) {
            errorMessage = htmlToastWithLink()
        }

        toast.error(errorMessage);
        hideLoader();

        if ([HTTP_STATUS.UNAUTHORIZED, HTTP_STATUS.FORBIDDEN].includes(response.status)) {
            // auto logout if 401 or 403 response returned from api
            logout();
        }
        return response;
  
    });

}
