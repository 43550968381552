import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import { hideLoader, showLoader } from "../../../services/core/loader-service";
import { Link, useNavigate } from "react-router-dom";
import { login, register } from "../../../services/auth-service";
import { finalize, of, switchMap, tap } from "rxjs";
import * as constantsType from "../../../utility/constants/common-constants";
import { setPermission } from "../../../redux/permission/permission-reducer";
import { getUserPermissions } from '../../../services/role-service';
import { LoginFormValidation, SignUpFormValidation } from '../../../utils/schemas';
import { useFormik } from 'formik';
import { DUTY_CALCULATOR_PAGE } from '../../../utility/constants/page-route-constants';
import img from "../../../static/img/logo.svg";
import usernameIcon from "../../../static/icon/icon-username.png";
import mobileIcon from "../../../static/icon/icon-mobile.png";
import emailIcon from "../../../static/icon/icon-email.png";
import "../../../static/css/global/login.css";
import { LOGIN_URL } from '../../../router/router-constatns';
import back from '../../../static/icon/back.png'

export const Signup = () => {

    const initialValues = { first_name: '', last_name: '', email: '', mobile_number: '' };
    const [passwordType, setPasswordType] = useState("password");
    const [isTermAccepted, setTerm] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: SignUpFormValidation,
        enableReinitialize: true,
        onSubmit: (value) => {
            // value.mobile_number = value.mobile_number;
            showLoader();
            formHandler(value)
        }
    });

    const formHandler = (payload) => {
        let signupObs = register({ data: payload }).pipe(finalize(() => {
            hideLoader();
            signupObs = null;
        }))
            .subscribe(response => {
                hideLoader();

                if (!response) {
                    return
                };
                debugger
                seoSignupPage(response.data);
                showSuccessToaster(response?.message);
                navigate(LOGIN_URL);
            });
    }

    const updateTermValue = (event) => {
        setTerm(event.target.checked);
    }

    const seoSignupPage = (data) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'registrationComplete',
            'formType': 'Signup',
            'formPosition': 'Footer',
            'user_id':data.id
        });
    }


    return <>
        <div className='container-fluid'>

            <div className='row '>
                <a class="btn back-home" href="https://www.cusbuzz.com/" title="Go Back">
                    <img className='back-icon' src={back} />
                    <span className='bd-show' >Back to home</span>
                </a>

                <br></br>

                <div className='col-lg-8 left aligncenter'>
                    <img className='img-head imglogo' src={img} ></img>
                </div>
                <div className='col-lg-4 right'>
                    <section className="login-wrapper">
                        <div className="login-form">
                            <h4 className='login-heading'>Register Your Account </h4>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="label-secondary">First Name</label>
                                    <div className="input-with-icon">
                                        <span className="icon">
                                            <img src={usernameIcon} ></img>
                                        </span>
                                        <input type="text" className="form-control" placeholder="First Name" name="first_name"
                                            value={values.first_name} autoComplete="false" onChange={handleChange} />
                                        {errors.first_name && <p className='error'>{errors.first_name}</p>}
                                    </div>
                                </div>
                                <br />
                                <div className="form-group">
                                    <label className="label-secondary">Last Name</label>
                                    <div className="input-with-icon">
                                        <span className="icon">
                                            <img src={usernameIcon} ></img>
                                        </span>
                                        <input type="text" className="form-control" placeholder="Last Name" name="last_name"
                                            value={values.last_name} autoComplete="false" onChange={handleChange} />
                                        {errors.last_name && <p className='error'>{errors.last_name}</p>}
                                    </div>
                                </div>
                                <br />
                                <div className="form-group">
                                    <label className="label-secondary">Email</label>
                                    <div className="input-with-icon">
                                        <span className="icon">
                                            <img src={emailIcon} ></img>
                                        </span>
                                        <input type="email" className="form-control" placeholder="Your Email" name="email"
                                            value={values.email} autoComplete="false" onChange={handleChange} />
                                        {errors.email && <p className='error'>{errors.email}</p>}
                                    </div>
                                </div>
                                <br />
                                <div className="form-group">
                                    <label className="label-secondary">Mobile No.</label>
                                    <div className="input-with-icon">
                                        <span className="icon">
                                            <img src={mobileIcon} ></img>
                                        </span>
                                        <input type="text" className="form-control" placeholder="Ex. +9188**********" name="mobile_number"
                                            value={values.mobile_number} autoComplete="false" onChange={handleChange} />
                                        {errors.mobile_number && <p className='error'>{errors.mobile_number}</p>}
                                    </div>
                                </div>
                                <br />



                                <input checked={isTermAccepted} onChange={(e) => updateTermValue(e)} type="checkbox"></input> I Accept to the <Link className='theme-statement' to="/termAndCondition">Term of Service and Privacy Policy</Link>
                                <br />
                                <br />
                                <div>
                                    <button disabled={!isTermAccepted} type="submit" className="btn btn-primary btn-full">Register</button>
                                </div>
                                <br></br>
                                <div className="input-container text-center">
                                    Already have an account? <Link to={LOGIN_URL} className="textLink">Login</Link>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>

            </div>
        </div>

    </>
}

