import React, { useCallback, useEffect, useState } from 'react';
import { finalize } from 'rxjs';
import { hideLoader, showLoader } from '../../../services/core/loader-service';
import { getDutyCessRates, getGstRates, getHsCode } from '../../../services/duty-calc-service';
import * as constantsType from "../../../utility/constants/common-constants";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { showWarnToaster } from '../../../utils/toaster/toaster';

export const GstModal = (props) => {

    let [gstList, setGstList] = useState([]);
    let [selectGst, setSelectGst] = useState([]);

    useEffect(() => {
        let isGstData = props.initialData.column_key.toLowerCase() == 'gst' ? true : false;
        if (isGstData) {
            fetchGstRates();
        } else {
            fetchDutyCessRates();
        }
    }, [])

    const fetchGstRates = () => {
        showLoader();
        let hscode = localStorage.getItem('selectedHsCode');
        let subscription = getGstRates(hscode)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let list = response.results;
                setGstList(list);
            });
    };

    const fetchDutyCessRates = () => {
        showLoader();
        let hscode = localStorage.getItem('selectedHsCode');
        let column_key = props.initialData.column_key || '';
        let subscription = getDutyCessRates(hscode, column_key)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let list = response.results;
                setGstList(list);
            });
    };


    function onConfirmHandler() {
        if (selectGst.length > 1) {
            showWarnToaster("Max Selection is 1");
            return
        }
        props.onClose(selectGst);
        props.hideModal(false)
    }



    const columns = [
        {
            field: 'hs_code',
            headerName: 'Hs code',
            width: 150,
        },
        {
            field: 'value',
            headerName: 'Value (%)',
            width: 150,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 150,
        },

    ];



    return <>
        <div style={{ height: 370, width: '100%' }}>

            <DataGrid
                rows={gstList}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection

                onRowSelectionModelChange={(newRowSelectionModel) => {
                    if (newRowSelectionModel.length > 1) {
                        showWarnToaster("Max Selection is 1");
                        return
                    }

                    if (newRowSelectionModel.length) {
                        let temp = JSON.parse(JSON.stringify(gstList));
                        let rowData = temp.find((obj) => {
                            return obj.id == newRowSelectionModel[0]
                        })
                        debugger
                        setSelectGst(rowData);
                    }


                }}
            />
        </div><br></br>
        <Button className='btn-theme' variant="contained" onClick={() => onConfirmHandler()}> Select</Button>
    </>

}