import img from "../../static/img/404.png";
export const NotFound = () => {
    return <>
        {/* {img} */}
        {/* <h2 style={{ "textAlign": "center", "marginTop": "120px" }}>Page Not Found</h2> */}
        <br></br>
        <img src={img} style={{ height: 300, width: 700, "marginLeft": "20%", "marginTop": "100px" }}></img>
    </>

}
