import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import { hideLoader, showLoader } from "../../../services/core/loader-service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { login } from "../../../services/auth-service";
import { finalize, of, switchMap, tap } from "rxjs";
import * as constantsType from "../../../utility/constants/common-constants";
import { setPermission } from "../../../redux/permission/permission-reducer";
import { getUserPermissions } from '../../../services/role-service';
import { LoginFormValidation } from '../../../utils/schemas';
import { useFormik } from 'formik';
import { DUTY_CALCULATOR_PAGE, FORGOT_PASSWORD, MAINTAINENCE_PAGE } from '../../../utility/constants/page-route-constants';
import img from "../../../static/img/logo.svg";
import userIcon from "../../../static/icon/user-icons.png";
import passwordIcon from "../../../static/icon/icon-password.png";
import showPasswordIcon from "../../../static/icon/icon-eye.png"
import "../../../static/css/global/login.css";
import { NOT_FOUND, SIGNUP_URL, SUBSCRIPTION_PLAN_MGMT_URL } from '../../../router/router-constatns';
import useRazorpay from "react-razorpay";
import axios from 'axios';
import { bannerData, getProfile } from '../../../services/user-service';
import { lastestSubscription } from '../../../services/subscription-service';
import back from '../../../static/icon/back.png'


export const Login = () => {


    const initialValues = { username: '', password: '' };
    const [passwordType, setPasswordType] = useState("password");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getBannerData();
        
    }, [])

    let location = useLocation();
    useEffect(() => {
      if (window.fbq) {
        window.fbq('track', 'PageView');
      }
    }, [location]);

    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: LoginFormValidation,
        enableReinitialize: true,
        onSubmit: (value) => {
            showLoader();
            formHandler(value)
        }
    })

    const formHandler = (payload) => {
        let loginObs = login({ data: payload }).pipe(finalize(() => {
            hideLoader();
            loginObs = null;
        }))
            .subscribe(response => {
                if (!response) return;
                hideLoader();
                updateLayout();
                getProfileData();
                getLastestSubscription();
            });
    }

    const getLastestSubscription = (params) => {
        showLoader();

        let filter = { ...params };
        let subscriptionListubscription = lastestSubscription({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscriptionListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;

                let isSubscriptionPurchased = response.data.length ? true : false;
                if (isSubscriptionPurchased) {
                    navigate(DUTY_CALCULATOR_PAGE);
                } else {
                    navigate(SUBSCRIPTION_PLAN_MGMT_URL);
                }
            });
    }

    const getProfileData = () => {

        // showLoader();
        let subscription = getProfile({})
            .pipe(finalize(() => {
                // hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                localStorage.setItem(constantsType.ACTION_PROFILE, JSON.stringify(response['data']));
                localStorage.setItem('tourShow', response.data.show_demo ? 'yes' : 'no');


            });
    }

    const updateLayout = () => {
        let layout = document.getElementById("page-wrapper");
        layout.classList.remove('hide');
        layout.classList.add('show');
        layout.classList.add('header-upper')
    }

    const getBannerData = (params, selectedPage = 1) => {

        let filter = { ...params, };
        let subscription = bannerData({ params: filter })
            .pipe(finalize(() => {
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let data = response.results;
                let bData = data.find((obj) => {
                    return obj.banner_view !== "APPLICATION";
                });

                if (bData?.message) {
                    navigate(MAINTAINENCE_PAGE)
                }
            });
    }





    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }





    return <>
        <div className='container-fluid'>
            

            <div className='row '>
            <a class="btn back-home" href="https://www.cusbuzz.com/" title="Go Back">
                <img className='back-icon' src={back} />
                <span className='bd-show' >Back to home</span>
            </a>

            <br></br>
                <div className='col-lg-8 left aligncenter'>
                    <img className='img-head imglogo' src={img} ></img>
                </div>


                <div className='col-lg-4 right'>
                    <section className="login-wrapper">
                        <div className="login-form">
                            <h4 className='login-heading'>Login To Your Account </h4>
                            <form onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <label className="label-secondary">Username</label>
                                    <div className="input-with-icon">
                                        <span className="icon">
                                            <img src={userIcon} ></img>
                                        </span>
                                        <input type="text" className="form-control" placeholder="Email" name="username"
                                            value={values.username} autoComplete="false" onChange={handleChange} />
                                        {errors.username && <p className='error'>{errors.username}</p>}
                                    </div>
                                </div>
                                <br /> <br style={{ display: (errors.username || errors.password) ? 'block' : 'none' }} />
                                <div className="form-group">
                                    <label className="label-secondary">Password</label>
                                    <div className="input-with-icon">
                                        <span className="icon">
                                            <img src={passwordIcon} ></img>
                                        </span>
                                        <input type={passwordType} className="form-control" id='password' placeholder="Enter Password" name="password"
                                            value={values.password} autoComplete="false" onChange={handleChange} />
                                        <span className="view-password" onClick={togglePassword}>
                                            <img src={showPasswordIcon} title="" alt="" />
                                        </span>
                                        {errors.password && <p className='error'>{errors.password}</p>}
                                    </div>
                                </div>
                                <br />
                                <div className="input-container text-right">
                                    <Link to={FORGOT_PASSWORD} className="textLink">Forgot password?</Link>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary btn-full">Login</button>
                                </div>
                                <br></br>
                                <div className="input-container text-center">
                                    Don't have an account? <Link to={SIGNUP_URL} className="textLink">Signup Now</Link>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>

            </div>
        </div>

    </>
}

