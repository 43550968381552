import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {redirectTo} from "../../../redux/common/router-redirect-reducer";
import {useNavigate} from "react-router-dom";

const RouterRedirectComponent = ({urlToRedirect}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (urlToRedirect) {
            dispatch(redirectTo(null));
            navigate(urlToRedirect);
        }
    }, []);
    return <></>
}
export default RouterRedirectComponent;
