import { from, map } from "rxjs";
import HTTP_CLIENT from "../factory/modules/http-client";
import { HTTP_STATUS } from "../utility/constants/common-constants";
import AppConfig from "../factory/modules/app-config";

export const listRole = (args) => {
    args.url = AppConfig.APIS.role;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const getRoleDetails = (args, id) => {
    args.url = `${AppConfig.APIS.role}/${id}`;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const addRole = (args) => {
    args.url = AppConfig.APIS.role;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.CREATED) {
                return res.data;
            }
            return false;
        }))
}


export const editRole = (args, id) => {
    args.url = `${AppConfig.APIS.role}/${id}`;
    return from(HTTP_CLIENT.PUT(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const deleteRole = (id) => {
    let args = {};
    args.url = `${AppConfig.APIS.role}/${id}`;

    return from(HTTP_CLIENT.DELETE(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const getModuleActionList = (args) => {
    args.url = AppConfig.APIS.module_action;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const getPermissions = (args) => {
    args.url = AppConfig.APIS.all_permissions;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const getUserPermissions = (args) => {
    args.url = AppConfig.APIS.user_permissions;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

