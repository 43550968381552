import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const ImportPolicyModal = (props) => {

    return <>
        
        <>
            <span><b>Import Policy  : </b>{props.initialData.policy.import_policy}</span><br></br><br></br>
            <span><b>Policy Conditions  :</b> {props.initialData.policy.policy_conditions}</span>
        </>
    </>
}