import React, { useState, useEffect, useCallback } from 'react';
import "../../../static/css/pages/users/user.css";
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { finalize } from "rxjs";
import "../../../../lib/static/css/global/global.css";
import { getCustomDutyRates, getTradeRamides } from '../../../services/duty-calc-service';
import { DotLoader } from '../../Common/Loader/DotLoader';
import { Skeleton } from '@mui/material';


export const TradeRemedies = () => {


    const [tMList, SetList] = useState(null);
    let [showAccordian, setShowAccordian] = useState(false);
    let [selectedPtaRow, setSelectedPtaRow] = useState(null);

    useEffect(() => {
        if(localStorage.getItem('selectedCountry')){
            fetchTradeRemaides();
        }else{
            SetList([])
        }
    }, [])

    const fetchTradeRemaides = (params = {},) => {
        
        let hscode = localStorage.getItem('selectedHsCode');
        let partnercode = localStorage.getItem("partnerCode");
        if(!partnercode){
            SetList([])
            return
        }
        showLoader();
        let subscription = getTradeRamides(hscode, partnercode)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let list = Array.isArray(response.data.tradeRemedyData) ? response.data.tradeRemedyData : [];
                console.log(list, "list tMList ")

                SetList(list);


            });
    };


    const openAccordian = (ftaID, rowId) => {
        setShowAccordian(true);
        setSelectedPtaRow(rowId);

    }

    const hideAccordian = () => {
        setShowAccordian(false);
        setSelectedPtaRow(null);
    }




    return (
        <React.Fragment>
            <h4 className='theme-statement'>Trade Remedies</h4><br></br>
            For Product: <b> {localStorage.getItem('selectedHsCode')}</b> <br></br>
            Source: <b> {"ITC (International Trade Centre)"} <br></br></b >
            <br></br>

            {(!tMList) ? <Skeleton variant="rectangular" sx={{ fontSize: '1rem' }} height={100} /> :
                <>

                    {localStorage.getItem('partnerCode') ?
                        <>
                            {tMList?.length ?
                                <>
                                    <table className="table table-user">

                                        <thead>
                                            <tr>
                                                <th>Remedy Type </th>
                                                <th>Remedy Status</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Document</th>
                                                <th>Measure</th>
                                            </tr >
                                        </thead >

                                        <tbody>
                                            {tMList && tMList.map((item, index) => {
                                                return <>
                                                    <tr key={index}>
                                                        <td>{item.remedyType}</td>
                                                        <td>{item?.tradeRemedyDetails[0]?.remedyStatus}</td>
                                                        <td>{item?.tradeRemedyDetails[0]?.startDate}</td>
                                                        <td>{item?.tradeRemedyDetails[0]?.endDate}</td>

                                                        <td>

                                                            <div dangerouslySetInnerHTML={{ __html: item?.tradeRemedyDetails[0]?.document }} />
                                                        </td>

                                                        {!showAccordian &&
                                                            <td onClick={() => openAccordian(item.remedyId, index)}>
                                                                <i className="fa fa-sort-desc"></i>
                                                            </td>
                                                        }
                                                        {showAccordian &&
                                                            <td onClick={() => hideAccordian()} >
                                                                <i className="fa fa-sort-up"></i>

                                                            </td>
                                                        }

                                                    </tr>

                                                    {(showAccordian && selectedPtaRow == index) ?
                                                        <>
                                                            <tr style={{ display: 'table-row' }}>
                                                                <td colSpan="6">
                                                                    <table className="table table-user" >

                                                                        <thead>
                                                                            <tr>

                                                                                <th>Exporting Firm</th>
                                                                                <th>Measure</th>
                                                                                <th>Note</th>
                                                                            </tr >


                                                                        </thead >

                                                                        {item?.tradeRemedyDetails[0]['companyDetails'] && item?.tradeRemedyDetails[0]['companyDetails'].map((data) => {
                                                                            return <>
                                                                                <tbody>
                                                                                    <td>{data.exportingFirm}</td>
                                                                                    <td>{data.duty}</td>
                                                                                    <td>{data.note}</td>
                                                                                </tbody>
                                                                            </>
                                                                        })}
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </> : null
                                                    }



                                                </>
                                            })}
                                            {/* <tr>
                            <td style={{ "fontWeight": 500 }}>Total Custom Duty</td>
                            <td>{totalCustomDuty}</td>
                            <td></td>
                            <td></td>
                        </tr> */}
                                        </tbody>
                                    </table >

                                </> :
                                <i>India does not apply any trade remedy on the selected product. </i>}
                        </>
                        : <i>To see this Section, Please Select Trade Agreement from screen 1 (Search custom duty).</i>}
                </>
            }
        </React.Fragment>
    );
}
