import { combineReducers } from 'redux';
import authReducer from './auth/auth-reducer';
import permissionReducer from './permission/permission-reducer';
import globalLoaderReducer from "./loader/loader-reducer";
import usersReducer from "./users/users-reducers";
import RouterRedirectReducer from "./common/router-redirect-reducer";
import UserProfileReducer from "./userProfile/user-profile-reducer";

const rootReducer = combineReducers({
    login: authReducer,
    globalLoader: globalLoaderReducer,
    users: usersReducer,
    permission: permissionReducer,
    routerRedirectReducer: RouterRedirectReducer,
    UserProfileReducer: UserProfileReducer,
});

export default rootReducer;
