import { from, map } from "rxjs";
import HTTP_CLIENT from "../factory/modules/http-client";
import { HTTP_STATUS } from "../utility/constants/common-constants";
import AppConfig from "../factory/modules/app-config";



export const getHsCode = (args) => {
    args.url = AppConfig.APIS.hsCode;
    // /hs-codes
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const productLocator = (args) => {
    args.url = AppConfig.APIS.productLocator;
    // /hs-codes
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const productSearch = (args) => {
    args.url = AppConfig.APIS.productSearch;
    // /import-data
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const getCountries = (args) => {
    args.url = AppConfig.APIS.countries;
    // /countries
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const getPartnerCode = (country) => {
    let args = {};
    args['params'] = {
        partner: country
    };
    args.url = AppConfig.APIS.partnerCode;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const getPTA = (code, hscode) => {
    let args = {};
    args['params'] = {
        partner_code: code,
        hs_code:hscode
    };
    args.url = AppConfig.APIS.PTA;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const getFLADetails = (hscode, ftaID, partnerCode) => {
    let args = {};
    args['params'] = {
        fta_id: ftaID,
        partner_code:partnerCode,
        hs_code:hscode
    };
    args.url = AppConfig.APIS.fla_details;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const getROODetails = (ftaID, partnerCode) => {
    let args = {};
    args['params'] = {
        fta_id: ftaID,
        partner_code:partnerCode,
    };
    args.url = AppConfig.APIS.roo_details;

    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const getCustomDutyRates = (hscode, pta, country) => {
    let args = {
        data: {
            hs_code: hscode,
            pta_data: pta,
            country: country
        }
    };
    args.url = AppConfig.APIS.rates;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const getCustomDutyTotal = (payload) => {
    let args = {
        data: payload
    };
    args.url = AppConfig.APIS.total;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const getTradeRamides = (hscode, partnerCode) => {
    let args = {};
    args['params'] = {
        hs_code: hscode,
        partner_code:partnerCode,
    };
    args.url = AppConfig.APIS.traderemedy;

    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const getNtmData = (hscode, partnerCode) => {
    let args = {};
    args['params'] = {
        hs_code: hscode,
        partner_code:partnerCode,
    };
    args.url = AppConfig.APIS.ntm;

    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const getCustomDutyValues = (payload) => {
    let args = { data: payload };
    args.url = AppConfig.APIS.values;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const getImportPolicy = (hscode) => {
    let args = {};
    args['params'] = {
        hs_code: hscode,
    };
    args.url = AppConfig.APIS.importPolicy;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const getGstRates = (hscode) => {
    let args = {};
    args['params'] = {
        hs_code: hscode,
    };
    args.url = AppConfig.APIS.gst_rates;

    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const getDutyCessRates = (hscode, column_key) => {
    let args = {};
    args['params'] = {
        hs_code: hscode,
        column_key:column_key,
    };
    args.url = AppConfig.APIS.duty_cess;

    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}


export const getInsights = (filter) => {
    let args = {};
    args['params'] = filter;
    // const querystring = encodeQueryData(filter);
    args.url = AppConfig.APIS.insight;

    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}



export const postReportIncorrect = (payload) => {
    let args = { data: payload };
    args.url = AppConfig.APIS.incorrectReport;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const getUsagHistoryList = (args) => {
    args.url = AppConfig.APIS.usesHistory;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}



