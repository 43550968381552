
export const USER_MANAGEMENT = 'Sub User Management';
export const MASTER_MANAGEMENT = 'Master Management';

//Users Module
export const USER_PAGE = 'Users';

// Role Module
export const ROLE = 'Role';
export const ROLE_PAGE = 'Roles';
export const ADD_ROLE_PAGE = 'Add Role';
export const EDIT_ROLE_PAGE = 'Edit Role';


// Master Management

//Lookup Module
export const LOOKUP_PAGE = 'Lookup';

//Lookup Type Module
export const LOOKUP_TYPE_PAGE = 'Lookup Type';



// AUTH Module
export const LOGIN = 'Login';
export const FORGOT_PASSWORD = 'Forgot Password';
export const CHANGE_PASSWORD = 'Change Password';
export const RESET_PASSWORD = 'Reset Password';
export const PROFILE = 'Profile';



export const DASHBOARD = 'Dashboard';