import { from, map } from "rxjs";
import HTTP_CLIENT from "../factory/modules/http-client";
import { HTTP_STATUS } from "../utility/constants/common-constants";
import AppConfig from "../factory/modules/app-config";

export const listLookupType = (args) => {
    args.url = AppConfig.APIS.lookup_types;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const addLookupType = (args) => {
    args.url = AppConfig.APIS.lookup_types;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.CREATED) {
                return res.data;
            }
            return false;
        }))
}


export const editLookupType = (args,id) => {
    args.url = `${AppConfig.APIS.lookup_types}/${id}`;
    return from(HTTP_CLIENT.PUT(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const deleteLookupType = (id) => {
    let args = {};
    args.url = `${AppConfig.APIS.lookup_types}/${id}`;

    return from(HTTP_CLIENT.DELETE(args)).pipe(
        map((res) => {
            console.log("delete status", res.status)
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}
