import * as constantsType from "../../utility/constants/common-constants";

export const hasPermission = (permission, child, featureKey) => {
    let permissionSet = permission || getPermissionSet();

    if (!permissionSet) {
        return true
    }
    for (const [key, perm] of Object.entries(permissionSet)) {
        if (key == child) {
            for (const [key2, list] of Object.entries(perm.actions)) {
                if (key2 == featureKey) {
                    return true
                }
            }
        }
    }
}

export const hasParentPermission = (permission, parent) => {
    let parent_id;
    let permissionSet = permission || getPermissionSet();
    let childModulePermissionSet = [];

    if (!permissionSet) {
        return true
    }
    for (const [key, perm] of Object.entries(permissionSet)) {
        if (key == parent) {
            parent_id = perm.id;
        }
    }

    for (const [key, perm] of Object.entries(permissionSet)) {
        if (parent_id == perm.parent_id) {
            childModulePermissionSet.push(hasPermission(permissionSet, key, 'view'));
        }
    }

    if (childModulePermissionSet.includes(false) || childModulePermissionSet.includes(undefined)) {
        return false
    } else {
        return true
    }


}

export const hasRouteAccess = (permission, key, feature) => {
    if (permission[key]) {
        return (permission[key]['actions'][feature]) ? true : false
    }
}

function getPermissionSet() {
    return localStorage.getItem(constantsType.PERMISSION_SET);
}
