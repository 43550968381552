// PARENT MODULE

export const USER_MANAGEMENT = 'CA_USERS_MANAGEMENT';
export const MASTER_MANAGEMENT = 'CA_MASTER_MANAGEMENT';

// CHILD MODULES

export const ROLES = 'ROLES';
export const USERS = 'USERS';
export const LOOKUP = 'LOOKUP';
export const LOOKUP_TYPE = 'LOOKUP_TYPE';


// FEATURES

export const ADD = 'add';
export const VIEW = 'view';
export const EDIT = 'edit';
export const DELETE = 'delete';
export const ACTIVE = 'active';


// permission constants

export const MODULES = {
    PARENT: {
        USER_MANAGEMENT: 'user-management',
        MASTER_MANAGEMENT: 'master-management'
    },
    CHILD: {
        USERS: "users",
        ROLE: 'role',
        LOOKUP: "lookups",
        LOOKUP_TYPE: "lookup-type"
    }
}