// User Management

//Users Module
export const USER_PAGE = '/user-management/users/list';

// Role Module
export const ROLE_PAGE = '/user-management/role/list';
export const ADD_ROLE_PAGE = '/user-management/role/add';
export const EDIT_ROLE_PAGE = '/user-management/role/edit';


// Master Management

//Lookup Module
export const LOOKUP_PAGE = '/master-management/lookups';

//Lookup Type Module
export const LOOKUP_TYPR_PAGE = '/master-management/lookup-type';



// AUTH Module
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const CHANGE_PASSWORD = '/change-password';
export const RESET_PASSWORD = '/reset-password/:token';
export const PROFILE = '/profile';


export const ABOUT = '/about';
export const DASHBOARD_PAGE = '/dashboard';

export const ALERT_MGMT_PAGE = '/alerts';
export const NOTIFICATION_MGMT_PAGE = '/notifications';
export const SUBSCRIPTION_MGMT_PAGE = '/subscription-history';
export const SUBSCRIPTION_PLAN_PAGE = '/subscription-plan';
export const DUTY_CALCULATOR_PAGE = '/duty-calculator';
export const MAINTAINENCE_PAGE = '/maintain';