import React from 'react';
import {Navigate} from 'react-router-dom';
import {isLogin} from "../services/auth-service";
import {DUTY_CALCULATOR_URL} from "./router-constatns";

const PublicRoute = ({restricted = false, component}) => {
    // restricted = false meaning public route
    // restricted = true meaning restricted route e.g. access login/forgot pass page when already logged in
    if (isLogin() && restricted) {
        return <Navigate to={DUTY_CALCULATOR_URL
} replace/>;
    }
    return component;
};
export default PublicRoute;
