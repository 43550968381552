import React, { useState, useEffect } from "react";
import "../../static/css/pages/subscription.css";
import { showLoader, hideLoader } from "../../services/core/loader-service";
import { finalize } from "rxjs";
import "../../../lib/static/css/global/global.css";
import {
  canAvail,
  listSubscriptionPlan
} from "../../services/subscription-service";
import check from "../../static/icon/check.svg";
import cross from "../../static/icon/error.png"

import { useNavigate } from "react-router-dom";

export const SubscriptionPlan = () => {
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [availFreePlan, setFreePlanData] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    getFreePlanDetails();
    getSubscriptionList("");
  }, []);

  const getSubscriptionList = params => {
    showLoader();

    let filter = { ...params };
    let subscriptionListubscription = listSubscriptionPlan({ params: filter })
      .pipe(
        finalize(() => {
          hideLoader();
          subscriptionListubscription = null;
        })
      )
      .subscribe(response => {
        if (!response) return;
        setSubscriptionList(response.results);
      });
  };

  const getFreePlanDetails = params => {
    showLoader();
    let subscription = canAvail({})
      .pipe(
        finalize(() => {
          hideLoader();
          subscription = null;
        })
      )
      .subscribe(response => {
        if (!response) return;
        setFreePlanData(!response.can_avail_free_plan);
      });
  };

  const checkout = id => {
    navigate("/subscription-payment/" + id);
  };

  let cusId = 0;
  function calimatic__Custom__Carousl() {
    this.slidePx = 0;
    this.currentPosition = 0;
  }

  calimatic__Custom__Carousl.prototype.NextSlide = function (that, b) {
    let inner = that.parentElement.firstElementChild;
    if (this.currentPosition < inner.childElementCount - 1) {
      this.slidePx += b * -1;
      inner.style.transform = `translateX(${this.slidePx}px)`;
      this.currentPosition++;
      this.currentPosition == inner.childElementCount - 1
        ? that.classList.add("disable-button")
        : that.classList.remove("disable-button");
      this.currentPosition != 0
        ? that.previousElementSibling.classList.remove("disable-button")
        : that.classList.remove("disable-button");
    } else {
      this.slidePx = 0;
      this.currentPosition = 0;
      inner.style.transform = `translateX(${this.slidePx}px)`;
      this.currentPosition++;
      this.currentPosition == inner.childElementCount - 1
        ? that.classList.remove("disable-button")
        : that.classList.remove("disable-button");
      this.currentPosition != 0
        ? (that.previousElementSibling.style.display = "")
        : that.classList.add("disable-button");
    }
  };

  calimatic__Custom__Carousl.prototype.PrevSlide = function (that, b) {
    let inner = that.parentElement.firstElementChild;
    if (this.currentPosition > 0 && this.slidePx < 0) {
      this.slidePx += b * 1;
      inner.style.transform = `translateX(${this.slidePx}px)`;
      this.currentPosition--;
      this.currentPosition == 0
        ? that.classList.add("disable-button")
        : that.classList.remove("disable-button");
      this.currentPosition != inner.childElementCount
        ? that.nextElementSibling.classList.remove("disable-button")
        : that.classList.remove("disable-button");
    }
  };

  let carouselPipeLine = [];
  let carouselObjPipeLine = [];

  function PrevSlide(that) {
    let slideWidth = that.parentElement.offsetWidth;

    if (!that.parentElement.hasAttribute("customIndex")) {
      let allCarousel = document.getElementsByClassName(
        "Custom__Carousel____Container___051"
      );
      for (let i = 0; i < allCarousel.length; i++) {
        cusId = cusId + 1;
        allCarousel[i].setAttribute("customIndex", `custom${i}${cusId}`);
      }
    }
    if (
      !carouselPipeLine.includes(that.parentElement.getAttribute("customIndex"))
    ) {
      let obj = that.parentElement.getAttribute("customIndex");
      carouselPipeLine.push(obj);
      obj = new calimatic__Custom__Carousl();
      carouselObjPipeLine.push(obj);
      obj.PrevSlide(that, slideWidth);
    } else {
      let MyIndex = carouselPipeLine.indexOf(
        that.parentElement.getAttribute("customIndex")
      );
      let obj = carouselObjPipeLine[MyIndex];
      obj.PrevSlide(that, slideWidth);
    }
  }

  function NextSlide(that) {
    let slideWidth = that.parentElement.offsetWidth;

    if (!that.parentElement.hasAttribute("customIndex")) {
      let allCarousel = document.getElementsByClassName(
        "Custom__Carousel____Container___051"
      );
      for (let i = 0; i < allCarousel.length; i++) {
        cusId = cusId + 1;
        allCarousel[i].setAttribute("customIndex", `custom${i}${cusId}`);
      }
    }
    if (
      !carouselPipeLine.includes(that.parentElement.getAttribute("customIndex"))
    ) {
      let obj = that.parentElement.getAttribute("customIndex");
      carouselPipeLine.push(obj);
      obj = new calimatic__Custom__Carousl();
      carouselObjPipeLine.push(obj);
      obj.NextSlide(that, slideWidth);
    } else {
      let MyIndex = carouselPipeLine.indexOf(
        that.parentElement.getAttribute("customIndex")
      );
      let obj = carouselObjPipeLine[MyIndex];
      obj.NextSlide(that, slideWidth);
    }
  }

  return (
    <React.Fragment>
      <section className="users-wrapper" style={{ background: "white" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="sub-heading headinglabel">
                &nbsp; Subscription Plan
              </h1>
              <h4 className="h4-sup">
                Whether you are with an EXIM business house, logistics company
                or are a customs house agent, our plans are specially tailored
                to enable you to successfully achieve your business objectives.
              </h4>
            </div>

            <div className="subscription-plans planui">
              <div className="planui-sub">
                <div className="Custom__Carousel____Container___051 ">
                  <div className="Slides_Wrapper">
                    {(subscriptionList.length ? true : false) &&
                      subscriptionList.map((sub, index) => {
                        return (
                          <>
                            <div className="subs-card freebg slide">
                              <h1 className="sub-head">
                                {sub.name}
                                <br></br>
                                <div className="permonth1">
                                  <span>₹{sub.final_price}</span>
                                  {/* <sub>per month</sub> */}
                                </div>
                              </h1>

                              <ul className="subs-list">
                                <li key={101}>
                                  <span>
                                    {sub.no_of_users ?
                                      <img
                                        src={check}
                                        width="15"
                                        alt=""
                                      /> :
                                      <img
                                        src={cross}
                                        width="12"
                                        alt=""
                                      />
                                    }
                                  </span>{" "}
                                  {sub.no_of_users} Sub Users
                                </li>
                                <li key={102}>
                                  <span>
                                  {sub.validity ?
                                      <img
                                        src={check}
                                        width="15"
                                        alt=""
                                      /> :
                                      <img
                                        src={cross}
                                        width="12"
                                        alt=""
                                      />
                                    }
                                  </span>{" "}
                                  {sub.validity} days Validity
                                </li>
                                <li key={102}>
                                  <span>
                                  {sub.queries_limit ?
                                      <img
                                        src={check}
                                        width="15"
                                        alt=""
                                      /> :
                                      <img
                                        src={cross}
                                        width="12"
                                        alt=""
                                      />
                                    }
                                  </span>{" "}
                                  {sub.queries_limit} Searches
                                </li>
                                {/* <li key={102}>
                                  <span>
                                    <img
                                      src={check}
                                      width="15"
                                      alt=""
                                    />
                                  </span>{" "}
                                  {sub.hs_codes_access.length ? sub.hs_codes_access : 'All Code Access'}
                                </li>
                                <li key={102}>
                                  <span>
                                    {sub.has_pta_data_access ?
                                      <img
                                        src={check}
                                        width="15"
                                        alt=""
                                      /> :
                                      <img
                                        style={{
                                          height: "12px",
                                          width: "12px"
                                        }}
                                        src={cross}
                                        width="15"
                                        alt=""
                                      />
                                    }


                                  </span>{" "}
                                  {'Preferential Duty'}
                                </li>
                                <li key={104}>
                                  <span>
                                    <img
                                      src={check}
                                      width="15"
                                      alt=""
                                    />
                                  </span>{" "}
                                  {sub.alerts_count ? sub.alerts_count : "No"}{" "}
                                  Alerts
                                </li>
                                <li key={103}>
                                  <span>
                                    <img
                                      src={check}
                                      width="15"
                                      alt=""
                                    />{" "}
                                  </span>
                                  {sub.analytics_limit} {" "}
                                  Insights
                                </li> */}


                                {(sub.additional_features.length
                                  ? true
                                  : false) &&
                                  sub.additional_features.map((ft, index) => {
                                    return (
                                      <>
                                        <li key={index}>
                                          <span>
                                            {ft.is_included ?
                                              <img
                                                src={check}
                                                width="15"
                                                alt=""
                                              /> :

                                              <img
                                                src={cross}
                                                width="12"
                                                alt=""
                                              />
                                            }
                                          </span>{" "}
                                          {ft.feature_name} &nbsp;
                                          {ft.help_text && (
                                            <i
                                              className="fa fa-question-circle"
                                              style={{ color: "black" }}
                                              title={ft.help_text}
                                              aria-hidden="true"
                                            ></i>
                                          )}{" "}
                                        </li>
                                      </>
                                    );
                                  })}


                              </ul>
                              <button
                                disabled={
                                  availFreePlan && sub.final_price == 0
                                }
                                onClick={() => checkout(sub.id)}
                                className="btn btn-price1 "
                              >
                                {" "}
                                Select{" "}
                              </button>
                            </div>
                            {/* <a
                              id="heroPrev"
                              onClick={e => PrevSlide(e.target)}
                              className="prev hero_-nav disable-button"
                            >
                              &#10094;
                            </a>
                            <a
                              id="heroNext"
                              onClick={e => NextSlide(e.target)}
                              className="next hero_-nav"
                            >
                              &#10095;
                            </a> */}
                          </>
                        );
                      })}

                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="col-lg-12">
            <div className="desclaimer">* Above prices are exclusive of taxes</div> 
            <h4 className="h4-sup">
              For customised plans, connect with our experts at info@cusbuzz.com
            </h4>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
