import { from, map } from "rxjs";
import HTTP_CLIENT from "../factory/modules/http-client";
import { HTTP_STATUS } from "../utility/constants/common-constants";
import AppConfig from "../factory/modules/app-config";



export const listSubscription = (args) => {
    args.url = AppConfig.APIS.subscription;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}



export const listSubscriptionPlan = (args) => {
    args['params'] = {
        publish: true
    };
    args.url = AppConfig.APIS.subscription_plans;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const canAvail = (args) => {
    args.url = AppConfig.APIS.can_plan_avail;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const checkoutOrder = (args) => {
    args.url = AppConfig.APIS.checkout;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const ConfirmPayment = (args) => {
    args.url = AppConfig.APIS.confirmPayment;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status) {
                return res.data;
            }
            return false;
        }))
}



export const addSubscription = (args) => {
    args.url = AppConfig.APIS.subscription;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.CREATED) {
                return res.data;
            }
            return false;
        }))
}


export const editSubscription = (args, id) => {
    args.url = `${AppConfig.APIS.subscription
        }/${id}`;
    return from(HTTP_CLIENT.PUT(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const deleteSubscription = (id) => {
    let args = {};
    args.url = `${AppConfig.APIS.subscription
        }/${id}`;

    return from(HTTP_CLIENT.DELETE(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.results;
            }
            return false;
        }))
}

export const actiavteSubscription = (id) => {
    let args = {};
    args.url = `${AppConfig.APIS.subscription
        }/${id}`;

    return from(HTTP_CLIENT.PATCH(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.results;
            }
            return false;
        }))
}

export const lastestSubscription = (args) => {
    args.url = AppConfig.APIS.latset_subscription;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const getTrnxDetails = (id) => {
    let args = {};
    args['params'] = {
        txn_id: id
    };
    args.url = AppConfig.APIS.trnx_details;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

