export const INVALID_EMAIL = "Email is Invalid!";
export const REQUIRED_EMAIL = "Email Adrress is Required!";
export const INVALID_PASSWORD = "Password needs to be 8 or more characters!";
export const WHITE_SPACE = "White or Empty Space is not Allowed!";
export const INVALID_CONFIRM_PASSWORD = "Confirm Password must match the New Password!";
export const REQUIRED_CONFIRM_PASSWORD = "Confirm Password is Required!";
export const REQUIRED_FIRST_NAME = "First Name is Required!";
export const REQUIRED_LAST_NAME = "Last Name is Required!";
export const REQUIRED_MOBILE_NO = "Mobile Number is Required!";
export const INVALID_LENGTH = "Mobile Number is Invalid!";
export const INVALID_MOBILE = "Mobile Number must be at least 10 Numbers!";
export const REQUIRED_ROLE = "Role is Required!";
export const MAX_STRING_LENGTH = "Max Length is 256";
export const REQUIRED_PASSWORD = "Password is required";
export const NOT_MATCH_PASSWORD = "Password not matched";
export const CONFIRM_PASSWORD_ERR = "Password and Confirm password are not matched";
export const FORBIDDEN_ERR = "401  UnAuthorized Request";