import { from, map } from "rxjs";
import HTTP_CLIENT from "../factory/modules/http-client";
import { HTTP_STATUS } from "../utility/constants/common-constants";
import AppConfig from "../factory/modules/app-config";



export const listAlerts = (args) => {
    args.url = AppConfig.APIS.alerts;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const addAlerts = (args) => {
    args.url = AppConfig.APIS.alerts;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.CREATED) {
                return res.data;
            }
            return false;
        }))
}


export const editAlerts = (args, id) => {
    args.url = `${AppConfig.APIS.alerts
        }/${id}`;
    return from(HTTP_CLIENT.PUT(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const deleteAlerts = (id) => {
    let args = {};
    args.url = `${AppConfig.APIS.alerts
        }/${id}`;

    return from(HTTP_CLIENT.DELETE(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const actiavteAlerts = (id, payload) => {
    let args = {
        data: payload
    };
    args.url = `${AppConfig.APIS.alerts
        }/${id}`;

    return from(HTTP_CLIENT.PATCH(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}




