import React, { useEffect, useRef } from "react";
import Button from "../Button/Button";
import styles from "../../../static/css/modal/modal.module.css";

const Modal = ({ modalStyle, children, show, onClose, backdropStyle, modal_title }) => {
    const modalRef = useRef(null);
    useEffect(() => {
        
        if (show) {
            modalRef.current.classList.add(styles.visible);
        } else {
            modalRef.current.classList.remove(styles.visible);
        }
    }, [show]);
    return (
        <React.Fragment>
            <div ref={modalRef} style={backdropStyle} className={`${styles.modal}`}>


                <div style={modalStyle} className={styles.modal__wrap}>
                    <div className={styles.modal_content}>
                        <div className={styles.modal_header}>
                            <h5 className={'heading'} >{modal_title}</h5>

                            <Button
                                onClick={onClose}

                                className={styles.modalclose__btn}
                            >

                                <span aria-hidden="true">×</span>
                            </Button>
                        </div>
                        <div className={styles.modal_body}>
                            {children}
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
};

export default Modal;
