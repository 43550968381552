export const HTTP_STATUS = {
    OK: 200,
    CREATED: 201,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    ACCEPTED:202
};

export const ACCESS_TOKEN = "accessToken";
export const ACTION_LOGIN = "login";
export const ACTION_PROFILE = "profile";
export const GLOBAL_LOADER = "globalLoader";
// export const BASE_URL_DEV = 'http://13.232.23.122:8888/api/v1';
export const ADMIN = 'ADMIN_APP';
export const RESET_TOKEN = 'reset_token';
export const ACTION_USERS = "users";
export const SHOW_SIDEBAR = "showSidebar";
export const HIDE_SIDEBAR = "hideSidebar";
export const ACTION_PERMISSION = "permission";
export const SIDEBAR = "sidebar";
export const PERMISSION_SET = "permission_set";
export const ADMIN_APP_SOURCE = 'customer';

export const CUSTOMER_APP_SOURCE = 'customer';
export const hash = '5e2d3c9f461c46ce97227ed8553a6726';
