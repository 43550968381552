import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "../../../static/css/layout/sidebar.css";
import * as moduleConstants from "../../../utility/constants/module-action-constants";
import * as actionConstants from "../../../utility/constants/module-action-constants";
import { PermissionSelector } from "../../../redux/permission/permission-reducer";
import { hasPermission, hasParentPermission } from "../../../factory/helpers/permission-service";
import { DUTY_CALCULATOR_PAGE, LOOKUP_PAGE, LOOKUP_TYPR_PAGE, NOTIFICATION_MGMT_PAGE, ROLE_PAGE, SUBSCRIPTION_MGMT_PAGE, USER_PAGE } from '../../../utility/constants/page-route-constants';
import { ALERT_MGMT_URL, DASHBOARD_MGMT_URL, SEARCH_HISTORY, TROUBLE_TICKET } from '../../../router/router-constatns';
import { UserProfileSelector } from '../../../redux/userProfile/user-profile-reducer';

export default function Sidebar(props) {
  const sidebarRef = useRef(null);
  const [masterMenuToggle, setMasterMenuToggle] = useState(false);
  const [userMenuToggle, setuserMenuToggle] = useState(false);
  const { permission } = useSelector(PermissionSelector);
  const { UserProfileReducer } = useSelector(UserProfileSelector);
  const [isMenuSelected, setMenuSelect] = useState(false);



  const navigate = useNavigate();

  const getActiveChildren = (parent, child) => {
    let url = window.location.href;
    return (url.includes(parent) && url.includes(child)) ? true : false
  }

  const isShow = (child, actionName) => {
    return hasPermission(permission, child, actionName)
  }

  const isParentShow = (parent) => {
    return hasParentPermission(permission, parent)
  }

  const hasUserManagementPermission = () => {
    return hasPermission(permission, moduleConstants.USERS, actionConstants.VIEW) ||
      hasPermission(permission, moduleConstants.ROLES, actionConstants.VIEW)
  }

  const updateActivated = () => {
    setMenuSelect((val) => !val);
  }




  useEffect(() => {
    // console.log(window.innerWidth)

    // console.log(UserProfileReducer, "profile")

    if (props.show) {
      sidebarRef.current.classList.add('show');
    } else {
      sidebarRef.current.classList.remove('show');
    }
  }, [props.show]);



  return (
    <aside className="sidebar" id="sidebar" ref={sidebarRef}>

      <ul className=''>
        {/* <li className={"group-menu" + (userMenuToggle ? " active " : "")}
          style={{ display: hasUserManagementPermission() ? 'block' : 'none' }}>
          <span onClick={() => setuserMenuToggle(!userMenuToggle)}><i className="fa fa-user-o"></i> User Management</span>
          <ul style={{ display: userMenuToggle ? 'block' : 'none' }}>
            <li onClick={() => updateActivated()} style={{
              background: getActiveChildren(moduleConstants.MODULES.PARENT.USER_MANAGEMENT, moduleConstants.MODULES.CHILD.USERS) ? '#f8f8f8' : '',
              display: isShow(moduleConstants.USERS, actionConstants.VIEW) ? 'block' : 'none'
            }}>
              <Link to={USER_PAGE} ><i ></i>&nbsp;Users
              </Link></li>
            <li onClick={() => updateActivated()} style={{
              background: getActiveChildren(moduleConstants.MODULES.PARENT.USER_MANAGEMENT, moduleConstants.MODULES.CHILD.ROLE) ? '#f8f8f8' : '',
              display: isShow(moduleConstants.ROLES, actionConstants.VIEW) ? 'block' : 'none'
            }}>
              <Link to={ROLE_PAGE} ><i  ></i>&nbsp;Roles
              </Link></li>
          </ul>
        </li> */}



        {!UserProfileReducer?.profile?.is_secondary_user &&
          <li title='Sub User Management' className={"group-menu"}
          >
            <span onClick={() => navigate(USER_PAGE)} >
              <i className="fa fa-user-o"></i>
              Sub User Management
            </span>

          </li>
        }


        <li title='Alerts' className={"group-menu"} data-tut="reactour__goTo6"
        >
          <span onClick={() => navigate(ALERT_MGMT_URL)} ><i className="fa fa-exclamation-triangle"></i> Alerts</span>

        </li>

        <li title='Duty Calculator' className={"group-menu"}
        >
          <span onClick={() => navigate(DUTY_CALCULATOR_PAGE)}><i className="fa fa-calculator"></i>Duty Calculator </span>

        </li>

        <li title='Subscriptions' className={"group-menu"} data-tut="reactour__goTo9"
        >
          <span onClick={() => navigate(SUBSCRIPTION_MGMT_PAGE)}><i className="fa fa-newspaper-o"></i> Subscriptions</span>

        </li>

        <li title='Notifications' className={"group-menu"} data-tut="reactour__goTo8"
        >
          <span onClick={() => navigate(NOTIFICATION_MGMT_PAGE)}><i className="fa fa-bell-o"></i> Notifications</span>

        </li>
        <li title='Insights' className={"group-menu"} data-tut="reactour__goTo7"
        >
          <span onClick={() => navigate(DASHBOARD_MGMT_URL)} ><i className="fa fa-line-chart"></i> Insights</span>

        </li>

        <li title='Insights' className={"group-menu"}
        >
          <span onClick={() => navigate(SEARCH_HISTORY)} ><i className="fa fa-history"></i> Usage History</span>

        </li>


        <li title='Insights' className={"group-menu"} data-tut="reactour__goTo7"
        >
          <span onClick={() => navigate(TROUBLE_TICKET)} ><i className="fa fa-ticket"></i> Trouble Ticket</span>

        </li>


      </ul>

    </aside>

  )
}

