import { FORBIDDEN_ERR } from "../../utility/constants/error-constants"

export const Forbidden = () => {
    return <>
        <h1 style={{
            'color': "blue", "marginLeft": "30%", "marginTop": "120px",
            "textTransform": "uppercase"
        }}>{FORBIDDEN_ERR}</h1>

    </>

}
