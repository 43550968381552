import React, { useEffect, useState } from 'react';
import "../../../static/css/layout/header.css";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import SideBar from './Sidebar';
import { isLogin, logout } from "../../../services/auth-service";
import { Avatar, Box, Button, Fade, IconButton, Menu, MenuItem, Paper, Popover, Popper, Tooltip, Typography } from '@mui/material';
import { hideLoader, showLoader } from '../../../services/core/loader-service';
import { finalize } from 'rxjs';
import { listNotification, markAllReadNotification } from '../../../services/notification-service';
import * as constantsType from "../../../utility/constants/common-constants";
import { bannerData, getProfile } from '../../../services/user-service';
import { setUserProfileData } from '../../../redux/userProfile/user-profile-reducer';
import logo from "../../../static/icon/logo.svg";
import { DUTY_CALCULATOR_PAGE, NOTIFICATION_MGMT_PAGE, SUBSCRIPTION_MGMT_PAGE, USER_PAGE } from '../../../utility/constants/page-route-constants';
import { ALERT_MGMT_URL, DASHBOARD_MGMT_URL } from '../../../router/router-constatns';
import { loginSelector } from '../../../redux/auth/auth-reducer';

export default function Header() {
    let initialBannerData = {
        message: ""
    }

    const dispatch = useDispatch();
    const { isMobileSideBarShow } = useSelector(loginSelector);
    const [isSideBarOpen, setSideBar] = useState(true);
    const [notificationList, setNotificationList] = useState([]);
    const [bannerObj, setBannerData] = useState(initialBannerData);
    const [profileData, setProfileData] = useState([]);
    const [userShortName, setUserShortName] = useState("");

    const [anchorEl1, setAnchorEl1] = useState(null);
    const [openMobileSidebar, setOpenMobileSidebar] = React.useState(false);
    // const [placement, setPlacement] = React.useState();
    const [winWIdth, setWinWidth] = useState(window.innerWidth)


    const PER_PAGE = 10;

    // useEffect(() => {
    //     setSideBar(isMobileSideBarShow)
    // }, [isMobileSideBarShow])


    const toggleSidebar = () => {
        let layout = document.getElementById("page-wrapper");

        if (!isSideBarOpen) {
            layout.classList.add('show');
            layout.classList.remove('hide');
        } else {
            layout.classList.add('hide');
            layout.classList.remove('show');
        }

        setSideBar(isSideBarOpen => !isSideBarOpen)
        console.log(isSideBarOpen);

    }

    useEffect(() => {
        let layout = document.getElementById("page-wrapper");
        layout.classList.add('show');
        if (winWIdth < '767') {
            setSideBar(false)
        }

        console.log(isSideBarOpen);
        loadLayout();

        syncNotifications();
        getProfileData();
        getBannerData();
    }, [])

    const syncNotifications = () => {
        setInterval(() => {
            getNotificationList('');
        }, 10000);
    }

    const loadLayout = () => {
        let layout = document.getElementById("page-wrapper");
        layout.classList.remove('hide');
        layout.classList.add('show');
        layout.classList.add('header-upper')
    }

    const getProfileData = () => {

        showLoader();
        let subscription = getProfile({})
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                setProfileData(response['data']);
                localStorage.setItem(constantsType.ACTION_PROFILE, JSON.stringify(response['data']));
                dispatch(setUserProfileData(response['data']))
                let shortName = response['data']?.first_name?.charAt(0) + "" + response['data']?.last_name?.charAt(0);
                setUserShortName(shortName)
            });
    }


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const logoutHandle = () => {
        logout();
        updateLayout();
    }



    const settings = [{
        pageLabel: 'Profile',
        link: '/profile'
    },
    {
        pageLabel: 'Logout',
        fnc: logoutHandle
    }];

    const updateLayout = () => {
        let layout = document.getElementById("page-wrapper");
        layout.classList.remove('show');
        layout.classList.add('hide');
        layout.classList.remove('header-upper')
    }

    const getAccessToken = () => {
        let token = localStorage.getItem("accessToken");
        return token;

    }



    const getNotificationList = (params, selectedPage = 1) => {
        if (!getAccessToken()) {
            return
        }
        // showLoader();

        let filter = {
            ...params, app_source: constantsType.ADMIN_APP_SOURCE, page: selectedPage, _page_size: PER_PAGE,
            is_read: false
        };
        let notificationListubscription = listNotification({ params: filter }, true)
            .pipe(finalize(() => {
                // hideLoader();
                notificationListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                setNotificationList(response.results);


            });
    }

    const getBannerData = (params, selectedPage = 1) => {
        showLoader();

        let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE, page: selectedPage, _page_size: PER_PAGE };
        let subscription = bannerData({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let data = response.results;
                let bData = data.find((obj) => {
                    return obj.banner_view == "APPLICATION";
                });
                setBannerData(bData);


            });
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const markReadAllNotifications = () => {
        showLoader();

        let filter = { app_source: constantsType.ADMIN_APP_SOURCE };
        let notificationListubscription = markAllReadNotification({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                notificationListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                getNotificationList();

            });
    }

    const handleMobileToggle = (event) => {
        console.log("e", event)
        setAnchorEl1(event.currentTarget);
        setOpenMobileSidebar(!openMobileSidebar)
    };

    const navigate = useNavigate();

    const open = Boolean(anchorEl);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);

    // useEffect(() => {
    //      handleSideNavToggle();
    // }, [width]);


    // function handleSideNavToggle() {
    //     console.log("toggle it");
    //     toggleSidebar()
    //     setSideBar(false)

    // }

    const id = open ? 'simple-popover' : undefined;

    return (<>

        {(bannerObj?.message && bannerObj.banner_type == 'RUNNING') ?
            <marquee width="100%" className="banner-msg banner-grid " style={{
                background: '#fff',
                height: '23px',
                position: 'fixed',
                zIndex: 99999,
                fontSize: '15px',
                color: 'red'
            }}>{bannerObj?.message}</marquee> :
            <div className="banner-msg" >{bannerObj?.message} </div>
        }

        <header style={{ marginTop: bannerObj?.message ? "20px" : null }}>

            <div className="navbrand">
                <button type='button' className='toggltButton web-display' onClick={toggleSidebar}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>

                {/* <button type='button' className='toggltButton mob-display' onClick={(e) => handleMobileToggle(e)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button> */}


                <h3 className={'head'} data-tut="reactour__goTo1">
                    <img className='logo-head' src={logo} />
                </h3>
            </div>
            <div className='ml-auto dflex'>

                {/* style={{ marginLeft: '71%', marginTop: '13px' }} */}
                <Typography title={profileData?.first_name} textAlign="center"><span className='notif' onClick={handleClick}>
                    <sup className='dot'>{notificationList.length ? notificationList.length : '0'}</sup><i style={{ color: 'white', textDecoration: 'uppercase' }} className="fa fa-bell-o"></i></span>&nbsp;&nbsp; | &nbsp;{profileData?.first_name?.length + profileData?.last_name?.length > 15 ? profileData?.first_name?.slice(0, 13) + '...' :
                        profileData?.first_name + ' ' + profileData?.last_name}</Typography>


                <div>

                    <div></div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}

                    >
                        <div className="menuuitem">
                            <div class="notihead">
                                <h3>&nbsp; All Notifications</h3>
                                <a onClick={() => markReadAllNotifications()}>Mark All Read&nbsp;</a>
                            </div>
                            {notificationList.length ?
                                <ul className='noitilist'>

                                    {notificationList.map((noti) => {
                                        return <>
                                            <li className='li-noti-list'>
                                                <Typography
                                                    style={{ 'fontSize': "14px", marginTop: '8px', paddingRight: "10px", paddingLeft: '54px' }} textAlign="left">
                                                    <div className="profile-icon">
                                                        <span className="user-name"> {userShortName} </span>
                                                    </div>   <p >{noti.message}</p>
                                                    <p className='notidate'>{noti.create_date}</p>
                                                </Typography>
                                            </li>
                                        </>
                                    })}

                                </ul>
                                :
                                <>
                                    {

                                        <li className='li-noti-list' style={{ listStyle: "none" }}>
                                            <Typography
                                                style={{ width: '400px', 'fontSize': "14px", marginTop: '8px', paddingLeft: "10px", paddingRight: '34px' }} textAlign="left">
                                                <p style={{

                                                    marginTop: '50px',
                                                    textAlign: 'center',
                                                    marginBottom: '30px'
                                                }}>{'No New Notifications Available'}</p>
                                            </Typography>
                                        </li>

                                    }
                                </>}

                            <Link className="btn view-all-btn" style={{ textDecoration: "none", color: "black", borderTop: "1px solid #eee" }} to="/notifications">View All</Link>
                        </div>
                    </Popover>
                </div>

                <Box className="profilmenu" sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">

                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            {/* <Avatar alt="" src="/static/images/avatar/2.jpg" /> */}
                            <div id="shortnamecontainer">
                                <div id="shortname">
                                    {userShortName}
                                </div>
                            </div>

                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <MenuItem onClick={handleCloseUserMenu}>
                            <Link style={{ 'textDecoration': 'none', color: 'black' }} to={'/profile'}><Typography textAlign="center">{'Profile'}</Typography></Link>
                        </MenuItem>
                        <MenuItem onClick={handleCloseUserMenu}>
                            <Typography onClick={() => logoutHandle()} textAlign="center">{'Logout'}</Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </div>
        </header >
        {(window.screen.width < 450 && localStorage.getItem('tourShow') !== 'no') ?
            <SideBar show={isMobileSideBarShow} /> :
            <SideBar show={isSideBarOpen} />}
    </>
    )
}

