import React, { useEffect, useState } from 'react';
import { finalize } from 'rxjs';
import { hideLoader, showLoader } from '../../../services/core/loader-service';
import { postReportIncorrect } from '../../../services/duty-calc-service';
import { reportIncorrectFormValidation } from '../../../utils/schemas';
import { useFormik } from 'formik';
import { showSuccessToaster } from '../../../utils/toaster/toaster';

export const ReportIncorrectModal = (props) => {

    let [gstList, setGstList] = useState([]);
    let [selectGst, setSelectGst] = useState([]);


    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: {},
        validationSchema: reportIncorrectFormValidation,
        enableReinitialize: true,
        onSubmit: (value) => {
            reportIncorrect(value.message)
        }
    })

    const reportIncorrect = (msg) => {
        showLoader();
        let hscode = localStorage.getItem('selectedHsCode');
        let subscription = postReportIncorrect({
            hs_code: hscode,
            message: msg
        })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                props.onClose(selectGst);
                props.hideModal(false)
            });
    };





    return <>
        <div style={{ height: 370, width: '100%' }}>
            <form onSubmit={handleSubmit}>
                <div className="input-container" style={{ height: '150px' }}>
                    <textarea style={{ resize: 'none', padding: '8px 8px',width:'100%',maxWidth: '100%' }} name='message' onChange={handleChange} rows="6" placeholder='E.g...BCD is incorrect, AIDC is 5%'></textarea>
                    {errors.message && <p className='error'>{errors.message}</p>}
                </div>
                <button type="submit" className="btn btn-primary" >Submit</button>&nbsp;
                <br></br>
            </form>
        </div>
    </>

}