import axios from 'axios';
import { decryptData, encryptData } from '../../utils/helper/helper';

export function networkEncryptionInterceptor() {

    axios.interceptors.request.use(request => {
        console.log(request.url , request.params , request.body, "request------------->> midd")
        let encUrl, enc_params;
        if (request.url) {
            const req_url_arr = request.url.split('.com/');
            // debugger
            // console.log(request,"request")
            if (request.params) {
                request.params['_time'] = new Date().getTime();
                enc_params = encryptData(JSON.stringify(request.params));
            }
            encUrl = req_url_arr[0] + '.com/' + encryptData(req_url_arr[1]);
            console.log(encUrl, "encUrl")
        }
        request.data = { q: encryptData(JSON.stringify(request.data)) };
        request.url = encUrl;

        if (enc_params) {
            request.params = { q: enc_params };
        }
        return request;
    });

    axios.interceptors.response.use((response) => {
        if (response && response.data && response.data._res) {
            response.data = decryptData(response.data._res);
        }
        return response;
    }, (error) => {
        console.error("error", error)
    });
}
