import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { finalize } from 'rxjs';
import { hideLoader, showLoader } from '../../../services/core/loader-service';
import { getPartnerCode, productSearch, getPTA, getFLADetails, getROODetails } from '../../../services/duty-calc-service';
import { DotLoader } from '../Loader/DotLoader';

export const PTAModal = (props) => {

    let [PTAList, setPTA] = useState([]);
    let [flaData, setFLAData] = useState(null);
    let [rooData, setRooData] = useState(null);
    let [CFData, setCFData] = useState(null);
    let [showAccordian, setShowAccordian] = useState(false);
    let [selectedPtaRow, setSelectedPtaRow] = useState(null);
    let [selectedPartnerCode, setPartnerCode] = useState(null);
    let [selectedRowID, setRowID] = useState(null);
    let [selectedData, setData] = useState(null);

    useEffect(() => {
        fetchPartnerCode();

    }, [])

    const fetchPartnerCode = () => {
        console.log(props, "props")
        showLoader();
        let subscription = getPartnerCode(props.initialData.country)
            .pipe(finalize(() => {

                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let partnerCode = response.data.partner_code;
                fetchPTA(partnerCode)
                setPartnerCode(partnerCode);
                localStorage.setItem("partnerCode", partnerCode);
            })
    };

    const fetchPTA = (partnerCode) => {

        let subscription = getPTA(partnerCode, props.initialData.hs_code)
            .pipe(finalize(() => {

                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let pTAList = response.data;
                pTAList.forEach((list, index) => {
                    list['id'] = (index + 1) * 97
                })
                setPTA(pTAList);
                hideLoader();
            });
    };

    const openAccordian = (ftaID, rowId) => {
        let id = ftaID ? ftaID : "";
        console.log(rowId)
        setShowAccordian(true);
        setSelectedPtaRow(rowId);
        fetchFLAData(props.initialData.hs_code, id, selectedPartnerCode);
    }

    const hideAccordian = () => {
        setShowAccordian(false);
        setSelectedPtaRow(null);
    }


    const fetchFLAData = (hscode, ftaID, partnerCode) => {
        showLoader();
        let subscription = getFLADetails(hscode, ftaID, partnerCode)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let data = response.data;
                setFLAData(data);
                fetchRooData(ftaID, partnerCode)
            });
    };

    const fetchRooData = (ftaID, partnerCode) => {
        showLoader();
        let subscription = getROODetails(ftaID, partnerCode)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let rooList = response.data.rulesofOrigins
                let CFList = response.data.certificates;
                setRooData(rooList);
                setCFData(CFList)
            });
    };

    const selectRow = (row, index) => {
        setRowID(index);
        setData(row);
    }

    function onConfirmHandler() {

        props.onClose(selectedData);
        props.hideModal(false)
    }

    function getLinkfromHTML(temp1) {
        if (!temp1) {
            return
        }

        let temp2 = temp1.split("href=")
        let temp3 = temp2[1];
        let temp4 = temp3.split("target")
        let temp5 = temp4[0].split("'")
        return temp5[1];
    }

    function getFileLabalfromHTML(temp1) {
        if (!temp1) {
            return
        }

        let temp2 = temp1.split("target='_blank'>");
        let temp3 = temp2[2];
        let temp4 = temp3.split("</a>")
        return temp4[0];
    }


    return <>
        <table className="table table-user">

            <thead>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Tariff</th>
                    <th>Note</th>
                </tr >


            </thead >
            <tbody>
                {PTAList && PTAList.map((item, index) => {
                    return <>
                        <tr key={index}>
                            <td><input checked={index == selectedRowID} onClick={() => selectRow(item, index)} type="checkbox"></input></td>
                            <td>{item.pta_name}</td>
                            <td>{item.tariff_text}</td>
                           
                                <td className={selectedPtaRow === index ? 'uparrow' : 'downarrow'}>
                                    <a className='down' id={index} onClick={(e) => openAccordian(item.fta_id, index)}><i className="fa fa-sort-desc"></i> </a>
                                
                                <a className='upp' id={index} onClick={() => hideAccordian()}><i className="fa fa-sort-up"></i></a>
                                </td>
                            
                        
                    

                        </tr>
                        {(showAccordian && selectedPtaRow == index) ?
                        <tr style={{ display: 'table-row' }}>
                            <td colSpan="6">
                              
                                    <>
                                        <table className="table table-user" >

                                            <thead>
                                                <tr>

                                                    <th>Agreement information</th>
                                                    <th>Rule of origin</th>
                                                    <th>certificate of origin</th>
                                                </tr >


                                            </thead >
                                            <tbody>

                                                <td>Name <br></br>{flaData && flaData.agreement_name}
                                                    <br></br><br></br>{flaData && flaData.status} <br></br>{flaData && flaData.status_date}
                                                    <br></br><br></br>type <br></br>{flaData && flaData.type}
                                                    <br></br><br></br>scope <br></br>{flaData && flaData.scope}
                                                    <br></br><br></br>Member States <br></br>{flaData && flaData.member_states}</td>
                                                <td>{
                                                    rooData ? rooData.map((rooObj) => {
                                                        return <>
                                                            {

                                                                <>
                                                                    {rooObj.rooLink ?
                                                                        <>
                                                                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                                            &nbsp;&nbsp;
                                                                            <a target={'blank'} href={getLinkfromHTML(rooObj.rooLink)}>{getFileLabalfromHTML(rooObj.rooLink)}</a>
                                                                            <br /><br />
                                                                        </> :
                                                                        <DotLoader />
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }) : <DotLoader />
                                                }</td>
                                                <td>{
                                                    CFData && CFData.map((rooObj) => {
                                                        return <>
                                                            {/* <i className="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;&nbsp; */}
                                                            <div dangerouslySetInnerHTML={{ __html: rooObj.certificateLink }} />
                                                            

                                                        </>

                                                    })
                                                }</td>
                                            </tbody>
                                        </table>
                                    </> 
                            </td>
                        </tr>

: null
                                }
                    </>
                })}

                {(PTAList && PTAList.length == 0) ?
                    <a style={{ textAlign: 'center' }}>No Records Found </a> : null}
            </tbody>
        </table >

        <Button className='btn-theme' variant="contained" onClick={() => onConfirmHandler()}>Select</Button>
    </>
}