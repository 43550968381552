import React, { memo, useCallback } from "react";
import PropTypes from 'prop-types';

const Search = ({ onSearch, placeHolder }) => {

    

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 600);
        }
    }
    const handleChange = (event) => {
        onSearch(event.target.value);
    }


    const optimisedVersion = useCallback(debounce(handleChange), []);

    return (
        <div>
            <div className='search-group mt-2 mb-2'>
                <input type="text" className="input-control" onChange={optimisedVersion} placeholder={placeHolder} />
            </div>
        </div>
    );
}

Search.propTypes = {
    onSearch: PropTypes.func.isRequired,
    placeHolder: PropTypes.string.isRequired
};

export default memo(Search);