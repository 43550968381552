import { from, map } from "rxjs";
import HTTP_CLIENT from "../factory/modules/http-client";
import { HTTP_STATUS } from "../utility/constants/common-constants";
import AppConfig from "../factory/modules/app-config";
import { showErrorToaster } from "../utils/toaster/toaster";



export const listUsers = (args) => {
    args.url = AppConfig.APIS.userList;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const addUser = (args) => {
    args.url = AppConfig.APIS.userList;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.CREATED) {
                return res.data;
            }
            return false;
        }))
}


export const editUser = (args, id) => {
    args.url = `${AppConfig.APIS.userList
        }/${id}`;
    return from(HTTP_CLIENT.PUT(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const deleteUser = (id) => {
    let args = {};
    args.url = `${AppConfig.APIS.userList
        }/${id}`;

    return from(HTTP_CLIENT.DELETE(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const actiavteUser = (id, payload) => {
    let args = {
        data: payload
    };
    args.url = `${AppConfig.APIS.userList
        }/${id}`;

    return from(HTTP_CLIENT.PATCH(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const getProfile = (args) => {
    args.url = AppConfig.APIS.profile;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const updateProfile = (args) => {
    args.url = `${AppConfig.APIS.profile}`;
    return from(HTTP_CLIENT.PUT(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}



export const bannerData = (args) => {
    args.url = AppConfig.APIS.banner;
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            if (res && !res.data) {
                showErrorToaster("Service is not running");
            }
            return false;
        }))
}


