
import React, { useState, useEffect, useCallback } from 'react';
import "../../static/css/pages/users/user.css";
import '../../../lib/static/css/modal/modal.module.css';
import Modal from '../Common/Modal/Modal';
// import { AddEditUserPage } from "./AddEditUser";
import { showLoader, hideLoader } from "../../services/core/loader-service";
import { ConfirmModal } from "../Common/ConfirmModal/ConfirmModal";
import * as constantsType from "../../utility/constants/common-constants";
import { showSuccessToaster } from "../../utils/toaster/toaster";
import { DataTable } from "../Common/DataTable/DataTable";
import { actiavteUser, deleteUser, listUsers } from "../../services/user-service";
import { finalize } from "rxjs";
import Search from "../Common/Search/Search";
import "../../../lib/static/css/global/global.css";
import { USER_MANAGEMENT, USER_PAGE } from '../../utility/constants/page-label-constants';
import { Breadcrumb } from '../../components/Common/Breadcrumb/Breadcrumb';
import { listNotification } from '../../services/notification-service';


export const NotificationPage = () => {

    const [showDeletConfirmModal, setDeleteShowConfirmModal] = useState(false);
    const [showActivateConfirmModal, setActivateShowConfirmModal] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [sortable, setSortable] = useState([]);
    const [sort, SetSort] = useState(new Map())
    const PER_PAGE = 10;

    useEffect(() => {
        getNotificationList('', currentPage);
    }, [currentPage]);

    const handlePageClick = (selectedObject) => {
        let selectedPage = selectedObject.selected + 1;
        setCurrentPage(selectedPage)

    }

    const getNotificationList = (params, selectedPage = 1) => {
        showLoader();

        let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE, page: selectedPage, _page_size: PER_PAGE };
        let notificationListubscription = listNotification({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                notificationListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                setNotificationList(response.results);
                setPageCount(response.count);
                setSortable(['first_name', 'last_name', 'email', 'mobile_number']);
            });
    }

    const onSearchHandler = useCallback((searchValue) => {
        let params = { _q: searchValue };
        getNotificationList(params);
    }, []);




    const onSortingChange = (params, key, sortBy) => {
        let sortPair = new Map()
        if (key && sortBy) {
            sortPair.set(key, sortBy)
            SetSort(sortPair);
        } else {
            SetSort(sortPair);
        }

        getNotificationList(params);
    }



    const applyDelete = (data) => {
        showLoader();
        const id = data['id'];
        let deleteSubscription = deleteUser(id)
            .pipe(finalize(() => {
                hideLoader();
                deleteSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                getNotificationList('');
            });
    }

    const applyActivate = (data) => {
        showLoader();
        const id = data['id'];
        let activateSubscription = actiavteUser(id)
            .pipe(finalize(() => {
                hideLoader();
                activateSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                getNotificationList('');
            });
    }

    let headerConfig = [
        { colLabel: 'Date', colKey: 'create_date' },
        { colLabel: 'Notification', colKey: 'message' },
        { colLabel: 'Type', colKey: 'type' },
    ];

    // let actionList = [

    // ];



    return (
        <React.Fragment>

            <section className='users-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='title-buttons'>
                                <h2 >Notification Page</h2>

                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='form-inline'>

                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='search-group mt-2 mb-2'>
                                <Search onSearch={onSearchHandler} placeHolder={"Search..."} />
                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                    <DataTable rowData={notificationList} headerGroup={headerConfig}
                        totalPage={pageCount} onPageChange={handlePageClick}
                        sortGroup={sortable}
                        sort={sort}
                        onSortingChange={onSortingChange}
                        >
                    </DataTable>
</div>
</div>
                </div>
            </section>




            {showDeletConfirmModal && <Modal show={showDeletConfirmModal} onClose={() => setDeleteShowConfirmModal(false)} modal_title="Confirm">
                <ConfirmModal initialData={{
                    'data': selectedUser,
                    'action': 'delete'
                }} onClose={applyDelete}
                    hideModal={setDeleteShowConfirmModal} />
            </Modal>}

            {showActivateConfirmModal && <Modal show={showActivateConfirmModal} onClose={() => setActivateShowConfirmModal(false)} modal_title="Confirm">
                <ConfirmModal initialData={{
                    'data': selectedUser,
                    'action': 'activate'
                }} onClose={applyActivate}
                    hideModal={setActivateShowConfirmModal} />
            </Modal>}

        </React.Fragment>
    );
}
