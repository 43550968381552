import { createSlice } from '@reduxjs/toolkit';
import * as constantType from "../../utility/constants/common-constants";

const initialState = {
    profile: localStorage.getItem(constantType.ACTION_PROFILE),
}

const UserProfileSlice = createSlice({
    name: constantType.ACTION_PROFILE,
    initialState,
    reducers: {
        setUserProfileData: (state, action) => {
            state.profile = action.payload;
        },
        clearUserProfileData: state => {
            state.profile = null;
        }
    }
});


export const { clearUserProfileData, setUserProfileData } = UserProfileSlice.actions;
export const UserProfileSelector = state => state;
export default UserProfileSlice.reducer;
