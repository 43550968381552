import { Button } from "@mui/material"
import logo from "../../static/icon/logo.svg";

export const TermAndCondition = () => {

    let getAuth = () => {
        return localStorage.getItem('accessToken');
    }

    return <>
        {!getAuth() &&
            <>
                <header >
                    <div className="navbrand">

                        <h3 className={'head'}>
                            <img className='logo-head logoterms' src={logo} />
                        </h3>
                    </div>
                    <a href="/login" className="loginbtn-terms">

                        <Button className='btn-theme' variant="contained" >Login</Button>
                    </a>
                </header>
            </>}

        <br /><br /><br />
        <div className="term">

            These Terms and Conditions governs the use of the information that are made available by
            Cusbuzz.com (the “APP”). By using this App owned, hosted or maintained by Cusbuzz.com, you, the
            user, are deemed to have agreed to comply with the following terms and conditions of use, which is
            a legally binding agreement between you and Cusbuzz.com. The terms &quot;you&quot; and &quot;User&quot; and
            “Visitor” and “Subscriber” refer to anyone who accesses the Cusbuzz.com APP.
            Please note that Cusbuzz.com may change these Terms &amp; Conditions any time without prior notice.
            Changes will be published on Cusbuzz.com under “terms of use”.

            <br /><br /><b>1. TERMS OF USE</b><br />
           
                    Your use of this APP and Services provided is subject to the following terms of use:
                    <ul className="listt">
                        <li>
                            The content of the pages of this APP is for your general information and use only. It is
                            subject to change without notice. Unauthorized use of this APP may give rise to a claim for
                            damages and/or be a criminal offence.</li>
                        <li> This APP uses cookies to monitor browsing preferences.</li>
                        <li> By browsing and visiting the APP you agree that you have read and understood these Terms
                            of Use and you agree to be bound by these Terms of Use and use these Services in
                            compliance with these Terms of Use.</li>
                        <li>Any violation of these Terms of Use may result in legal liability upon you. Nothing in these
                            terms of Use should be construed to confer any rights to any third party or any other person.
                            Your use of the APP means you are consenting to this agreement.</li>
                        <li> The service statements on this APP are for general description purposes only. The
                            information contained on this APP should not be viewed as an offer to sell or as a solicitation
                            to purchase any of our services.</li>
                        <li> All materials herein and all Cusbuzz.com software are proprietary to Cusbuzz.com and
                            protected by copyright and other intellectual property laws. Except as stated herein, none of
                            the materials may be modified, copied, reproduced, distributed, republished, downloaded,
                            displayed, sold, compiled, posted or transmitted in any form any part or whole of the
                            database.</li>
                        <li> You agree to use the APP only for lawful purposes. You agree not to take any action that
                            might compromise the security of the APP, render the APP inaccessible to others or
                            otherwise cause damage to the APP or the content therein. You agree not to use the APP in
                            any manner that might interfere with the rights of third parties.</li>
                        <li> As a condition of your use of the App, you will not use the App for any purpose that is
                            unlawful or prohibited by these Terms or by any domestic or international laws, statutes,
                            ordinances and regulations.</li>
                        <li> You acknowledge and agree that nothing in these Terms shall have the effect of transferring
                            the ownership of any copyrights, trademarks, service marks, trade names or other
                            proprietary rights in App or any part thereof to you or any third party.</li>
                        <li> Cusbuzz.com expressly disclaims any and all warranties, express or implied, without
                            limitation:
                
<ol className="listsquar">
                <li> Cusbuzz.com will not be responsible or liable for any inaccuracies, errors or
                omissions of any data, opinion, statement or other information displayed,
                uploaded or distributed through the App.</li>
               <li> You acknowledge that any reliance upon any such opinion, statement or
                information shall be at your sole risk.
                </li>
                </ol>
          
              </li>
                <li>All information about Standard Duties, Preferential Duties, Cess, GST, Notifications etc on
                the Cusbuzz.com App is fetched through Indian Customs Notifications, Circulars, Documents
                on Free trade Agreements, Preferential trade agreements.Cusbuzz.com will not be liable for
                any kind of loss / damages / liabilities for any inaccuracy of the information or non - availability
                of the information.Customers / Buyers will not be entitled to any REFUNDS for any
                inaccuracy of such data for the aforementioned reasons.</li>
               <li> Private Subscription: Subscription to the Cusbuzz.com database is strictly Private and
                confidential and usage is restricted to self - use only.</li>
                </ul>
           

<br /><b>2. WARRANTY; DISCLAIMER; LIMITATION OF LIABILITY</b><br /><br />
            <p className="term">
                THIS APP AND CONTENT PROVIDED TO YOU BY CUSBUZZ.COM SHALL BE ON & quot;AS IS & quot; BASIS.NEITHER
                CUSBUZZ.COM, ITS AFFILIATES NOR ANY DATA PROVIDER MAKES ANY WARRANTY, EXPRESS OR
                IMPLIED, AS TO THE ACCURACY, TIMELINESS OR COMPLETENESS OF THIS APP OR CONTENT OR AS
                TO THE RESULTS TO BE ATTAINED BY YOU OR OTHERS FROM THE USE OF THE CONTENT.YOU
                HEREBY ACKNOWLEDGE THAT THERE ARE NO EXPRESS OR IMPLIED WARRANTIES OF TITLE,
                MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE AND THAT IT HAS NOT RELIED
                UPON ANY WARRANTY, GUARANTY OR REPRESENTATION MADE BY CUSBUZZ.COM, ITS AFFILIATES
                OR ANY DATA PROVIDER, EXCEPT THE REPRESENTATIONS MADE BY CUSBUZZ.COM SPECIFICALLY
                OUTLINED IN THIS TERMS OF USE.<br />
                <strong>NO LIABILITY.</strong> NEITHER CUSBUZZ.COM, ITS AFFILIATES NOR ANY DATA PROVIDER SHALL IN ANY WAY
                BE LIABLE TO YOU OR ANY CLIENT OF YOU FOR ANY INACCURACIES, ERRORS OR OMISSIONS,
                REGARDLESS OF CAUSE, IN THE CONTENT PROVIDED HEREUNDER OR FOR ANY DAMAGES(WHETHER
                DIRECT OR INDIRECT) RESULTING THEREFROM.WITHOUT LIMITING THE FOREGOING, CUSBUZZ.COM
                SHALL HAVE NO LIABILITY WHATSOEVER TO YOU, WHETHER IN CONTRACT(INCLUDING UNDER AN
                INDEMNITY), IN TORT(INCLUDING NEGLIGENCE), UNDER A WARRANTY, UNDER STATUTE OR
                OTHERWISE, IN RESPECT OF ANY LOSS OR DAMAGE SUFFERED BY YOU AS A RESULT OF OR IN
                CONNECTION WITH ANY OPINIONS, RECOMMENDATIONS, FORECASTS, JUDGMENTS, OR ANY OTHER
                CONCLUSIONS, OR ANY COURSE OF ACTION DETERMINED BY YOU OR ANY CLIENT OF YOU,
                WHETHER OR NOT BASED ON THIS APP OR CONTENT.UNDER NO CIRCUMSTANCES SHALL
                CUSBUZZ.COM BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
                EXEMPLARY DAMAGES, OR FOR DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, LOSS OF USE
                ARISING OUT OR RELATING TO THE USE OF, OR THE INABILITY TO USE THIS APP, THE SERVICES OR
                THE INFORMATION CONTAINED ON THIS SITE, WHETHER BASED UPON WARRANTY, CONTRACT,
                TORT OR OTHERWISE, WHETHER OR NOT CUSBUZZ.COM HAS BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES.IN NO EVENT SHALL CUSBUZZ.COM &#39;S TOTAL LIABILITY TO YOU FOR ALL DAMAGES,
                LOSSES AND CAUSES OF ACTION RESULTING FROM YOUR USE OF THIS SITE INCLUDING THE USE OF
                ANY SERVICES, WHETHER IN CONTRACT, TORT OR OTHERWISE, EXCEED THE TOTAL AMOUNTS PAID
                BY YOU TO CUSBUZZ.COM FOR THE SERVICES IMMEDIATELY PRECEDING THE APPLICABLE CLAIM.
            </p>

        <br /><b>3. APPLICABLE LAW</b><br />
            <p className="term">

                This Agreement shall be governed by, interpreted and construed in accordance with the laws of India.
                The courts at New Delhi, India shall have exclusive jurisdiction over any claims or matters arising out
                of these Terms and Conditions.</p>

          <br /><b>4. ENTIRE AGREEMENT</b><br />
            <p className="term">
                The Terms, as amended from time to time, constitute the entire agreement between you and
                Cusbuzz.com.If any provision of these Terms is considered unlawful, void, or for any reason
                unenforceable, then that provision shall be deemed severable from these Terms and shall not affect
                the validity and enforceability of any remaining provisions.
            </p>
        </div >
    </>

}
