import React, { useState } from 'react';
import "../../../static/css/global/login.css";
import { useParams } from 'react-router-dom';
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { useNavigate } from "react-router-dom";
import { resetPasswordValidation } from '../../../utils/schemas';
import { resetPassword } from '../../../services/auth-service';
import { useFormik } from 'formik';
import { finalize } from 'rxjs';
import { NOT_MATCH_PASSWORD } from '../../../utility/constants/error-constants';
import { LOGIN } from '../../../utility/constants/page-route-constants';

export const ResetPassword = () => {

    const [passwordErr, setPasswordErr] = useState('');
    const initialValues = { new_password: '', confirm_password: '', }
    const navigate = useNavigate();
    const { token } = useParams();

    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: resetPasswordValidation,
        enableReinitialize: true,
        onSubmit: (value) => {
            value['reset_token'] = token;
            if (values.new_password !== values.confirm_password) {
                setPasswordErr(NOT_MATCH_PASSWORD);
                return;
            }
            sendEmail(value)
        }
    })

    const sendEmail = (params) => {
        let payload = { ...params };
        showLoader();
        let subscription = resetPassword({ data: payload })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                navigate(LOGIN);
            });
    }

    const matchPassword = (e) => {
        if (values.new_password !== values.confirm_password) {
            setPasswordErr(NOT_MATCH_PASSWORD);
            return false;
        } else {
            setPasswordErr("");
            return true;
        }
    }


    return (
        <section className="login-wrapper" style={{ background: 'var(--primary-color)' }}>
            <div className="login-form  mw-500 h-350">
                <br />
                <h2 className='text-center'>Reset Password</h2>
                <br /> <br />
                <form onSubmit={handleSubmit}>
                    <div className="input-container ">
                        <label htmlFor="password">New Password</label>
                        <input type="password" className="input-control shadow-input" placeholder="New password" name="new_password" value={values.new_password} onChange={handleChange} />
                        {errors.new_password && <p className='error'>{errors.new_password}</p>}
                    </div>
                    <div className="input-container">
                        <label htmlFor="password">Confirm Password</label>
                        <input type="password" className="input-control shadow-input" placeholder="Confirm password" name="confirm_password" value={values.confirm_password}
                            onBlur={matchPassword} onChange={handleChange} />
                        {errors.confirm_password && <p className='error'>{errors.confirm_password}</p>}
                        {passwordErr && <p className='error'>{passwordErr}</p>}
                    </div>
                    <div className='input-container'>
                        <button type='submit' className="btn btn-primary btn-full">Submit</button>
                    </div>

                </form>
            </div>
        </section>
    )
}