import React from 'react';

export const ConfirmModal = (props) => {

    function onConfirmHandler() {
        props.onClose(props.initialData['data']);
        props.hideModal(false)
    }

    console.log(props)

    return (
        <div>
            <h3 style={{ 'display': props.initialData.action == 'activate' ? 'block' : 'none' }} className="text-center">{props.initialData.data['is_active'] ? 'Are you sure, you want to Inactive ?' : 'Are you sure want to Active ?'} </h3>&nbsp;&nbsp;

            <h3 style={{ 'display': props.initialData.action == 'delete' ? 'block' : 'none' }} className="text-center">{'Are you sure, you want to Delete ?'} </h3>&nbsp;&nbsp;

            <div className='input-container text-center' >
                <button type="button" className="btn btn-primary" onClick={(event) => onConfirmHandler()}>Confirm</button>&nbsp;
                <button type="button" className="btn btn-default" onClick={(event) => props.closeModal(false)}>Cancel</button>

            </div>
        </div>
    );
}