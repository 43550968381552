import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './lib/static/css/variable.css';
import { AppRouter } from './lib/router/AppRoutes';
import { Provider } from 'react-redux';
import { store } from "./lib/redux/store";
toast.configure();

function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <AppRouter />
    </Provider>
  );
}
export default App;
