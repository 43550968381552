import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "../../static/css/pages/profile.css";
import { showLoader, hideLoader } from "../../services/core/loader-service";
import Modal from '../Common/Modal/Modal';
import { UpdateProfilePage } from "./UpdateProfile";
import { getProfile } from '../../services/user-service';
import { finalize } from 'rxjs';
import { CHANGE_PASSWORD } from '../../utility/constants/page-route-constants';

export const ProfilePage = () => {

  const navigate = useNavigate();
  const [profileData, setProfileData] = useState([]);
  const [show, setShow] = useState(false);
  const [userShortName, setUserShortName] = useState("");

  useEffect(() => {
    getProfileData()
  }, []);

  const getProfileData = () => {

    showLoader();
    let subscription = getProfile({})
      .pipe(finalize(() => {
        hideLoader();
        subscription = null;
      }))
      .subscribe(response => {
        setProfileData(response['data']);
        let shortName = response['data']?.first_name?.charAt(0) + "" + response['data']?.last_name?.charAt(0);
        setUserShortName(shortName)
      });
  }


  const disableBackBtn = () => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }

  const onAddUserHandler = () => {
    setShow(true);

  }

  const onHideModalHandler = () => {
    getProfileData()
  }



  disableBackBtn();

  return (
    <section className='profile-wrapper'>
      <div className='container-fluid'>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='title-buttons'>
              <h2 className='title'>Profile Page</h2>
              <div className='buttons ml-auto'>
                <button onClick={onAddUserHandler} type="button" className="btn-edit ml-1" ><i className='fa fa-edit'></i></button>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='card-profile'>
              <figure>

                <div id="shortname" style={{ marginTop: "28px",
                "fontSize": "37px" }}>
                  {userShortName}
                </div>
              </figure>
              <figcaption>
                <h4>{profileData.first_name} {profileData.last_name}</h4>
                <p>Email: {profileData.email ? profileData.email : 'NA'}</p>

              </figcaption>
              <div className='text-center'>

                <button type="button" className='change-btn' onClick={() => {
                  navigate(CHANGE_PASSWORD
                  )
                }}>Change Password
                </button>
              </div>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className='card-profile'>
              <div className='row'>


                <div className='col-lg-4 mb-1'>
                  <label>First Name</label>
                  <h3>{profileData.first_name ? profileData.first_name : "-"}</h3>
                  <hr />
                </div >
                <div className='col-lg-4 mb-1'>
                  <label>Last Name</label>
                  <h3>{profileData.last_name ? profileData.last_name : "-"}</h3>
                  <hr />
                </div>

                <div className='col-lg-4 mb-1'>
                  <label>Mobile</label>
                  <h3>{profileData.mobile_number ? profileData.mobile_number : "-"}</h3>
                  <hr />
                </div>

                <div className='col-lg-6 mb-1'>
                  <label>Email:</label>
                  <h3>{profileData.email ? profileData.email : 'NA'}</h3>
                  <hr />
                </div>

              </div >
            </div >
          </div >
        </div >
      </div >
      {show && <Modal show={show} initialData={{}} onClose={() => setShow(false)} modal_title={"Update Profile"}>
        {<UpdateProfilePage closeModal={setShow} onHide={onHideModalHandler} initialData={{ 'data': (profileData ? profileData : {}) }} />}

      </Modal>}


    </section >

  );
}