import React, { useEffect, useState } from 'react';
import { lookupFormValidation } from '../../../utils/schemas/index';
import { useFormik } from 'formik';
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import { addLookup, editLookup } from "../../../services/lookup-service";
import { finalize } from "rxjs";
import { listLookupType } from '../../../services/lookup-type-service';

export const AddEditLookupPage = (props) => {

    const [lookupTypeList, setLooupTypes] = useState([]);
    let copyProps = JSON.parse(JSON.stringify(props));

    useEffect(() => {
        getLookupTypes();
    }, []);

    const getLookupTypes = () => {
        showLoader();
        let filter = { _page: 1, _page_size: 10000 };

        let subscription = listLookupType({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                let list = response['data'];
                let selectedId = props.initialData.data ? props.initialData.data.lookup_type_id : null;
                let isMatched = false;

                list.forEach((obj) => {
                    if (obj.id == selectedId) {
                        isMatched = true;
                    }
                });

                if (!isMatched) {
                    copyProps.initialData.data.lookup_type_id = "";
                }

                setLooupTypes(response['data'])
            });
    }



    let initValue = {
        name: '',
        key: '',
        order: undefined,
        lookup_type_id: ''
    };

    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: copyProps.editmode ? copyProps.initialData.data : initValue,
        validationSchema: lookupFormValidation,
        onSubmit: (value) => {
            if (copyProps.action == 'Add') {
                create(value)
            } else {
                update(value)
            }
        }
    })

    const create = (params) => {
        let payload = { ...params };
        showLoader();
        let addSubscription = addLookup({ data: payload })
            .pipe(finalize(() => {
                hideLoader();
                addSubscription = null;
            }))
            .subscribe(response => {
                showSuccessToaster(response.message);
                closeAndUpdateModel();
            });
    }


    const closeAndUpdateModel = () => {
        props.closeModal(false);
        props.onHide();
    }


    const update = (params) => {
        let payload = { ...params };
        showLoader();
        let id = props.initialData.data.id;
        let updateSubscription = editLookup({ data: payload }, id)
            .pipe(finalize(() => {
                hideLoader();
                updateSubscription = null;
            }))
            .subscribe(response => {
                showSuccessToaster(response.message);
                closeAndUpdateModel();
            });
    }

    return (

        <form onSubmit={handleSubmit}>
            <div className="input-container">
                <label>Name <sup>*</sup></label>
                <input type="text" className="input-control" placeholder="Enter Name" name="name" value={values.name} onChange={handleChange} />
                {errors && <p className='error'>{errors.name}</p>}
            </div>
            <div className="input-container">
                <label>Key <sup>*</sup></label>
                <input type="text" className="input-control" placeholder="Enter Key" name="key" value={values.key} onChange={handleChange} />
                {errors && <p className='error'>{errors.key}</p>}
            </div>

            <div className="input-container">
                <label>Order </label>
                <input type="number" className="input-control" placeholder="Order" name="order" value={values.order} onChange={handleChange} />
                {errors && <p className='error'>{errors.order}</p>}
            </div>

            <div className="input-container">
                <label>Lookup Type <sup>*</sup></label>
                <select placeholder='select lookup type' name='lookup_type_id' className="input-control" onChange={handleChange} value={values.lookup_type_id}>
                    <option value="">Select Lookup Type</option>
                    {lookupTypeList && lookupTypeList.map((item, index) => {
                        return (<option key={index} value={item.id}>{item.name}</option>)
                    })}
                </select>
                {errors && <p className='error'>{errors.lookup_type_id}</p>}
            </div>


            <div className='input-container'>
                <button type="submit" className="btn btn-primary" >Submit</button>&nbsp;
            </div>
        </form>
    );
}