

import React, { useState, useEffect, useCallback } from 'react';
import { showLoader, hideLoader } from "../../services/core/loader-service";
import { store } from "../../redux/store";
import { useNavigate, useParams } from 'react-router-dom';
import * as constantsType from "../../utility/constants/common-constants";
import { setLoginData } from '../../redux/auth/auth-reducer';


export const AcceptSubscriptionPayment = () => {

    const navigate = useNavigate();
    const { id, token } = useParams();

    useEffect(() => {
        showLoader();
        setLoginSession(id, token)
    }, []);

    const setLoginSession = (id, token) => {
        localStorage.setItem(constantsType.ACCESS_TOKEN, token);
        store.dispatch(setLoginData({
            access:token,
            refersh:token
        }));
        hideLoader();
        navigate('/subscription-payment/' + id)
    }

    return (
        <React.Fragment>
        </React.Fragment >
    );
}
