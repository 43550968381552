import { store } from "../redux/store";
import { clearLoginData, setLoginData } from "../redux/auth/auth-reducer";
import HttpClient from "../factory/modules/http-client";
import { from, map } from "rxjs";
import * as constantsType from "../utility/constants/common-constants";
import { HTTP_STATUS } from "../utility/constants/common-constants";
import AppConfig from "../factory/modules/app-config";
import { redirectTo } from "../redux/common/router-redirect-reducer";
import HTTP_CLIENT from "../factory/modules/http-client";
import { showErrorToaster } from "../utils/toaster/toaster";

const reduxStore = store;

export const login = (args) => {
    args.url = AppConfig.APIS.login;
    // args['headers']['App-Source'] = constantsType.ADMIN;
    return from(HttpClient.POST(args)).pipe(map(res => {
        if (res && res.status === HTTP_STATUS.OK) {
            localStorage.setItem(constantsType.ACCESS_TOKEN, res.data.data.access);
            store.dispatch(setLoginData(res.data.data));
            return res.data;
        }
        if (!res.data) {
            showErrorToaster("Service is not running");
        }
        return false;
    }));
}

export const register = (args) => {
    args.url = AppConfig.APIS.register;
    // args['headers']['App-Source'] = constantsType.ADMIN;
    return from(HttpClient.POST(args)).pipe(map(res => {
        if (res && res.status === HTTP_STATUS.CREATED) {
            return res.data;
        }
        return false;
    }));
}

export const logout = () => {
    localStorage.removeItem(constantsType.ACCESS_TOKEN);
    localStorage.removeItem(constantsType.PERMISSION_SET);
    localStorage.removeItem('profile')

    reduxStore.dispatch(clearLoginData());
    reduxStore.dispatch(redirectTo('/login'))
}

export const isLogin = () => {
    const loginReducer = reduxStore.getState().login;
    return loginReducer.isLoggedIn;
}

export const forgotPassword = (args, id) => {
    args.url = `${AppConfig.APIS.forgot_password}`;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const changePassword = (args, id) => {
    args.url = `${AppConfig.APIS.change_password}`;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const resetPassword = (args, id) => {
    args.url = `${AppConfig.APIS.reset_password}`;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


