import CryptoJS from 'crypto-js';

export const decryptData = (ciphertext) => {
  var Base64CBC = ciphertext;
  var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_IV);
 
  var key = process.env.REACT_APP_ENC_KEY;
  key = CryptoJS.enc.Utf8.parse(key);
 
  var decrypted = CryptoJS.AES.decrypt(Base64CBC, key, { iv: iv, mode: CryptoJS.mode.CBC });
  decrypted = decrypted.toString(CryptoJS.enc.Utf8);
 
  return JSON.parse(decrypted);
};


export const encryptData = (message) => {
  var key = process.env.REACT_APP_ENC_KEY;
  key = CryptoJS.enc.Utf8.parse(key);

  var iv = process.env.REACT_APP_ENC_IV;
  var ivBytes = CryptoJS.enc.Utf8.parse(iv)
  var encrypted = CryptoJS.AES.encrypt(message, key, { iv: ivBytes, mode: CryptoJS.mode.CBC });

  encrypted = encrypted.toString();

  return encrypted;
}


