import * as Yup from 'yup';


export const LoginFormValidation = Yup.object({
    username: Yup.string().required("Please enter email"),
    password: Yup.string().required("Please enter password")
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
})

export const SignUpFormValidation = Yup.object({
    first_name: Yup.string().required("Please enter first name").max(20),
    last_name: Yup.string().max(20),
    email: Yup.string().when('company', {
        is: (value) => !value,
        then: Yup.string().required('Email is required'),
        otherwise: Yup.string(),
    }),
    mobile_number: Yup.string().required("Please enter mobile").matches(/^\+\d{1,3}\d{6,13}$/, 'Invalid Mobile Format, Ex. +9188********'),
})

export const userFormValidation = Yup.object({
    first_name: Yup.string().required("Please enter first name"),
    last_name: Yup.string(),
    email: Yup.string().required("Please enter email").email().matches(/@[^.]*\./),
    mobile_number: Yup.string().required("Please enter mobile").matches(/^\+\d{1,3}\d{6,13}$/, 'Invalid Mobile Format, Ex. +9188********'),
});

export const alertFormValidation = Yup.object({
    name: Yup.string().required("Please enter alert name"),
    
})

export const lookupFormValidation = Yup.object({
    name: Yup.string().required("Please select name"),
    key: Yup.string().required("Please select key"),
    order: Yup.number(),
    lookup_type_id: Yup.string().required("Please select lookup type")
})

export const lookupTypeFormValidation = Yup.object({
    name: Yup.string().required("Please select name"),
    key: Yup.string().required("Please select key"),

})

export const RoleFormValidation = Yup.object({
    name: Yup.string().required("Please select name"),
    key: Yup.string().required("Please select key"),
    default_module_action_id: Yup.string().required("Please select default action")
})

export const ForgotPasswordValidation = Yup.object({
    email: Yup.string().required("Please enter email"),
})

export const ChangePasswordValidation = Yup.object({
    old_password: Yup.string().required("Please enter Old Password"),
    new_password: Yup.string().required("Please enter New Password"),
    confirm_password: Yup.string().required("Please enter Confirm password"),
})

export const resetPasswordValidation = Yup.object({
    new_password: Yup.string().required("Please enter New Password"),
    confirm_password: Yup.string().required("Please enter Confirm password"),
})



export const profileFormValidation = Yup.object({
    first_name: Yup.string().required("Please enter first name").max(20),
    last_name: Yup.string().max(20),
    email: Yup.string().required("Please enter email"),
    mobile_number: Yup.string().required("Please enter mobile").matches(/^\+\d{1,3}\d{6,13}$/, 'Invalid Mobile Format, Ex. +9188********'),
})

export const reportIncorrectFormValidation = Yup.object({
    message: Yup.string().required("Please Enter your remarks").max(500,'Remarks should be less than 500 characters'),
    
})
