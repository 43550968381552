import React from "react";
import ReactPaginate from "react-paginate";
import PropTypes from 'prop-types';
import RcIf from '../../../utils/RcIf/RcIf';
import { Switch } from "@mui/material";

export const DataTable = ({ rowData, headerGroup, totalPage, sortGroup, onPageChange, actionGroup, onSortingChange, sort }) => {


    headerGroup.forEach((col) => {
        col['sortable'] = sortGroup.includes(col.colKey) ? true : false;
        let map = sort;
        if (map && map.has(col.colKey)) {
            col['sortBy'] = map.get(col.colKey)
        }
    })

    const sorting = (key, sortBy) => {
        headerGroup.forEach((col) => {
            col['sortBy'] = null
        });

        headerGroup.forEach((colP) => {
            if (colP.colKey == key && sortBy == '+') {
                colP['sortBy'] = "+"
            }

            if (colP.colKey == key && sortBy == '-') {
                colP['sortBy'] = "-"
            }
        });

        let params = {};
        const sortKey = '_sort';

        if (sortBy == '+') {
            params[sortKey] = key;
        } else {
            params[sortKey] = sortBy + key;
        }

        onSortingChange(params, key, sortBy);
    }

    const resetSorting = (key) => {
        headerGroup.forEach((col) => {
            if (col.colKey == key) {
                delete col['sortBy'];
            }
        });
        onSortingChange({}, null, null);
    }

    return (<>
        <div className="table-responsive">
            <table className="table table-user">
                <thead>
                    <tr>
                        {headerGroup && headerGroup.map((header) => {
                            return (
                                <th key={header.colKey} className="col" >

                                    <RcIf if={!header?.sortable} >
                                        {header.colLabel}
                                    </RcIf>

                                    <RcIf if={header?.sortable} >
                                        {header.colLabel}&nbsp;

                                        <RcIf if={header.sortBy !== '+' && header.sortBy !== '-'} >
                                            <i className="fa fa-sort" onClick={() => sorting(header.colKey, '-')} aria-hidden="true"></i>
                                        </RcIf>

                                        <RcIf if={header.sortBy == '+'} >
                                            <i className="fa fa-caret-down" onClick={() => sorting(header.colKey, '-')} aria-hidden="true"></i>
                                        </RcIf>

                                        <RcIf if={header.sortBy == '-'} >
                                            <i className="fa fa-caret-up" onClick={() => sorting(header.colKey, '+')} aria-hidden="true"></i>
                                        </RcIf>


                                    </RcIf>

                                    <RcIf if={header.sortBy == "+" || header.sortBy == "-"} >
                                        <span onClick={() => resetSorting(header.key)} >
                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </span >
                                    </RcIf>
                                </th>
                            )

                        })}
                        {actionGroup &&
                            <th key={new Date().getTime()}>Action</th>
                        }
                    </tr >


                </thead >
                <tbody>
                    {rowData && rowData.map((item, index) => {
                        return <tr key={index}>
                            {headerGroup && headerGroup.map((header, i) => {
                                return (
                                    <td key={i}>{item[header.colKey]}</td>
                                )
                            })}

                            {actionGroup &&


                                <td key={new Date().getTime()} style={{ display: 'flex' }}>

                                    {actionGroup && actionGroup.map((action, key) => {
                                        let icon = `fa fa-${action.faIcon}`;
                                        return <>
                                            <RcIf if={action.actionName !== 'active'} >
                                                <button className="btn" key={key}
                                                    onClick={(event) => action.callEvent(event, item)} type="button"  ><i style={{ color: 'var(--primary-color)' }} className={icon}></i></button>
                                            </RcIf>

                                            <RcIf if={action.actionName == 'active'} >
                                                <Switch checked={item.is_active} key={key + 45} onClick={(event) => action.callEvent(event, item)} color="secondary" />
                                            </RcIf>
                                        </>
                                    })}



                                </td>
                            }

                        </tr>
                    })}
                </tbody>
            </table >

</div>
{rowData.length == 0 ? <div style={{ "textAlign": "center" }}>No Records Found</div> : null}

            {

                (<ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    pageCount={totalPage / 10}
                    onPageChange={onPageChange}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                />)

            }
           
        </>
    );
}


DataTable.propTypes = {
    rowData: PropTypes.array.isRequired,
    headerGroup: PropTypes.array.isRequired,
    totalPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    actionGroup: PropTypes.array,
    onSortingChange: PropTypes.func
};