export const HOME_URL = '/';
export const LOGIN_URL = '/login';
export const SIGNUP_URL = '/signup';
export const PROFILE_URL = '/profile';
export const DASHBOARD_URL = '/dashboard';
export const ABOUT_URL = '/about';
export const NOT_FOUND = '/404';
export const TERM_PAGE = '/termAndCondition';
export const FORBIDDEN_PAGE = '/401';
export const C_PASSWORD_URL = '/change-password';
export const F_PASSWORD_URL = '/forgot-password';
export const R_PASSWORD_URL = '/reset-password/:token';
export const MASTER_MGMT_LOOKUP_URL = '/master-management/lookups';
export const MASTER_MGMT_LOOKUPTYPE_URL = '/master-management/lookup-type';

// User Management
export const USER_MGMT_URL = '/user-management/users/list';

// Role Management
export const ROLE_MGMT_URL = '/user-management/role/list';
export const ADD_ROLE_URL = '/user-management/role/add';
export const EDIT_ROLE_URL = '/user-management/role/edit/:id';


export const ALERT_MGMT_URL = '/alerts';
export const NOTIFICATION_MGMT_URL = '/notifications';
export const SUBSCRIPTION_HISTORY_MGMT_URL = '/subscription-history';
export const SUBSCRIPTION_PLAN_MGMT_URL = '/subscription-plan';
export const SUBSCRIPTION_PAYMENT_URL = '/subscription-payment/:id';
export const ACCEPT_SUBSCRIPTION_PAYMENT_URL = '/accept-subscription-payment/:id/:token';
export const DUTY_CALCULATOR_URL = '/duty-calculator';
export const TRNX_DETAILS = '/transaction-details/:id';
export const MAINTAINENCE_PAGE_URL = '/maintain';
export const DASHBOARD_MGMT_URL = '/dashboard';
export const TROUBLE_TICKET = '/trouble-ticket';
export const SEARCH_HISTORY = '/search-history';