import { createSlice } from '@reduxjs/toolkit';
import * as constantType from "../../utility/constants/common-constants";

const initialState = {
    usersData: null
    // isLoggedIn: localStorage.getItem(constantType.ACCESS_TOKEN) ? true : false,
    // loggedInUserData: null,
    // payloadAccessToken: localStorage.getItem(constantType.ACCESS_TOKEN)
}

const UsersSlice = createSlice({
    name: constantType.ACTION_USERS,
    initialState,
    reducers: {
        users: (state) => {
            // state.isLoggedIn = false;
            // state.loggedInUserData = null;
            // state.payloadAccessToken = null;
        },
        getUsersSuccess: (state, { payload }) => {
            // state.isLoggedIn = true;
        },
        getUsersFailure: state => {
            // state.isLoggedIn = false;
            // state.loggedInUserData = null;
            // state.payloadAccessToken = null;
        },

        
        clearUsersData: state => {
            state.usersData = null;
        },
        setUsersResponse: (state, { payload }) => {
            state.usersData = payload;
        }
    }
});

export const { users, getUsersFailure, clearUsersData, usersData,setUsersResponse,getUsersSuccess } = UsersSlice.actions;
// isLoggedIn, loggedInUserData, payloadAccessToken

export const usersSelector = state => state.users;
export default UsersSlice.reducer;