import React, { useState, useEffect, useCallback } from 'react';
import "../../static/css/pages/users/user.css";
import '../../../lib/static/css/modal/modal.module.css';
import Modal from '../Common/Modal/Modal';
import { AddEditAlertPage } from "./AddEditAlert";
import { showLoader, hideLoader } from "../../services/core/loader-service";
import { ConfirmModal } from "../Common/ConfirmModal/ConfirmModal";
import * as constantsType from "../../utility/constants/common-constants";
import { showSuccessToaster } from "../../utils/toaster/toaster";
import { DataTable } from "../Common/DataTable/DataTable";
import { actiavteUser, deleteUser, listUsers } from "../../services/user-service";
import { finalize } from "rxjs";
import Search from "../Common/Search/Search";
import "../../../lib/static/css/global/global.css";
import { actiavteAlerts, deleteAlerts, listAlerts } from '../../services/alert-service';

export const AlertPage = () => {

    const [showDeletConfirmModal, setDeleteShowConfirmModal] = useState(false);
    const [showActivateConfirmModal, setActivateShowConfirmModal] = useState(false);
    const [alertBal, setAlertBal] = useState(0);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [selectedAction, setAction] = useState('Add');
    const [alertList, setAlertsList] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [sortable, setSortable] = useState([]);
    const [sort, SetSort] = useState(new Map())
    const PER_PAGE = 10;

    useEffect(() => {
        getAlertsList('', currentPage);
    }, [currentPage]);

    const handlePageClick = (selectedObject) => {
        let selectedPage = selectedObject.selected + 1;
        setCurrentPage(selectedPage)

    }

    const getAlertsList = (params, selectedPage = 1) => {
        showLoader();

        let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE, page: selectedPage, _page_size: PER_PAGE };
        let alertListubscription = listAlerts({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                alertListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let arr = response.results;
                arr.forEach(element => {
                    element['criteria'] = element.duty_change ? 'Duty Change' : 'New HS Code';
                });
                setAlertsList(response.results);
                setPageCount(response.count);
                setAlertBal(response.balance_alerts);
                setSortable(['first_name', 'last_name', 'email', 'mobile_number']);
            });
    }

    const onSearchHandler = useCallback((searchValue) => {
        let params = { _q: searchValue };
        getAlertsList(params);
    }, []);

    const onSortingChange = (params, key, sortBy) => {
        let sortPair = new Map()
        if (key && sortBy) {
            sortPair.set(key, sortBy)
            SetSort(sortPair);
        } else {
            SetSort(sortPair);
        }

        getAlertsList(params);
    }



    const onHideModalHandler = () => {
        getAlertsList('');
    }

    const onAddHandler = (event, rowData) => {
        setShowAddEditModal(true);
        setAction('Add')
        setSelectedUser(rowData);
    }

    const onEditHandler = (event, rowData) => {
        setShowAddEditModal(true);
        setAction('Edit')
        setSelectedUser(rowData);
    }

    const onDeleteHandler = (event, rowData) => {
        setDeleteShowConfirmModal(true);
        setSelectedUser(rowData);

    }

    const onActivateHandler = (event, rowData) => {
        setActivateShowConfirmModal(true);
        setSelectedUser(rowData);
    }


    const applyDelete = (data) => {
        showLoader();
        const id = data['id'];
        let deleteSubscription = deleteAlerts(id)
            .pipe(finalize(() => {
                hideLoader();
                deleteSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                getAlertsList('');
            });
    }

    const applyActivate = (data) => {
        console.log(data, "data")
        showLoader();
        const id = data['id'];
        let payload = { "is_active": !data.is_active }
        let activateSubscription = actiavteAlerts(id, payload)
            .pipe(finalize(() => {
                hideLoader();
                activateSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                getAlertsList('');
            });
    }

    let headerConfig = [
        // { colLabel: 'Id', colKey: 'id' },
        { colLabel: 'Name', colKey: 'name' },
        { colLabel: 'Hs Code', colKey: 'hs_code' },
        { colLabel: 'Alert Criteria', colKey: 'criteria' },
    ];

    let actionList = [
        { actionName: 'edit', faIcon: 'edit', callEvent: onEditHandler },
        { actionName: 'delete', faIcon: 'trash', callEvent: onDeleteHandler },
        { actionName: 'active', faIcon: 'activate', callEvent: onActivateHandler },
    ];



    return (
        <React.Fragment>

            <section className='users-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='title-buttons'>
                                <h2 >Alert Management</h2>
                                <div className='buttons ml-auto'>
                                    <button onClick={onAddHandler} type='button' className='btn btn-primary'>Add New Alert</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='form-inline mt-2 mb-2 alert-bal'>
                                <b className='theme-sub-statement'>Balance Alerts :</b>&nbsp;  {alertBal}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='search-group mt-2 mb-2'>
                                <Search onSearch={onSearchHandler} placeHolder={"Search..."} />
                            </div>
                        </div>

                    </div>

                    <DataTable rowData={alertList} headerGroup={headerConfig}
                        totalPage={pageCount} onPageChange={handlePageClick}
                        sortGroup={sortable}
                        sort={sort}
                        onSortingChange={onSortingChange}
                        actionGroup={actionList}>
                    </DataTable>

                </div>
            </section>


            {showAddEditModal && <Modal show={showAddEditModal} onClose={() => setShowAddEditModal(false)} modal_title={`${selectedAction} Alert`}>
                {<AddEditAlertPage action={selectedAction} closeModal={setShowAddEditModal} onHide={onHideModalHandler} initialData={{ 'data': (selectedUser ? selectedUser : {}) }} />}
            </Modal>}

            {showDeletConfirmModal && <Modal show={showDeletConfirmModal} onClose={() => setDeleteShowConfirmModal(false)} modal_title="Confirm">
                <ConfirmModal initialData={{
                    'data': selectedUser,
                    'action': 'delete'
                }} onClose={applyDelete}
                    hideModal={setDeleteShowConfirmModal} />
            </Modal>}

            {showActivateConfirmModal && <Modal show={showActivateConfirmModal} onClose={() => setActivateShowConfirmModal(false)} modal_title="Confirm">
                <ConfirmModal initialData={{
                    'data': selectedUser,
                    'action': 'activate'
                }} onClose={applyActivate}
                    hideModal={setActivateShowConfirmModal} />
            </Modal>}

        </React.Fragment>
    );
}
