import { createSlice } from '@reduxjs/toolkit';
import * as constantType from "../../utility/constants/common-constants";

const initialState = {
    isLoggedIn: !!localStorage.getItem(constantType.ACCESS_TOKEN),
    loggedInUserData: null,
    payloadAccessToken: localStorage.getItem(constantType.ACCESS_TOKEN),
    usersData: null,
    isMobileSideBarShow: false
}

const AuthSlice = createSlice({
    name: constantType.ACTION_LOGIN,
    initialState,
    reducers: {
        setLoginData: (state, action) => {
            state.isLoggedIn = true;
            state.loggedInUserData = action.payload;
            state.payloadAccessToken = action.payload.access;
        },
        clearLoginData: state => {
            state.isLoggedIn = false;
            state.loggedInUserData = null;
            state.payloadAccessToken = null;
        },
        setMobileSidebar: (state, action) => {
            state.isMobileSideBarShow = action.payload;
        }
    }
});

export const { clearLoginData, setLoginData, setMobileSidebar } = AuthSlice.actions;
export const loginSelector = state => state.login;
export default AuthSlice.reducer;
