import React, { useState, useEffect, useCallback } from 'react';
import "../../../static/css/pages/users/user.css";
import '../../../../lib/static/css/modal/modal.module.css';
import Modal from '../../Common/Modal/Modal';
import { AddEditLookupTypePage } from "./AddEditLookupType";
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { ConfirmModal } from "../../Common/ConfirmModal/ConfirmModal";
import * as constantsType from "../../../utility/constants/common-constants";
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import * as moduleConstants from "../../../utility/constants/module-action-constants";
import * as actionConstants from "../../../utility/constants/module-action-constants";

import { PermissionSelector } from "../../../redux/permission/permission-reducer";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../../factory/helpers/permission-service";
import { DataTable } from "../../Common/DataTable/DataTable";
import { listLookupType, deleteLookupType } from "../../../services/lookup-type-service";
import { finalize } from "rxjs";
import Search from "../../Common/Search/Search";
import "../../../../lib/static/css/global/global.css";
import { LOOKUP_TYPE_PAGE, MASTER_MANAGEMENT } from '../../../utility/constants/page-label-constants';
import { Breadcrumb } from '../../../components/Common/Breadcrumb/Breadcrumb';
 

export const LookupTypePage = () => {

    const { permission } = useSelector(PermissionSelector);
    const PER_PAGE = 10;
    const [showDeletConfirmModal, setDeleteShowConfirmModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [selectedAction, setAction] = useState('Add');
    const [lookupType, setLookupType] = useState([]);
    const [selectedLookup, setSelectedLookup] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [sortable, setSortable] = useState([]);
    const [sort, SetSort] = useState(new Map())

    useEffect(() => {
        getLookupTypeList('', currentPage);
    }, [currentPage]);

    const handlePageClick = (selectedObject) => {
        let selectedPage = selectedObject.selected + 1;
        setCurrentPage(selectedPage)

    }

    const getLookupTypeList = (params, selectedPage = 1) => {
        showLoader();

        let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE, _page: selectedPage, _page_size: PER_PAGE };
        let lookupTypeubscription = listLookupType({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                lookupTypeubscription = null;
            }))
            .subscribe(response => {

                setLookupType(response.data);
                setPageCount(response.count);
                setSortable(response.sortable);
            });
    }

    const onSearchHandler = useCallback((searchValue) => {
        let params = { _q: searchValue };
        getLookupTypeList(params);
    }, [])

    const onSortingChange = (params, key, sortBy) => {
        let sortPair = new Map()
        if (key && sortBy) {
            sortPair.set(key, sortBy)
            SetSort(sortPair);
        } else {
            SetSort(sortPair);
        }

        getLookupTypeList(params);
    }

    const isShow = (actionName) => {
        let child = moduleConstants.LOOKUP_TYPE;
        return hasPermission(permission, child, actionName);
    }


    const onHideModalHandler = () => {
        getLookupTypeList('');
    }

    const onAddHandler = (event, rowData) => {
        setShowAddEditModal(true);
        setAction('Add')
        setSelectedLookup(rowData);


    }

    const onEditHandler = (event, rowData) => {
        setShowAddEditModal(true);
        setAction('Edit')
        setSelectedLookup(rowData);
    }

    const onDeleteHandler = (event, rowData) => {
        setDeleteShowConfirmModal(true);
        setSelectedLookup(rowData);

    }

    const applyDelete = (data) => {
        showLoader();
        const id = data['id'];
        let deleteSubscription = deleteLookupType(id)
            .pipe(finalize(() => {
                hideLoader();
                deleteSubscription = null;
            }))
            .subscribe(response => {
                showSuccessToaster(response.message);
                getLookupTypeList('');
            });
    }

    let headerConfig = [
        { colLabel: 'Id', colKey: 'id' },
        { colLabel: 'Name', colKey: 'name' },
        { colLabel: 'key', colKey: 'key' },
    ];

    let actionList = [
        { actionName: 'edit', faIcon: 'edit', hasPermission: isShow, callEvent: onEditHandler },
        { actionName: 'delete', faIcon: 'trash', hasPermission: isShow, callEvent: onDeleteHandler },
    ];


    const BreadcrumbConfig = [
        { label: MASTER_MANAGEMENT, link: null, highlight: true },
        { label: LOOKUP_TYPE_PAGE, link: null },
    ]

    return (
        <React.Fragment>

            <Breadcrumb breadcrumbList={BreadcrumbConfig} />

            <section className='users-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='title-buttons'>
                                <h2 className='title'>Lookup Type</h2>
                                <div className='buttons ml-auto'>
                                    <button style={{ display: isShow(actionConstants.ADD) ? 'block' : 'none' }} onClick={onAddHandler} type='button' className='btn btn-primary'>Add Lookup Type</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-7'>
                            <div className='form-inline'>

                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='search-group mt-2 mb-2'>
                                <Search onSearch={onSearchHandler} placeHolder={"Search..."} />

                            </div>
                        </div>

                    </div>


                    <DataTable rowData={lookupType} headerGroup={headerConfig}
                        totalPage={pageCount} onPageChange={handlePageClick}
                        sortGroup={sortable}
                        sort={sort}
                        onSortingChange={onSortingChange}
                        actionGroup={actionList}>
                    </DataTable>


                </div>
            </section>


            {showAddEditModal && <Modal show={showAddEditModal} onClose={() => setShowAddEditModal(false)} modal_title={`${selectedAction} Lookup Type`}>
                {<AddEditLookupTypePage action={selectedAction} closeModal={setShowAddEditModal} editmode={true} onHide={onHideModalHandler} initialData={{ 'data': (selectedLookup ? selectedLookup : {}) }} />}
            </Modal>}

            {showDeletConfirmModal && <Modal show={showDeletConfirmModal} onClose={() => setDeleteShowConfirmModal(false)} modal_title="Confirm">
                <ConfirmModal initialData={{
                    'data': selectedLookup,
                    'action': 'delete'
                }} onClose={applyDelete}
                    hideModal={setDeleteShowConfirmModal} />
            </Modal>}

        </React.Fragment>
    );
}
