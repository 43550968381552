import axios from "axios";
import AppConfig from "./app-config";
import { errorInterceptor } from "../helpers/error-interceptor";
import { jwtInterceptor } from "../helpers/jwt-interceptor";
import { networkEncryptionInterceptor } from "../helpers/network-encryption-interceptor";


errorInterceptor();
jwtInterceptor();
networkEncryptionInterceptor();

const HTTP_CLIENT = {
    GET: (args) => {
        let URL = AppConfig.baseURL() + args.url;
        let headers = { ...args.headers };
        if (args.newUrl) {
            URL = args.newUrl;
        }
        return axios
            .get(URL, { headers, withCredentials: false, timeout: AppConfig.apiTimeoutTime, params: args.params });
    },
    POST: (args) => {
        let data = args.data || {};
        let URL = AppConfig.baseURL() + args.url;
        let headers = { ...args.headers };
        if (args.newUrl) {
            URL = args.newUrl;
        }
        return axios.post(URL, data, { headers, withCredentials: false, timeout: AppConfig.apiTimeoutTime });
    },

    PUT: (args) => {
        let data = args.data || {};
        let headers = { ...args.headers };
        let URL = AppConfig.baseURL() + args.url;
        if (args.newUrl) {
            URL = args.newUrl;
        }

        return axios.put(URL, data, { headers, withCredentials: false, timeout: AppConfig.apiTimeoutTime })
    },
    PATCH: (args) => {
        let data = args.data || {};
        let headers = { ...args.headers };
        let URL = AppConfig.baseURL() + args.url;
        if (args.newUrl) {
            URL = args.newUrl;
        }

        return axios.patch(URL, data, { headers, withCredentials: false, timeout: AppConfig.apiTimeoutTime })
    },
    DELETE: (args) => {
        let headers = { ...args.headers };
        let URL = AppConfig.baseURL() + args.url;
        if (args.newUrl) {
            URL = args.newUrl;
        }
        return axios.delete(URL, { headers, withCredentials: false, timeout: AppConfig.apiTimeoutTime })
    }

};
export default HTTP_CLIENT;
