import React from 'react'
import { Link } from 'react-router-dom';
import { showLoader, hideLoader } from '../../../services/core/loader-service';

import { showSuccessToaster } from "../../../utils/toaster/toaster";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordValidation } from '../../../utils/schemas';
import { forgotPassword } from '../../../services/auth-service';
import { useFormik } from 'formik';
import { finalize } from 'rxjs';
import { LOGIN } from '../../../utility/constants/page-route-constants';

const ForgotPassword = () => {

    let navigate = useNavigate();
    const initialValues = { email: '' };

    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: ForgotPasswordValidation,
        enableReinitialize: true,
        onSubmit: (value) => {
            sendEmail(value)
        }
    })

    const sendEmail = (params) => {
        let payload = { ...params };
        showLoader();
        let subscription = forgotPassword({ data: payload })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                navigate(LOGIN);
            });
    }

    return (
        <section className="login-wrapper" style={{ background: 'var(--primary-color)', minHeight: "100vh", padding: "0 15px"}}>
            <div className="login-form mw-500">
                <br />
                <h2 className='text-center theme-statement'>Forgot Your Password?</h2>
                <br />
                <div className='text-center'>Please Enter your Email address to Reset your Password.</div>
                <br />
                <form onSubmit={handleSubmit}>
                    <br></br>
                    <div className="input-container ">
                        <label htmlFor="email">Email</label>
                        <input type="email"
                            className="input-control shadow-input"
                            placeholder="Your Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange} />

                        {errors.email && <p className='error'>{errors.email}</p>}
                    </div>
                    <div className='input-container'>
                        <button type='submit' className="btn btn-primary btn-full">Submit</button>
                    </div>
                    <div className='input-container text-center'>
                        <Link to={LOGIN} className="textLink">Back to login</Link>
                    </div>
                </form>
            </div>
        </section>
    )
}
export default ForgotPassword;