import React from 'react';


export const TroubleTicketPage = () => {

  return (
    <React.Fragment>
      <br /><br />
      <div className='title-buttons'>
        <h2 className='subcriptionlabel' >&nbsp; &nbsp; Trouble Ticket</h2>
      </div>
      <div className="App">
        <iframe frameborder="0" style={{ height: '1000px', width: '63%', border: 'none' }} src='https://forms.zohopublic.in/futecglobal/form/RaiseaConcern1/formperma/V3YGbBAisbdKY55j52EKYbDWN3z1Xhlk1RmQRpsvfgw'></iframe>
      </div>
    </React.Fragment>
  );
}