

import React, { useState, useEffect, useCallback } from 'react';
import "../../static/css/pages/subscription.css";
import { showLoader, hideLoader } from "../../services/core/loader-service";
import { finalize } from "rxjs";
import "../../../lib/static/css/global/global.css";
import { checkoutOrder, ConfirmPayment, lastestSubscription, listSubscription, listSubscriptionPlan } from '../../services/subscription-service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import paymentImg from "../../static/icon/easebuzz-badge.png";
import { showErrorToaster, showSuccessToaster } from '../../utils/toaster/toaster';
import { getProfile } from '../../services/user-service';
import check from "../../static/icon/check.svg";
import cross from "../../static/icon/error.png"

export const SubscriptionPayment = () => {

    let initialData = {
        name: '',
        mobile: ''
    }
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [userData, setUserData] = useState(initialData);
    const [profileData, setProfileData] = useState([]);

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getSubscriptionList('');
        getProfileData();
    }, []);



    const initPaymentGateway = (acesssKey) => {
        var easebuzzCheckout = new window.EasebuzzCheckout(process.env.REACT_APP_PAYMENT_GATEWAY_KEY, process.env.REACT_APP_PAYMENT_GATEWAY_ENV);
        var options = {
            access_key: acesssKey, // access key received via Initiate Payment
            theme: "#123456", // color hex
            onResponse: function (response) {
                console.log("response", response)
                if(response.txnid){
                    ConfirmOrder(response);
                }
            }
        }
        easebuzzCheckout.initiatePayment(options)
    }

    const getSubscriptionList = (params) => {
        // showLoader();

        let filter = { ...params };
        let subscriptionListubscription = listSubscriptionPlan({ params: filter })
            .pipe(finalize(() => {
                // hideLoader();
                subscriptionListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let list = response.results;

                list = list.filter((obj) => {
                    return obj.id == id
                })

                if (list.length) {
                    list[0]['final_gst_price'] = list[0]['final_price'] + (list[0]['final_price'] * 18) / 100;
                }
                setSubscriptionList(list);
            });
    }

    const checkoutCall = () => {
        let payload = { subscription: id };
        let subscriptionListubscription = checkoutOrder({ data: payload })
            .pipe(finalize(() => {
                // hideLoader();
                subscriptionListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
               const plantAmt =  subscriptionList.length ? subscriptionList[0].final_price : 0;
                if(plantAmt == 0){
                    seoPaymentPage(false);
                    navigate('/transaction-details/' + response.data.txn_id);
                    return
                }
                initPaymentGateway(response.data.access_key)
            });
    }

    const ConfirmOrder = (payload) => {
        showLoader();
        let subscriptionListubscription = ConfirmPayment({
            data: {
                txn_id: payload.txnid
            }
        })
            .pipe(finalize(() => {
                hideLoader();
                subscriptionListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                if (response.data) {
                    if (response.data.status == "Success") {
                        if (response.message) {
                            showSuccessToaster(response.message);
                        }

                        seoPaymentPage(true)

                        //
                        navigate('/transaction-details/' + response.data.txn_id);
                    } else {
                        if (response.message) {
                            showErrorToaster(response.message);
                        }
                        navigate('/transaction-details/' + response.data.txn_id);
                    }
                }
            });
    }

    const seoPaymentPage = (isPaid) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'purchaseComplete',
            'pricing_plan': subscriptionList?.length ? subscriptionList[0]?.name : 'N/A',
            'formType': 'PaidPurchase',
            'formPosition': 'Footer',
            'isPaidPlan': isPaid
        });
    }



    // function loadScript(src) {
    //     return new Promise((resolve) => {
    //         const script = document.createElement("script");
    //         script.src = src;
    //         script.onload = () => {
    //             resolve(true);
    //         };
    //         script.onerror = () => {
    //             resolve(false);
    //         };
    //         document.body.appendChild(script);
    //     });
    // }



    // async function displayRazorpay(e) {
    //     e.preventDefault();

    //     const res = await loadScript(
    //         "https://checkout.razorpay.com/v1/checkout.js"
    //     );



    //     const options = {
    //         "key": "rzp_test_hIwRTyKjaN1bDh",
    //         "currency": "INR",
    //         "name": "Cusbuzz",
    //         "image": paymentImg,
    //         "order_id": orderId,
    //         "prefill": {
    //             "name": userData.name,
    //             "contact": userData.mobile
    //         },
    //         handler: async function (response) {
    //             // console.log(response,"response paymnet")
    //             // const data = {
    //             //     orderCreationId: orderId,
    //             //     razorpayPaymentId: response.razorpay_payment_id,
    //             //     razorpayOrderId: response.razorpay_order_id,
    //             //     razorpaySignature: response.razorpay_signature,
    //             // };

    //             createOrder(response);


    //         },
    //         prefill: {
    //             name: userData.name,
    //             contact: userData.mobile,
    //         },

    //         theme: {
    //             color: "#61dafb",
    //         },
    //     };

    //     const paymentObject = new window.Razorpay(options);
    //     paymentObject.open();
    // }

    const handleChange = (e, key) => {
        setUserData({
            ...userData,
            [key]: [e.target.value]
        })

    }

    const getProfileData = () => {

        showLoader();
        let subscription = getProfile({})
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                setProfileData(response['data']);
                let userData = response['data'];
                let name = userData.first_name + ' ' + userData.last_name;
                setUserData({
                    ...userData,
                    name: name,
                    mobile: userData.mobile_number
                })
            });
    }


    return (
        <React.Fragment>

            <br />
            <div className="primary-panel">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            {/* <h2 className="title-payment">Checkout</h2> */}
                            <div className='title-buttons'>
                                <h2 className='subcriptionlabel' >Checkout</h2>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Link to="/subscription-plan">
                                <button
                                    title="Back"
                                    type="button"
                                    className="btn btn-outline-primary back-btn back"

                                >
                                    Back
                                </button>
                            </Link>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-3" style={{ "marginTop": "47px" }}>
                            <h5 className="side-headings s-head">Customer Name<br /></h5>
                            <div className='s-head'>{profileData?.first_name} {profileData?.last_name}</div><br />
                            <h5 className="side-headings s-head">Mobile<br /></h5>
                            <div className='s-head'>{profileData?.mobile_number}</div><br />
                            <h5 className="side-headings s-head">Email<br /></h5>
                            <div className='s-head'>{profileData?.email}</div>
                        </div>
                        <div className="col-lg-3" style={{ "marginTop": "47px" }}>

                            <h5 className="side-headings" >Subscription Name<br /></h5><br />
                            <b style={{ marginLeft: '43%' }}>{subscriptionList?.length ? subscriptionList[0]?.name : 0}</b>
                            <ul className='selected-sub'>
                                <li>
                                    <span className='mr1-10'>
                                        {(subscriptionList?.length && subscriptionList[0]?.no_of_users) ? <img

                                            src={check}
                                            width="15"
                                            alt=""
                                        /> :
                                            <img
                                                src={cross}
                                                width="12"
                                                alt=""
                                            />
                                        }
                                    </span> <small>{subscriptionList?.length ? subscriptionList[0]?.no_of_users : 0} Sub Users</small>
                                </li>
                                <li>
                                    <span className='mr1-10'>
                                        {(subscriptionList?.length && subscriptionList[0]?.validity) ? <img
                                            src={check}
                                            width="15"
                                            alt=""
                                        /> :
                                            <img
                                                src={cross}
                                                width="12"
                                                alt=""
                                            />
                                        } </span><small>{subscriptionList?.length ? subscriptionList[0]?.validity : 0} days Validity</small>
                                </li>

                                <li>
                                    <span className='mr1-10'>
                                        {(subscriptionList?.length && subscriptionList[0]?.queries_limit) ? <img
                                            src={check}
                                            width="15"
                                            alt=""
                                        /> :
                                            <img
                                                src={cross}
                                                width="12"
                                                alt=""
                                            />
                                        } </span><small>{subscriptionList?.length ? subscriptionList[0]?.queries_limit : 0} Searches</small>
                                </li>

                                {subscriptionList?.length &&
                                    (subscriptionList[0].additional_features.length ? true : false) && subscriptionList[0].additional_features.map((ft, index) => {
                                        return <>
                                            <li  >
                                                <small>
                                                    <span className='mr1-10'>
                                                        {ft.is_included ?
                                                            <img
                                                                src={check}
                                                                width="15"
                                                                alt=""
                                                            /> :

                                                            <img
                                                                src={cross}
                                                                width="15"
                                                                alt=""
                                                            />
                                                        }
                                                    </span>{" "}
                                                    {ft.feature_name} &nbsp;
                                                    {ft.help_text && (
                                                        <i
                                                            className="fa fa-question-circle"
                                                            style={{ color: "black" }}
                                                            title={ft.help_text}
                                                            aria-hidden="true"
                                                        ></i>
                                                    )}{" "}
                                                </small>
                                            </li>
                                        </>
                                    })
                                }
                            </ul>
                        </div>

                        <div className="payment-section col-lg-6">
                            <form className="checkout" style={{ "border": "none !important" }}>
                                <div className="flex-row justify-content-between" >
                                    <div className="column-left" style={{ "backgroundColor": "#f8f8f8" }}>
                                        <div className="card-panel">
                                            <br />
                                            <h3 style={{
                                                "color": "gray", "textTransform": "capitalize !important",
                                                "marginLeft": "20px"
                                            }}>
                                                Pay - <span style={{ color: "gray" }}>₹ {subscriptionList?.length ? subscriptionList[0]?.final_gst_price : 0}<span className='gst-sub'>&nbsp;&nbsp;({' Inclusive of 18% GST '})</span></span>
                                            </h3>
                                            <ul style={{ 'listStyle': 'none' }}>
                                                <li>
                                                    <h6 style={{ "marginBottom": "-13px" }}>Name On Card<sup style={{ color: "red" }}>*</sup></h6><br />
                                                    <label className="text">
                                                        <input
                                                            type="text"
                                                            placeholder="Name On Card"
                                                            className="input-control"
                                                            value={userData.name}
                                                            name='name'
                                                            onChange={(e) => handleChange(e, 'name')}

                                                        />
                                                    </label>

                                                    <h6 style={{ "marginBottom": "-13px" }}>Mobile<sup style={{ color: "red" }}>*</sup></h6>
                                                    <br />
                                                    <label className="text">
                                                        <input
                                                            type="text"
                                                            placeholder="Mobile"
                                                            className="input-control"
                                                            value={userData.mobile}
                                                            name='mobile'
                                                            onChange={(e) => handleChange(e, 'mobile')}

                                                        />
                                                    </label>


                                                    {
                                                        (userData.name && userData.mobile) ?
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btn-lg btn-block btn-theme"
                                                                    onClick={() => checkoutCall()}
                                                                >
                                                                    Pay Now
                                                                </button>
                                                            </>

                                                            :
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btn-lg btn-block"
                                                                    disabled
                                                                >
                                                                    Pay Now
                                                                </button>
                                                            </>
                                                    }


                                                    <br /><br />
                                                    <img style={{ width: "100%" }} src={paymentImg} />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment >
    );
}
