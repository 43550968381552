import { useEffect, useState } from "react";
import { finalize } from "rxjs";
import { hideLoader, showLoader } from "../../services/core/loader-service";
import { bannerData } from "../../services/user-service";
import setting from "../../static/img/setting.png";
import { Button } from "@mui/material"
import logo from "../../static/icon/logo.svg";

export const MaintaincePage = (props) => {
    let initialBannerData = {
        message: ""
    }

    const [bannerObj, setBannerData] = useState(initialBannerData);

    useEffect(() => {
        getBannerData();
    }, [])

    const getBannerData = (params, selectedPage = 1) => {
        showLoader();

        let filter = { ...params};
        let subscription = bannerData({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let data = response.results;
                let bData = data.find((obj) => {
                    return obj.banner_view !== "APPLICATION";
                });
                setBannerData(bData);


            });
    }
    return <>
       
       {/* {
            <>

                <header style={{ height: '50px' }}>
                    <div className="navbrand">

                        <h3 className={'head'}>
                            <img className='logo-head' src={logo} />
                        </h3>
                    </div>
                     
                </header>
            </>} */}
            <div className="maintain-screen">
            <img src={bannerObj?.image} style={{width:'100%'}}/>
            </div>
        {/* <br /><br /><br /> */}

        
        {/* <blockquote className="maintaince-msg">{bannerObj?.message}</blockquote> */}
        {/* <br/> */}
        {/* <img src={setting} class="maintaince-img"/> */}
    </>

}
