import React, { useEffect, useState } from 'react';
import { userFormValidation } from '../../../utils/schemas/index';
import { useFormik } from 'formik';
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import { finalize } from "rxjs";
import { listRole } from '../../../services/role-service';
import { addUser, editUser } from '../../../services/user-service';

export const AddEditUserPage = (props) => {

    let initValue = {
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
    };




    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: props.action == 'Edit' ? props.initialData.data : initValue,
        validationSchema: userFormValidation,
        enableReinitialize: true,
        onSubmit: (value) => {
            console.log(value,"values")
            // value.mobile_number = "+91-" + value.mobile_number;

            if (props.action == 'Add') {
                create(value)
            } else {
                update(value)
            }
        }
    })


    const create = (params) => {
        let payload = { ...params };
        showLoader();
        let addSubscription = addUser({ data: payload })
            .pipe(finalize(() => {
                hideLoader();
                addSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                closeAndUpdateModel();
            });
    }


    const closeAndUpdateModel = () => {
        props.closeModal(false);
        props.onHide();
    }


    const update = (params) => {
        let payload = { ...params };
        showLoader();
        let id = props.initialData.data.id;
        let updateSubscription = editUser({ data: payload }, id)
            .pipe(finalize(() => {
                hideLoader();
                updateSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                closeAndUpdateModel();
            });
    }


    return (

        <form onSubmit={handleSubmit}>
            <div className="input-container">
                <label>First Name <sup>*</sup></label>
                <input type="text" className="input-control" placeholder="Enter First Name" name="first_name" value={values.first_name} onChange={handleChange} />
                {errors.first_name && <p className='error'>{errors.first_name}</p>}
            </div>
            <div className="input-container">
                <label>Last Name  </label>
                <input type="text" className="input-control" placeholder="Enter Last Name" name="last_name" value={values.last_name} onChange={handleChange} />
                {errors.last_name && <p className='error'>{errors.last_name}</p>}
            </div>

            <div className="input-container">
                <label>Email <sup>*</sup></label>
                <input type="text" className="input-control" placeholder="Enter Email" name="email" value={values.email} autoComplete="false" onChange={handleChange} />
                {errors.email && <p className='error'>{errors.email}</p>}
            </div>

            <div className="input-container">
                <label>Mobile <sup>*</sup></label>
                <input type="text" className="input-control" placeholder="+9188xxxxxxxx" name="mobile_number" value={values.mobile_number} autoComplete="false" onChange={handleChange} />
                {errors.mobile_number && <p className='error'>{errors.mobile_number}</p>}
            </div>



            <div className='input-container'>
                <button type="submit" className="btn btn-primary" >Submit</button>&nbsp;
            </div>
        </form>
    );
}