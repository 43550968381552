

import React, { useState, useEffect, useCallback } from 'react';
import "../../static/css/pages/users/user.css";
import '../../../lib/static/css/modal/modal.module.css';
import Modal from '../Common/Modal/Modal';
import { showLoader, hideLoader } from "../../services/core/loader-service";
import { ConfirmModal } from "../Common/ConfirmModal/ConfirmModal";
import * as constantsType from "../../utility/constants/common-constants";
import { showSuccessToaster } from "../../utils/toaster/toaster";
import { DataTable } from "../Common/DataTable/DataTable";
import { finalize } from "rxjs";
import Search from "../Common/Search/Search";
import "../../../lib/static/css/global/global.css";
import { getUsagHistoryList } from '../../services/duty-calc-service';

export const UsageHistory = () => {

    const [historyList, setHistoryList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [sortable, setSortable] = useState([]);
    const [sort, SetSort] = useState(new Map())
    const PER_PAGE = 10;

    useEffect(() => {
        getHistoryList('', currentPage);
    }, [currentPage]);

    const handlePageClick = (selectedObject) => {
        let selectedPage = selectedObject.selected + 1;
        setCurrentPage(selectedPage)
    }

    const getHistoryList = (params, selectedPage = 1) => {
        showLoader();

        let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE, page: selectedPage, _page_size: PER_PAGE };
        let subscriptionListubscription = getUsagHistoryList({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscriptionListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                setHistoryList(response.results);
                setPageCount(response.count);
                setSortable(['first_name', 'last_name', 'email', 'mobile_number']);
            });
    }

    const onSearchHandler = useCallback((searchValue) => {
        let params = { _q: searchValue };
        getHistoryList(params);
    }, []);




    const onSortingChange = (params, key, sortBy) => {
        let sortPair = new Map()
        if (key && sortBy) {
            sortPair.set(key, sortBy)
            SetSort(sortPair);
        } else {
            SetSort(sortPair);
        }

        getHistoryList(params);
    }



    let headerConfig = [
        { colLabel: 'Date', colKey: 'search_date' },
        { colLabel: 'User Name', colKey: 'user_name' },
        { colLabel: 'HS Code', colKey: 'hs_code' },
        { colLabel: 'Country', colKey: 'country' },
        { colLabel: 'PTA Name', colKey: 'pta_name' },
        { colLabel: 'Tariff', colKey: 'pta_tariff_text' },
    ];


    return (
        <React.Fragment>
                      

            <section className='users-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-8'>
                            <div className='title-buttons'>
                                <h2 >&nbsp; Usage History</h2>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='search-group  '>
                                <Search onSearch={onSearchHandler} placeHolder={"Search..."} />
                            </div>
                        </div>

                    </div>

                    <DataTable rowData={historyList} headerGroup={headerConfig}
                        totalPage={pageCount} onPageChange={handlePageClick}
                        sortGroup={sortable}
                        sort={sort}
                        onSortingChange={onSortingChange}
                    >
                    </DataTable>

                </div>
            </section>







        </React.Fragment >
    );
}
