import React from 'react'
import PropTypes from 'prop-types';

export const ErrorDetails = (props) => {
    return (
        <div>
            <details>
                {props.error && props.error?.toString()}
                <br />
                {props.errorInfo?.componentStack}
            </details>
        </div>

    )
}

ErrorDetails.propTypes = {
    error: PropTypes.object.isRequired,
    errorInfo: PropTypes.object.isRequired
};