
const AppConfig = {
    baseURL: () => {
        return process.env.REACT_APP_BASE_URL;
    },
    apiTimeoutTime: 120000,
    storeId: 1,
    APIS: {
        userList: "/subscriber/sub-user",
        adminuser: "/admin-users",
        login: "/subscriber/login",
        register: "/subscriber/register",
        myProfile: "/user/my-profile",
        changePassword: "/user/change-password",
        submitContact: "/contact-us",
        submitFeedback: "/feedback",
        lookup: '/lookup-management/lookups',
        lookup_types: '/lookup-management/lookup-types',
        role: '/roles',
        module_action: '/permission/module-actions',
        all_permissions: '/permission/all',
        profile: '/subscriber/profile',
        user_permissions: '/user/permission',
        forgot_password: '/subscriber/forget_password',
        change_password: '/subscriber/change_password',
        reset_password: '/subscriber/reset_password',
        hsCode: '/hs-codes',
        productLocator: "/product-locator",
        countries: '/countries',
        productSearch: '/import-data',
        PTA: '/pta/tariff',
        partnerCode: '/pta/partner-code',
        notification: "/notification/",
        mark_read: "/notification/mark-all-as-read",
        alerts: "/subscriber/alert",
        subscription: "/subscriber/subscription-history",
        subscription_plans: "/subscription/",
        can_plan_avail: "/can-avail-freeplan",
        trnx_details: "/get-payment-details",
        checkout: "/checkout",
        confirmPayment: "/confirm-payment",
        latset_subscription: "/subscriber/latest-subscription",
        fla_details: "/pta/fta-detail",
        roo_details: "/pta/roo-details",
        rates: "/custom-duty/rates",
        values: "/custom-duty/value",
        total: "/custom-duty/total",
        importPolicy: "/import-policy",
        traderemedy: "/pta/trade-remedy",
        ntm: "/pta/ntm-measures",
        gst_rates: "/gst-rates",
        duty_cess: "/duty-cess",
        banner: "/banner",
        insight: "/custom-duty/insights",
        incorrectReport: "/custom-duty/report-incorrect",
        usesHistory: "/subscriber/search-history"
    }
};

export default AppConfig;
