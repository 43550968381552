import React, { useEffect, useState } from 'react';
import { RoleFormValidation } from '../../../utils/schemas/index';
import "../../../../lib/static/css/global/global.css";
import { useFormik } from 'formik';
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import Accordion from "../../Common/Accordian/Accordion";
import { useNavigate } from "react-router-dom";
import * as pageConstant from "../../../utility/constants/page-route-constants";
import { useParams } from 'react-router-dom';
import { addRole, editRole, getModuleActionList, getPermissions, getRoleDetails } from "../../../services/role-service";
import { finalize, forkJoin } from "rxjs";
import { Breadcrumb } from '../../../components/Common/Breadcrumb/Breadcrumb';
import { USER_MANAGEMENT } from '../../../utility/constants/page-label-constants';
import { ROLE_PAGE } from '../../../utility/constants/page-label-constants';
import { Link } from 'react-router-dom';

export const AddEditRolePage = () => {

    let initValue = {
        name: '',
        key: '',
        default_module_action_id: '',
        module_actions: []
    };

    const { id } = useParams();
    const [moduleActions, setModuleActions] = useState([]);
    const [permissionsList, setPermissions] = useState([]);
    const [roleData, setRoleData] = useState(initValue);
    const [moduleErr, SetModuleErr] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            getRoleData()
        }
        getModulePermissions();
    }, []);

    const getModulePermissions = () => {
        let permissionsfilters = { app_source_id: 1 };
        let moduleActionsfilters = { status: true, is_routable: true, _sort: 'label' };

        let permissionObs = getPermissions({ params: permissionsfilters });
        let moduleActionsObs = getModuleActionList({ params: moduleActionsfilters });

        forkJoin([permissionObs, moduleActionsObs]).subscribe(
            (response) => {
                const [permissionData, moduleActionData] = response;
                let permissions = permissionData.data;
                setModuleActions(moduleActionData.data);
                permissions = mapChildModule(permissions);
                setPermissions(permissions);
                if (!id) {
                    setRoleData(initValue);
                }

            })
    }

    const getRoleData = () => {
        showLoader();
        let filter = { _page: 1, _page_size: 10000 };

        let subscription = getRoleDetails({ params: filter }, id)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let roleValue = response.data;
                setRoleData(roleValue);
            });
    }

    const mapChildModule = (permission) => {
        let parent = [];
        let child = []

        permission.forEach((perm) => {
            if (perm.parent_id == null) {
                parent.push(perm)
            } else {
                child.push(perm)
            }
        })

        parent.forEach((obj) => {
            obj['child_module'] = [];
            child.forEach((list) => {
                if (obj.id == list.parent_id) {

                    obj.child_module.push(list)
                }
            })
        })

        return parent;
    }



    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: roleData,
        validationSchema: RoleFormValidation,
        enableReinitialize: true,
        onSubmit: (value) => {
            value['app_source_id'] = 1;
            value['module_actions'] = roleData.module_actions;


            if (!roleData.module_actions.length) {
                SetModuleErr('module selection is required');
                return
            } else {
                SetModuleErr('');
            }

            if (!id) {
                create(value)
            } else {
                update(value)
            }
        }
    })



    const create = (params) => {
        let payload = { ...params };
        showLoader();
        let addSubscription = addRole({ data: payload })
            .pipe(finalize(() => {
                hideLoader();
                addSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                closeAndUpdateModel();
            });
    }

    const closeAndUpdateModel = () => {
        navigate(pageConstant.ROLE_PAGE);
    }

    const update = (params) => {
        let payload = { ...params };
        showLoader();
        let updateSubscription = editRole({ data: payload }, id)
            .pipe(finalize(() => {
                hideLoader();
                updateSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                closeAndUpdateModel();
            });
    }


    const updateModuleActions = (e, data) => {
        let selectedAction = roleData.module_actions;

        if (e.target.checked) {
            selectedAction.push(data.id)

        } else {
            delete selectedAction[selectedAction.indexOf(data.id)]
            selectedAction = selectedAction.filter((id) => id);
        }


        let modifiedRoleData = {
            name: values.name,
            key: values.key,
            default_module_action_id: values.default_module_action_id,
            module_actions: selectedAction
        }

        setRoleData(modifiedRoleData)


        if (!selectedAction.length) {
            SetModuleErr('module selection is required');
            return
        } else {
            SetModuleErr('');
        }

    }

    const isActionChecked = (id) => {
        if (Object.entries(roleData).length) {
            return roleData.module_actions.includes(id);
        }
    }

    const BreadcrumbConfig = [
        { label: USER_MANAGEMENT, link: pageConstant.USER_PAGE, },
        { label: ROLE_PAGE, link: pageConstant.ROLE_PAGE, },
        { label: ROLE_PAGE, link: null }
    ];

    return (
        <React.Fragment>
            <Breadcrumb breadcrumbList={BreadcrumbConfig} />

            <section className='users-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='title-buttons'>
                                <h2 className='title' >{id ? 'Update' : 'Add'} Role</h2>
                                <div className='buttons ml-auto'>
                                    <Link to={pageConstant.ROLE_PAGE} ><button type='button' className='btn btn-primary' >Back</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="input-container">
                            <label>Name <sup>*</sup></label>
                            <input type="text" className="input-control" placeholder="Enter Name" name="name" value={values.name} onChange={handleChange} />
                            {errors && <p className='error'>{errors.name}</p>}
                        </div>
                        <div className="input-container">
                            <label>Key <sup>*</sup></label>
                            <input type="text" className="input-control" placeholder="Enter Key" name="key" value={values.key} onChange={handleChange} />
                            {errors && <p className='error'>{errors.key}</p>}
                        </div>
                        <div className="input-container">
                            <label>Default Action <sup>*</sup></label>
                            <select name="default_module_action_id" className='input-control' value={values.default_module_action_id} onChange={handleChange}>
                                <option value="">Select Landing Screen</option>
                                {moduleActions && moduleActions.map((item, index) => {
                                    return (<option key={index} value={item.id}>{item.label}</option>)
                                })}
                            </select>
                            {errors && <p className='error'>{errors.default_module_action_id}</p>}
                        </div>

                        <div className="input-container">
                            <label>Select Module Action <sup>*</sup></label>
                            <Accordion allowMultipleOpen >
                                {permissionsList.map((item, i) => (
                                    <div label={item.label} key={item.id} isOpen>
                                        <Accordion >
                                            {item.child_module.map((childitem, c) => (
                                                <div label={childitem.label} key={childitem.id}>
                                                    <div key={c}>
                                                        {Object.keys(childitem.actions).map((key, i) => (
                                                            <div className="checkbox checkbox-circle checkbox-color-scheme" key={i}>
                                                                <label className="checkbox-checked">

                                                                    <input type="checkbox" checked={isActionChecked(childitem.actions[key]['id'])} onChange={(e) => updateModuleActions(e, childitem.actions[key])} /> <span className="label-text">{key}
                                                                    </span>

                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </Accordion>
                                    </div>
                                ))}
                            </Accordion>
                            {moduleErr && <p style={{ "color": "red" }}>{moduleErr}</p>}
                        </div>


                        <br></br>
                        <div className='input-container'>
                            <button type="submit" className="btn btn-primary" >Submit</button>&nbsp;
                        </div>
                    </form>
                </div>
            </section>

        </React.Fragment>
    );
}