import React, { useState } from 'react';
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { loginSelector } from "../../../redux/auth/auth-reducer";
import * as constantType from "../../../utility/constants/common-constants";
import { changePassword } from '../../../services/auth-service';
import { useFormik } from 'formik';
import { ChangePasswordValidation } from '../../../utils/schemas';
import { finalize } from 'rxjs';
import { NOT_MATCH_PASSWORD } from '../../../utility/constants/error-constants';
import { PROFILE } from '../../../utility/constants/page-route-constants';

export const ChangePassword = () => {

    const initialValues = { old_password: '', new_password: '', confirm_password: '' };
    const { payloadAccessToken } = useSelector(loginSelector);
    const navigate = useNavigate();
    const [passwordErr, setPasswordErr] = useState('');

    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: ChangePasswordValidation,
        enableReinitialize: true,
        onSubmit: (value) => {
            value[constantType.RESET_TOKEN] = payloadAccessToken;
            if (values.new_password !== values.confirm_password) {
                setPasswordErr(NOT_MATCH_PASSWORD);
                return
            }
            sendEmail(value)
        }
    })

    const sendEmail = (params) => {
        let payload = { ...params };
        showLoader();
        let subscription = changePassword({ data: payload })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                navigate(PROFILE);
            });
    }

    const matchPassword = (e) => {
        if (values.new_password !== values.confirm_password) {
            setPasswordErr(NOT_MATCH_PASSWORD);
            return false;
        } else {
            setPasswordErr("");
            return true;
        }
    }

    return (
        <section className="login-wrapper">
            <div className="login-form">
            <div className='row'>
                        <div className='col-lg-12'>
                            <div className='title-buttons'>
                                <h2 >Change Password</h2>
                                 
                            </div>
                        </div>
                    </div>
                <form onSubmit={handleSubmit}>

                    <div className="input-container">
                        <label htmlFor="new_password">Old Password </label>
                        <input type="password"
                            className="input-control"
                            placeholder="Enter Old Password"
                            name="old_password"
                            value={values.old_password}
                            onChange={handleChange}
                        />

                        {errors.old_password && <p className='error'>{errors.old_password}</p>}
                    </div>
                    <div className="input-container">
                        <label htmlFor="new_password">New Password </label>
                        <input type="password"
                            className="input-control"
                            placeholder="Enter New Password"
                            name="new_password"
                            value={values.new_password}
                            onChange={handleChange}
                        />

                        {errors.new_password && <p className='error'>{errors.new_password}</p>}
                    </div>

                    <div className="input-container">
                        <label htmlFor="confirm_password">Confirm Password </label>
                        <input type="password"
                            className="input-control"
                            placeholder="Enter Confirm Password"
                            name="confirm_password"
                            value={values.confirm_password}
                            onChange={handleChange}
                            onBlur={matchPassword} />

                        {errors.confirm_password && <p className='error'>{errors.confirm_password}</p>}
                        {passwordErr && <p className='error'>{passwordErr}</p>}
                    </div>
                    <div className='input-container'>
                        <button type="submit" className="btn btn-primary" >Submit</button>
                    </div>
                </form>
            </div>
        </section>
    );
}