import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Step1 } from './Steps/Step1';
import { Step2 } from './Steps/Step2';
import { Step3 } from './Steps/Step3';
import { Step4 } from './Steps/Step4';
import { Step5 } from './Steps/Step5';
import { StepLabel } from '@mui/material';
import { showWarnToaster } from "../../utils/toaster/toaster";
import { bannerData } from '../../services/user-service';
import { finalize, of, switchMap, tap } from "rxjs";
import { useNavigate } from 'react-router';
import { MAINTAINENCE_PAGE } from '../../utility/constants/page-route-constants';
import { logout } from '../../services/auth-service';
import '../../../../src/App.css'
import Tour from 'reactour';
import logo from "../../static/icon/logo.svg";
import { useState } from 'react';
// import { TourProvider } from '@reactour/tour'

import { useTour } from '@reactour/tour'
import { useEffect } from 'react';
import { hideLoader, showLoader } from '../../services/core/loader-service';
import { store } from '../../redux/store';
import { setMobileSidebar } from '../../redux/auth/auth-reducer';

const steps = [Step1, Step2, Step5];

export const DutyCalculatorPage = () => {


    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});



    const getTourAccess = () => {
        let value = (localStorage.getItem("tourShow") == 'yes') ? true : false;
        // let profile = (data) ? JSON.parse(data) : null;
        // console.log(profile?.show_demo, "demo")
        return value
    }


    const [isShow, SetShow] = React.useState(getTourAccess());


    React.useEffect(() => {
        console.log(isShow, "isShow")
        getBannerData()

    }, [])

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const GSTExists = () => {
        let data = localStorage.getItem("gstfield");
        data = (data) ? JSON.parse(data) : false;
        data = data ? true : false;
        return data;
    }

    const GSTValue = () => {
        let data = localStorage.getItem("selectedGST");
        return (data) ? JSON.parse(data) : null;
    }

    const GSTField = () => {
        let data = localStorage.getItem("gstfield");
        return (data) ? JSON.parse(data) : null;
    }

    const getHscode = () => {
        let data = localStorage.getItem("selectedHsCode");
        return (data) ? data : false;
    }

    const hyperLinkValue = () => {
        const CustomCessArr = JSON.parse(localStorage.getItem('CustomCess'));
        let finalArr = [];
        CustomCessArr.forEach(element => {
            let val;
            if(element.key.toLocaleLowerCase() == 'gst'){
               val = localStorage.getItem('selectedGST')
            }else{
               val = localStorage.getItem(element.key+'-custom-cess')
            }

            if(!val){
                finalArr.push(element.name);
            }
        });
        return finalArr
    }


    

// total
// has access
// [1,2,3]
// [1]

// // 2,3

const handleNext = () => {

    if (activeStep + 1 == 1 && !getHscode()) {
        showWarnToaster("Please Select HS Code")
        return
    }



    // if (GSTExists()) {
    //     if (activeStep + 1 == 2 && !GSTValue()) {

    //         let msg = "Please Select GST";
    //         if (GSTField()) {
    //             msg = `Please Select ${GSTField().column_name}`
    //         }
    //         showWarnToaster(msg)
    //         return
    //     }
    // }

    if (activeStep + 1 == 2) {
        if (hyperLinkValue().length) {
            showWarnToaster(`Please Select ${hyperLinkValue()[0]}`);
            return
        }
    }



    // obj == 

    const newActiveStep =
        isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
    setActiveStep(newActiveStep);
};



const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
};

const handleStep = (step) => () => {
    setActiveStep(step);
};

const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
};

const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
};

const getBannerData = (params, selectedPage = 1) => {

    let filter = { ...params, };
    let subscription = bannerData({ params: filter })
        .pipe(finalize(() => {
            subscription = null;
        }))
        .subscribe(response => {
            if (!response) return;
            let data = response.results;
            let bData = data.find((obj) => {
                return obj.banner_view !== "APPLICATION";
            });

            if (bData?.message) {
                logout();
            }
        });
}



const tourConfig = [
    {
        selector: '[data-tut="reactour__goTo1"]',
        content: 'Welcome to Cusbuzz – Your go-to app for latest on customs duties.',

    },
    {
        selector: '[data-tut="reactour__goTo2"]',
        content: 'On Duty Calculator tab, you can select your HS code or use HS code finder ( using your product name) to see the latest custom duty breakdown.',
    },
    {
        selector: '[data-tut="reactour__goTo3"]',
        content: 'You can also select country of Origin and Trade agreement to see preferential duty rates, Trade remedies and Non-tariff Measures.',
    },
    {
        selector: '[data-tut="reactour__goTo4"]',
        content: 'Here you can see your custom duty breakdown, Total duty, Preferential duty, Import policy, Chapter notes, Trade remedies and Non-tariff measures.',
    },
    {
        selector: '[data-tut="reactour__goTo5"]',
        content: 'On this screen , you can enter your CIF Value to calculate your exact Custom duty.',
    },
    {
        selector: '[data-tut="reactour__goTo6"]',
        content: 'On this module, you can set multiple alerts on your HS codes to receive notifications on changes in Duty Rate and introduction of new HS codes.',
    },
    {
        selector: '[data-tut="reactour__goTo7"]',
        content: 'Go to this module to see insights on Top Indian Ports, Top Foreign Countries, Top HS codes for your products and HS codes. You can also see top General Exemption Notifications based on your HS code & Country of Origin to reduce your custom duty',
    },
    {
        selector: '[data-tut="reactour__goTo8"]',
        content: 'Here you can see all your notifications at one place.',
    },
    {
        selector: '[data-tut="reactour__goTo9"]',
        content: 'Use this module to see your subscription details & its features. You can also see your subscription history here.',
    },
    {
        selector: '[data-tut="reactour__goTo17"]',
        content: ({ goTo, inDOM }) => (
            <div>
                <h1 className='center-align'>Thank You! </h1><br></br>
                <div style={{ background: 'var(--primary-color)' }}>
                    <img className='tour-logo' src={logo} />

                </div>
            </div>
        ),
    },
];

const closeTour = () => {
    // debugger
    // let data = localStorage.getItem("tourShow");
    // let profile = (data) ? JSON.parse(data) : null;
    // let obj = {
    //     ...profile,
    //     show_demo: false
    // };
    localStorage.setItem("tourShow", 'no');
    SetShow(false)
}

const getComponent = (step) => {
    switch (step) {
        case 1:
            return <Step1 />
        case 2:
            return <Step2 />
        // case 3:
        //     return <Step3 />
        case 3:
            return <Step5 />


    }
}

const getScreenTourClass = (index) => {
    if (index == 1) {
        return "reactour__goTo4"
    }

    if (index == 2) {
        return "reactour__goTo5"
    }

}


const radius = 10;

return (

    <React.Fragment>
        {isShow ?
            <>

                <Tour
                    steps={tourConfig}
                    isOpen={isShow}
                    onRequestClose={() => closeTour()}
                    onClickClose={closeTour}
                    disableDotsNavigation={true}
                    closeWithMask={false}
                    onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
                        return
                        // if (steps) {
                        //   if (currentStep === steps.length - 1) {
                        //     setIsOpen(false)
                        //   }
                        //   setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1))
                        // }
                    }}
                    getCurrentStep={(curr) => {
                        console.log(`The current step is ${curr + 1}`);
                        let currentStep = curr + 1;
                        if (currentStep == 5 || currentStep == 6 || currentStep == 7 || currentStep == 8 || currentStep == 9) {
                            // alert(currentStep)
                            store.dispatch(setMobileSidebar(true));
                        } else { store.dispatch(setMobileSidebar(false)); }
                    }}

                    maskClassName="mask"
                    disableKeyboardNavigation={['esc']}
                    scrollSmooth

                    className="helper"
                    rounded={5}
                    accentColor={'var(--special-color)'}
                    showNumber={false}

                    // onRequestClose={() => closeTour()}
                    // closeWithMask = {false}

                    disableInteraction
                    onClickHighlighted={(e) => {
                        e.stopPropagation()
                        console.log('No interaction at all')
                    }}
                // style={
                //     backgroundColor: '#bada55'
                // popover: (base) => ({
                //     ...base,
                //     '--reactour-accent': 'var(--special-color)',
                //     borderRadius: radius,
                //     marginLeft: '50px',
                //     border: '3px var(--primary-color) solid'
                // }),
                // maskArea: (base) => ({ ...base, rx: radius, }),
                // maskWrapper: (base) => ({ ...base, color: '#000000' }),
                // badge: (base) => ({ ...base, display: 'none' }),
                // // controls: (base) => ({ ...base, marginTop: 50 }),
                // close: (base) => ({ ...base, right: 10, top: 8 }),
                // } 
                />

                {/* <TourProvider

                        steps={tourConfig}
                        onClickClose={closeTour}
                        disableDotsNavigation={true}
                        onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
                            return
                            // if (steps) {
                            //   if (currentStep === steps.length - 1) {
                            //     setIsOpen(false)
                            //   }
                            //   setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1))
                            // }
                          }}
                        maskClassName="mask"
                        disableKeyboardNavigation={['esc']}
                        scrollSmooth
                        
                        className="helper"
                        rounded={5}
                        accentColor={'black'}
                        onRequestClose={() => closeTour()}
                        // closeWithMask = {false}

                        disableInteraction
                        onClickHighlighted={(e) => {
                            debugger
                            e.stopPropagation()
                            console.log('No interaction at all')
                        }}
                        styles={{
                            popover: (base) => ({
                                ...base,
                                '--reactour-accent': 'var(--special-color)',
                                borderRadius: radius,
                                marginLeft: '50px',
                                border: '3px var(--primary-color) solid'
                            }),
                            maskArea: (base) => ({ ...base, rx: radius, }),
                            maskWrapper: (base) => ({ ...base, color: '#000000' }),
                            badge: (base) => ({ ...base, display: 'none' }),
                            // controls: (base) => ({ ...base, marginTop: 50 }),
                            close: (base) => ({ ...base, right: 10, top: 8 }),
                        }}
                    >
                        <Main ></Main>
                    </TourProvider> */}
            </>
            : null}

        <section className='users-wrapper' >
            <div className='container-fluid'>

                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='title-buttons'>
                            <h2  >Duty Calculator</h2>

                        </div>
                    </div>
                </div>
                <br></br><br></br>

                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-10'>
                        <Box sx={{ width: '100%' }}>
                            <Stepper alternativeLabel activeStep={activeStep} disabled={true}>
                                {steps.map((label, index) => (
                                    <Step key={label} completed={completed[index]} >

                                        {(activeStep == 0 || activeStep == 2) && <StepButton data-tut={getScreenTourClass(index)} color="inherit" onClick={handleStep(index)}>
                                            {index == 0 && 'Search Custom Duty'}
                                            {index == 1 && 'Custom Duty Breakdown'}
                                            {index == 2 && 'Custom Duty Calculator'}
                                        </StepButton>}

                                        {activeStep == 1 && <StepButton className={'color-theme' + index} onClick={handleStep(index)}>
                                            {index == 0 && 'Search Custom Duty'}
                                            {index == 1 && 'Custom Duty Breakdown'}
                                            {index == 2 && 'Custom Duty Calculator'}
                                        </StepButton>}

                                    </Step>
                                ))}


                            </Stepper>
                            <div>
                                {allStepsCompleted() ? (
                                    <React.Fragment>
                                        <Typography sx={{ mt: 7, mb: 1 }}>
                                            All steps completed.
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            <Button onClick={handleReset}>Reset</Button>
                                        </Box>
                                    </React.Fragment>
                                ) : (


                                    <React.Fragment>
                                        <br /><br />
                                        {getComponent(activeStep + 1)}
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                            <Button
                                                color="inherit"
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                sx={{ mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                            <Box sx={{ flex: '1 1 auto' }} />





                                            {activeStep !== steps.length &&
                                                (completed[activeStep] ? (
                                                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                        <Button onClick={handleNext} sx={{ mr: 1 }}>
                                                            {/* <i className="fa fa-arrow-right" aria-hidden="true"></i> */}
                                                            {'Next'}
                                                        </Button>
                                                    </Typography>
                                                ) : (
                                                    <Button onClick={handleComplete}>
                                                        {completedSteps() === totalSteps() - 1
                                                            ? ''
                                                            : 'Next'}
                                                    </Button>
                                                ))}
                                        </Box>
                                    </React.Fragment>
                                    // (<>
                                    //     {completedSteps() === totalSteps() - 1
                                    //         ? ''
                                    //         : <Button onClick={handleComplete}>
                                    //             Next
                                    //         </Button>}
                                    // </>

                                )}
                            </div>




                        </Box>
                    </div>
                    <div className='col-md-1'></div>

                </div>
                <div className='row ' >
                    <div className='col-lg-4'></div>
                    <div className='col-lg-3'  >
                        <span data-tut="reactour__goTo10" ></span>
                    </div>
                    <div className='col-lg-5'></div>

                </div>



            </div>
        </section>







    </React.Fragment>
);
}



export default function Main() {
    const { setIsOpen } = useTour();

    useEffect(() => {
        setIsOpen(true)
    }, [])

    return (
        <div className="demo">


        </div>
    )
}
