import React, { useCallback, useEffect, useState } from 'react';
import { alertFormValidation, userFormValidation } from '../../utils/schemas/index';
import { useFormik } from 'formik';
import { showLoader, hideLoader } from "../../services/core/loader-service";
import { showSuccessToaster } from "../../utils/toaster/toaster";
import { finalize } from "rxjs";
import { getHsCode } from '../../services/duty-calc-service';
import * as constantsType from "../../utility/constants/common-constants";
import { addAlerts, editAlerts } from '../../services/alert-service';
import { Autocomplete, TextField } from '@mui/material';
import { showWarnToaster } from '../../utils/toaster/toaster';

export const AddEditAlertPage = (props) => {

    let [hsCodes, setHsCodeList] = useState([]);
    let [selectedHsCode, setSelectedHsCode] = useState('');

    let initValue = {
        name: '',
        hs_code: '',
        email_notification: false,
        web_notification: false,
        duty_change: false,
        new_hs_code: false
    };

    useEffect(() => {
        fetchHsCode();
    }, [])

    const fetchHsCode = (query) => {
        showLoader();

        let filter = {
            hs_code: query,
            app_source: constantsType.ADMIN_APP_SOURCE
        };
        let subscription = getHsCode({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;

                debugger

                if (props.action == 'Edit') {
                    let hs_code_arr = JSON.parse(JSON.stringify(response.results));
                    hs_code_arr.push({
                        code: props.initialData.data.hs_code,
                        description: props.initialData.data.hs_code,
                        id: new Date().getTime(),
                        text: props.initialData.data.hs_code + ''
                    })
                    debugger
                    setHsCodeList(hs_code_arr);
                    setSelectedHsCode(props.initialData.data.hs_code);

                } else {
                    setHsCodeList(response.results);

                }



            });
    };


    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: props.action == 'Edit' ? props.initialData.data : initValue,
        validationSchema: alertFormValidation,
        enableReinitialize: true,
        onSubmit: (value) => {
            if (!selectedHsCode) {
                showWarnToaster('Please Select HS Code');
                return
            }
            value['hs_code'] = selectedHsCode;

            if (props.action == 'Add') {
                create(value)
            } else {
                update(value)
            }
        }
    })


    const create = (params) => {
        let payload = { ...params };
        showLoader();
        let addSubscription = addAlerts({ data: payload })
            .pipe(finalize(() => {
                hideLoader();
                addSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                closeAndUpdateModel();
            });
    }


    const closeAndUpdateModel = () => {
        props.closeModal(false);
        props.onHide();
    }





    const update = (params) => {
        let payload = { ...params };
        showLoader();
        let id = props.initialData.data.id;
        let updateSubscription = editAlerts({ data: payload }, id)
            .pipe(finalize(() => {
                hideLoader();
                updateSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                closeAndUpdateModel();
            });
    }

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 600);
        }
    }

    const onHandleChange = (value) => {
        if (value) {
            let isValueExists = (props.initialData.data.hs_code == value) ? true : false;
            if (!isValueExists) {
                fetchHsCode(value);
            }

        }
    }



    const optimisedVersion = useCallback(debounce(onHandleChange), []);


    return (

        <form onSubmit={handleSubmit}>

            <div className="input-container">
                <label style={{ "fontWeight": 500 }}>Alert Name <sup>*</sup></label>
                <input type="text" className="input-control" placeholder="Enter First Name" name="name" value={values.name} onChange={handleChange} />
                {errors.name && <p className='error'>{errors.name}</p>}
            </div>


            <div className="input-container">
                <label style={{ "fontWeight": 500 }}>Hs Code <sup>*</sup></label>

                {
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={hsCodes}
                        value={selectedHsCode}
                        onChange={(event, newValue) => {
                            console.log(newValue, "nea")
                            let selectedValue = newValue ? newValue.code : "";
                            setSelectedHsCode(selectedValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            optimisedVersion(newInputValue);
                        }}
                        getOptionLabel={(option) => option['code'] || option}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Hs Code" />}
                    />
                }
                {errors.last_name && <p className='error'>{errors.last_name}</p>}
            </div>


            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <div className="input-container">
                            <label style={{ "fontWeight": 500, marginLeft: "-13px", marginBottom: "10px" }}>Alert Criteria <sup>*</sup></label>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="input-container">
                            <label>Duty Change&nbsp;
                                <input type="checkbox" name="duty_change" checked={values.duty_change} onChange={handleChange} />
                                {errors.duty_change && <p className='error'>{errors.duty_change}</p>}
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className="input-container">
                            <label>New Hs Code&nbsp;
                                <input type="checkbox" name="new_hs_code" checked={values.new_hs_code} onChange={handleChange} />
                                {errors.new_hs_code && <p className='error'>{errors.new_hs_code}</p>}
                            </label>
                        </div>
                    </div>

                    <div className='col-lg-2'>
                        <div className="input-container">
                            <label style={{ "fontWeight": 500, marginLeft: "-13px", marginBottom: "10px" }}>Alert Type <sup>*</sup></label>

                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className="input-container">
                            <label>Email Notifications&nbsp;
                                <input type="checkbox" name="email_notification" checked={values.email_notification} onChange={handleChange} />
                                {errors.email_notification && <p className='error'>{errors.email_notification}</p>}
                            </label>

                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className="input-container">


                            <label>Web Notifications&nbsp;
                                <input type="checkbox" name="web_notification" checked={values.web_notification} onChange={handleChange} />
                                {errors.web_notification && <p className='error'>{errors.web_notification}</p>}
                            </label>
                        </div>
                    </div>



                </div>
            </div>

            <div className='input-container'>
                <button type="submit" className="btn btn-primary" >Submit</button>&nbsp;
            </div>
        </form >
    );
}