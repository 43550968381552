import { createSlice } from '@reduxjs/toolkit';
import * as constantType from "../../utility/constants/common-constants";

const initialState = {
    permission: JSON.parse(localStorage.getItem(constantType.PERMISSION_SET)),
}

const PermissionSlice = createSlice({
    name: constantType.ACTION_PERMISSION,
    initialState,
    reducers: {
        setPermission: (state, { payload }) => {
            state.permission = payload
        }
    }
});

export const { setPermission, permission } = PermissionSlice.actions;
export const PermissionSelector = state => state.permission;
export default PermissionSlice.reducer;