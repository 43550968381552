import React, { useState, useEffect, useCallback } from 'react';
import "../../../static/css/pages/users/user.css";
import '../../../../lib/static/css/modal/modal.module.css';
import Modal from '../../Common/Modal/Modal';
import { AddEditRolePage } from "./AddEditRole";
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import * as moduleConstants from "../../../utility/constants/module-action-constants";
import * as actionConstants from "../../../utility/constants/module-action-constants";
import * as pageConstant from "../../../utility/constants/page-route-constants";
import { ConfirmModal } from "../../Common/ConfirmModal/ConfirmModal";
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import { PermissionSelector } from "../../../redux/permission/permission-reducer";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../../factory/helpers/permission-service";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../Common/DataTable/DataTable";
import { listRole, deleteRole } from "../../../services/role-service";
import { finalize } from "rxjs";
import Search from "../../Common/Search/Search";
import "../../../../lib/static/css/global/global.css";
import { ROLE_PAGE, USER_MANAGEMENT } from '../../../utility/constants/page-label-constants';
import { Breadcrumb } from '../../../components/Common/Breadcrumb/Breadcrumb';

export const RolePage = () => {
    const { permission } = useSelector(PermissionSelector);
    const navigate = useNavigate();
    const [showDeletConfirmModal, setDeleteShowConfirmModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [selectedAction, setAction] = useState('Add');
    const [roleList, setRoleList] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [sortable, setSortable] = useState([]);
    const [sort, SetSort] = useState(new Map())
    const PER_PAGE = 10;

    useEffect(() => {
        showLoader();
        getRoleList('', currentPage);
    }, [currentPage]);


    const handlePageClick = (selectedObject) => {
        let selectedPage = selectedObject.selected + 1;
        setCurrentPage(selectedPage)
        showLoader();
    }


    const isShow = (actionName) => {
        let child = moduleConstants.ROLES;
        return hasPermission(permission, child, actionName)
    }

    const getRoleList = (params, selectedPage = 1) => {
        showLoader();
        let filter = { ...params, _page: selectedPage, _page_size: PER_PAGE };
        let roleSubscription = listRole({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                roleSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                setRoleList(response.data);
                setPageCount(response.count);
                setSortable(response.sortable);
            });
    }


    const onSearchHandler = useCallback((searchValue) => {
        let params = { _q: searchValue };
        getRoleList(params);
    }, [])


    const onHideModalHandler = () => {
        getRoleList('');
    }

    const onEditHandler = (action, rowData) => {
        navigate(`${pageConstant.EDIT_ROLE_PAGE}/${rowData.id}`);
    }

    const onAddHandler = (action, rowData) => {
        navigate(pageConstant.ADD_ROLE_PAGE);
    }

    const onDeleteHandler = (event, rowData) => {
        setDeleteShowConfirmModal(true);
        setSelectedRole(rowData);
    }

    const onSortingChange = (params, key, sortBy) => {
        let sortPair = new Map()
        if (key && sortBy) {
            sortPair.set(key, sortBy)
            SetSort(sortPair);
        } else {
            SetSort(sortPair);
        }

        getRoleList(params);
    }


    const applyDelete = (data) => {
        showLoader();
        const id = data['id'];
        let deleteSubscription = deleteRole(id)
            .pipe(finalize(() => {
                hideLoader();
                deleteSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                getRoleList('');
            });
    }

    let headerConfig = [
        { colLabel: 'Id', colKey: 'id' },
        { colLabel: 'Name', colKey: 'name' },
        { colLabel: 'key', colKey: 'key' },
        { colLabel: 'Default Action', colKey: 'default_module_action_label' },
    ];

    let actionList = [
        { actionName: 'edit', faIcon: 'edit', hasPermission: isShow, callEvent: onEditHandler },
        { actionName: 'delete', faIcon: 'trash', hasPermission: isShow, callEvent: onDeleteHandler },
    ];

    const BreadcrumbConfig = [
        { label: USER_MANAGEMENT, link: null, highlight: true },
        { label: ROLE_PAGE, link: null, },
    ]

    return (
        <React.Fragment>

            <Breadcrumb breadcrumbList={BreadcrumbConfig} />

            <section className='users-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='title-buttons'>
                                <h2 className='title'>Role</h2>
                                <div className='buttons ml-auto'>
                                    <button style={{ display: isShow(actionConstants.ADD) ? 'block' : 'none' }} onClick={onAddHandler} type='button' className='btn btn-primary'>Add Role</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='form-inline'>

                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='search-group mt-2 mb-2'>
                                <Search onSearch={onSearchHandler} placeHolder={"Search..."} />

                            </div>
                        </div>
                    </div>

                    <DataTable rowData={roleList} headerGroup={headerConfig}
                        totalPage={pageCount} onPageChange={handlePageClick}
                        sortGroup={sortable}
                        sort={sort}
                        onSortingChange={onSortingChange}
                        actionGroup={actionList}>

                    </DataTable>

                </div>
            </section>


            {showAddEditModal && <Modal show={showAddEditModal} onClose={() => setShowAddEditModal(false)} modal_title={`${selectedAction} Role`}>
                {<AddEditRolePage action={selectedAction} closeModal={setShowAddEditModal} editmode={true} onHide={onHideModalHandler} initialData={{ 'data': (selectedRole ? selectedRole : {}) }} />}
            </Modal>}

            {showDeletConfirmModal && <Modal show={showDeletConfirmModal} onClose={() => setDeleteShowConfirmModal(false)} modal_title="Confirm">
                <ConfirmModal initialData={{
                    'data': selectedRole,
                    'action': 'delete'
                }} onClose={applyDelete}
                    hideModal={setDeleteShowConfirmModal} />
            </Modal>}

        </React.Fragment>
    );
}
