import React from "react";
import "../../../static/css/global/global.css";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

export const Breadcrumb = ({ breadcrumbList }) => {

    return (
        <div>
            <ul className="breadcrumb">
                {
                    breadcrumbList && breadcrumbList.map((data, i) => {
                        return <li key={i}>
                            {data.link ? <Link to={data.link} >{data.label}</Link> : <span style={{ color: data.highlight ? 'var(--primary-color)' : 'var(--secondary-color)' }}>
                                {data.label}</span>}
                        </li>
                    })
                }
            </ul>
        </div>
    );
}

Breadcrumb.propTypes = {
    breadcrumbList: PropTypes.array.isRequired,
};