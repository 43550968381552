import React from "react";
 
export const DotLoader = () => {


   
    return (
        <div class="loading-dots">
            <div class="loading-dots--dot"></div>
            <div class="loading-dots--dot"></div>
            <div class="loading-dots--dot"></div>
        </div>
    );
}

 