import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { Login } from '../components/Auth/Login/Login';
import { ProfilePage } from '../components/Profile/Profile';
import { useSelector } from 'react-redux';
import LoadingSpinner from "../utils/Loader/Spinner";
import { AboutUs } from "../components/About/About";
import AppLayout from '../components/Layout/AppLayoutModule';
import ForgotPassword from '../components/Auth/ForgotPassword/ForgotPassword';
import { ChangePassword } from "../components/Auth/ChangePassword/ChangePassword";
import { LookupPage } from '../components/MasterManagement/Lookup/LookupPage';
import { LookupTypePage } from '../components/MasterManagement/LookupType/LookupTypePage';
import { AddEditRolePage } from "../components/UserManagement/ROLE/AddEditRole";
import { RolePage } from '../components/UserManagement/ROLE/RolePage';
import RouterRedirectComponent from "../components/Common/Utility/RouterRedirect";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import { NotFound } from "../components/NotFound/404";

import {
    ABOUT_URL,
    C_PASSWORD_URL,
    F_PASSWORD_URL,
    HOME_URL,
    LOGIN_URL, MASTER_MGMT_LOOKUP_URL,
    MASTER_MGMT_LOOKUPTYPE_URL,
    PROFILE_URL,
    R_PASSWORD_URL,
    USER_MGMT_URL,
    ROLE_MGMT_URL,
    ADD_ROLE_URL,
    EDIT_ROLE_URL,
    NOT_FOUND,
    FORBIDDEN_PAGE,
    DASHBOARD_URL,
    ALERT_MGMT_URL,
    NOTIFICATION_MGMT_URL,
    SUBSCRIPTION_MGMT_URL,
    DUTY_CALCULATOR_URL,
    SIGNUP_URL,
    SUBSCRIPTION_HISTORY_MGMT_URL,
    SUBSCRIPTION_PLAN_MGMT_URL,
    SUBSCRIPTION_PAYMENT_URL,
    TERM_PAGE,
    MAINTAINENCE_PAGE_URL,
    TRNX_DETAILS,
    TROUBLE_TICKET,
    SEARCH_HISTORY,
    ACCEPT_SUBSCRIPTION_PAYMENT_URL
} from "./router-constatns";
import { ADD, EDIT, LOOKUP, LOOKUP_TYPE, ROLES, USERS } from '../utility/constants/module-action-constants';
import { Forbidden } from '../components/Forbidden/401';
import { UserIndexPage } from '../components/UserManagement/USERS/UserPage';
import { ResetPassword } from '../components/Auth/ResetPassword/ResetPassword';
import { DashboardPage } from '../components/Dashboard/Dashboard';
import { AlertPage } from '../components/Alerts/AlertPage';
import { NotificationPage } from '../components/Notifications/NotificationPage';
import { DutyCalculatorPage } from '../components/DutyCalculator/DutyCalculatorPage';
import { Signup } from '../components/Auth/Signup/Signup';
import { SubscriptionPlan } from '../components/Subscription/SubscriptionPlan';
import { SubscriptionHistory } from '../components/Subscription/SubscriptionHistory';
import { SubscriptionPayment } from '../components/Subscription/SubscriptionPayment';
import { TermAndCondition } from '../components/TermAndCondition/TermAndCondition';
import { MaintaincePage } from '../components/Maintaince/Maintaince';
import { TranxDetails } from '../components/Subscription/TranxDetails';
import { TroubleTicketPage } from '../components/TroubleTicket/TroubleTicket';
import { UsageHistory } from '../components/UsageHistory/UsageHistory';
import { AcceptSubscriptionPayment } from '../components/Subscription/AcceptSubscriptionPayment';

export const AppRouter = () => {
    const { url } = useSelector(state => state.routerRedirectReducer);

    return (<>
        <LoadingSpinner />

        <Router>
            <AppLayout>
                <Routes>
                    <Route path={HOME_URL}  element={<PublicRoute restricted={true} component={<Login />} />} />
                    <Route path={ABOUT_URL} element={<PublicRoute component={<AboutUs />} />} />
                    <Route path={PROFILE_URL} element={<ProtectedRoute component={<ProfilePage />} byPassRoutes={true} />} />
                    <Route path={DASHBOARD_URL} element={<ProtectedRoute component={<DashboardPage />} byPassRoutes={true} />} />

                    <Route path={LOGIN_URL} element={<PublicRoute restricted={true} component={<Login />} />} />
                    <Route path={SIGNUP_URL} element={<PublicRoute component={<Signup />} />} />

                    <Route path={F_PASSWORD_URL}
                        element={<PublicRoute restricted={true} component={<ForgotPassword />} />} />
                    <Route path={R_PASSWORD_URL}
                        element={<PublicRoute restricted={true} component={<ResetPassword />} />} />
                    <Route path={C_PASSWORD_URL} element={<ProtectedRoute component={<ChangePassword />} byPassRoutes={true} />} />

                    <Route path={USER_MGMT_URL} element={<ProtectedRoute component={<UserIndexPage />} componentKey={USERS} />} />
                    <Route path={ROLE_MGMT_URL} element={<ProtectedRoute component={<RolePage />} componentKey={ROLES} />} />
                    <Route path={ADD_ROLE_URL} element={<ProtectedRoute component={<AddEditRolePage />} componentKey={ROLES} feature={ADD} />} />
                    <Route path={EDIT_ROLE_URL} element={<ProtectedRoute component={<AddEditRolePage />} componentKey={ROLES} feature={EDIT} />} />

                    <Route path={MASTER_MGMT_LOOKUP_URL} element={<ProtectedRoute component={<LookupPage />} componentKey={LOOKUP} />} />
                    <Route path={MASTER_MGMT_LOOKUPTYPE_URL} element={<ProtectedRoute component={<LookupTypePage />} componentKey={LOOKUP_TYPE} />} />

                    <Route path={ALERT_MGMT_URL} element={<ProtectedRoute component={<AlertPage />} byPassRoutes={true} />} />
                    <Route path={NOTIFICATION_MGMT_URL} element={<ProtectedRoute component={<NotificationPage />} byPassRoutes={true} />} />
                    <Route path={SUBSCRIPTION_HISTORY_MGMT_URL} element={<ProtectedRoute component={<SubscriptionHistory />} byPassRoutes={true} />} />
                    <Route path={SUBSCRIPTION_PLAN_MGMT_URL} element={<ProtectedRoute component={<SubscriptionPlan />} byPassRoutes={true} />} />
                    <Route path={SUBSCRIPTION_PAYMENT_URL} element={<ProtectedRoute component={<SubscriptionPayment />} byPassRoutes={true} />} />
                    <Route path={ACCEPT_SUBSCRIPTION_PAYMENT_URL} element={<PublicRoute component={<AcceptSubscriptionPayment />} restricted={true} />} />
                    
                    <Route path={DUTY_CALCULATOR_URL} element={<ProtectedRoute component={<DutyCalculatorPage />} componentKey={DUTY_CALCULATOR_URL} byPassRoutes={true} />} />
                    <Route path={TRNX_DETAILS} element={<ProtectedRoute component={<TranxDetails />} byPassRoutes={true} />} />
                    <Route path={TROUBLE_TICKET} element={<ProtectedRoute component={<TroubleTicketPage />} byPassRoutes={true} />} />
                    
                    <Route path={SEARCH_HISTORY} element={<ProtectedRoute component={<UsageHistory />} byPassRoutes={true} />} />
                    

                    <Route path={NOT_FOUND} element={<NotFound />} />
                    <Route path={TERM_PAGE} element={<TermAndCondition />} />
                    <Route path={FORBIDDEN_PAGE} element={<Forbidden />} />
                    <Route path={MAINTAINENCE_PAGE_URL} element={<MaintaincePage />} />
                    <Route path="*" element={<Navigate to={NOT_FOUND} />} />

                </Routes>
            </AppLayout>
            {url && <RouterRedirectComponent urlToRedirect={url} />}
        </Router>

    </>
    );
}
