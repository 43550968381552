import React, { useState, useEffect, useCallback } from 'react';
import "../../../static/css/pages/users/user.css";
import '../../../../lib/static/css/modal/modal.module.css';
import Modal from '../../Common/Modal/Modal';
import { AddEditUserPage } from "./AddEditUser";
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { ConfirmModal } from "../../Common/ConfirmModal/ConfirmModal";
import * as constantsType from "../../../utility/constants/common-constants";
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import { DataTable } from "../../Common/DataTable/DataTable";
import { actiavteUser, deleteUser, listUsers } from "../../../services/user-service";
import { finalize } from "rxjs";
import Search from "../../Common/Search/Search";
import "../../../../lib/static/css/global/global.css";
import { USER_MANAGEMENT, USER_PAGE } from '../../../utility/constants/page-label-constants';
import { Breadcrumb } from '../../../components/Common/Breadcrumb/Breadcrumb';


export const UserIndexPage = () => {

    const [showDeletConfirmModal, setDeleteShowConfirmModal] = useState(false);
    const [showActivateConfirmModal, setActivateShowConfirmModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [selectedAction, setAction] = useState('Add');
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [sortable, setSortable] = useState([]);
    const [sort, SetSort] = useState(new Map())
    const PER_PAGE = 10;

    useEffect(() => {
        getUsersList('', currentPage);
    }, [currentPage]);

    const handlePageClick = (selectedObject) => {
        let selectedPage = selectedObject.selected + 1;
        setCurrentPage(selectedPage)

    }

    const getUsersList = (params, selectedPage = 1) => {
        showLoader();

        let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE, page: selectedPage, _page_size: PER_PAGE };
        let userListubscription = listUsers({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                userListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                setUserList(response.results);
                setPageCount(response.count);
                setSortable(['first_name', 'last_name', 'email', 'mobile_number']);
            });
    }

    const onSearchHandler = useCallback((searchValue) => {
        let params = { _q: searchValue };
        getUsersList(params);
    }, []);




    const onSortingChange = (params, key, sortBy) => {
        let sortPair = new Map()
        if (key && sortBy) {
            sortPair.set(key, sortBy)
            SetSort(sortPair);
        } else {
            SetSort(sortPair);
        }

        getUsersList(params);
    }



    const onHideModalHandler = () => {
        getUsersList('');
    }

    const onAddHandler = (event, rowData) => {
        setShowAddEditModal(true);
        setAction('Add')
        setSelectedUser(rowData);
    }

    const onEditHandler = (event, rowData) => {
        setShowAddEditModal(true);
        setAction('Edit')
        setSelectedUser(rowData);
    }

    const onDeleteHandler = (event, rowData) => {
        setDeleteShowConfirmModal(true);
        setSelectedUser(rowData);

    }

    const onActivateHandler = (event, rowData) => {
        setActivateShowConfirmModal(true);
        setSelectedUser(rowData);
    }


    const applyDelete = (data) => {
        showLoader();
        const id = data['id'];
        let deleteSubscription = deleteUser(id)
            .pipe(finalize(() => {
                hideLoader();
                deleteSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                getUsersList('');
            });
    }

    const applyActivate = (data) => {
        showLoader();
        const id = data['id'];
        let payload = { "is_active": !data.is_active }
        let activateSubscription = actiavteUser(id, payload)
            .pipe(finalize(() => {
                hideLoader();
                activateSubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                showSuccessToaster(response.message);
                getUsersList('');
            });
    }

    let headerConfig = [
        // { colLabel: 'Id', colKey: 'id' },
        { colLabel: 'First Name', colKey: 'first_name' },
        { colLabel: 'Last Name', colKey: 'last_name' },
        { colLabel: 'Email', colKey: 'email' },
        { colLabel: 'Mobile', colKey: 'mobile_number' },
    ];

    let actionList = [
        { actionName: 'edit', faIcon: 'edit', callEvent: onEditHandler },
        { actionName: 'delete', faIcon: 'trash', callEvent: onDeleteHandler },
        { actionName: 'active', faIcon: 'trash', callEvent: onActivateHandler },
    ];



    return (
        <React.Fragment>

            <section className='users-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-xs-18'>
                            <div className='title-buttons'>
                                <h2 >Sub User Management</h2>
                                <div className='buttons ml-auto'>
                                    <button onClick={onAddHandler} type='button' className='btn btn-primary'>Add New User</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='form-inline'>

                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='search-group mt-2 mb-2'>
                                <Search onSearch={onSearchHandler} placeHolder={"Search..."} />
                            </div>
                        </div>

                    </div>

                    <DataTable rowData={userList} headerGroup={headerConfig}
                        totalPage={pageCount} onPageChange={handlePageClick}
                        sortGroup={sortable}
                        sort={sort}
                        onSortingChange={onSortingChange}
                        actionGroup={actionList}>
                    </DataTable>

                </div>
            </section>


            {showAddEditModal && <Modal show={showAddEditModal} onClose={() => setShowAddEditModal(false)} modal_title={`${selectedAction} User`}>
                {<AddEditUserPage action={selectedAction} closeModal={setShowAddEditModal} onHide={onHideModalHandler} initialData={{ 'data': (selectedUser ? selectedUser : {}) }} />}
            </Modal>}

            {showDeletConfirmModal && <Modal show={showDeletConfirmModal} onClose={() => setDeleteShowConfirmModal(false)} modal_title="Confirm">
                <ConfirmModal initialData={{
                    'data': selectedUser,
                    'action': 'delete'
                }} onClose={applyDelete}
                    hideModal={setDeleteShowConfirmModal} />
            </Modal>}

            {showActivateConfirmModal && <Modal show={showActivateConfirmModal} onClose={() => setActivateShowConfirmModal(false)} modal_title="Confirm">
                <ConfirmModal initialData={{
                    'data': selectedUser,
                    'action': 'activate'
                }} onClose={applyActivate}
                    hideModal={setActivateShowConfirmModal} />
            </Modal>}

        </React.Fragment>
    );
}
