import { toast } from 'react-toastify';

export function showSuccessToaster(msg) {
    toast.success(msg);
}

export function showErrorToaster(msg) {
    toast.error(msg);
}

export function showWarnToaster(msg) {
    toast.warn(msg);
}