import { from, map } from "rxjs";
import HTTP_CLIENT from "../factory/modules/http-client";
import { HTTP_STATUS } from "../utility/constants/common-constants";
import AppConfig from "../factory/modules/app-config";



export const listNotification = (args,) => {
    args.url = AppConfig.APIS.notification;
   
    return from(HTTP_CLIENT.GET(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}

export const addNotification = (args) => {
    args.url = AppConfig.APIS.notification;
    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.CREATED) {
                return res.data;
            }
            return false;
        }))
}


export const editNotification = (args, id) => {
    args.url = `${AppConfig.APIS.notification
        }/${id}`;
    return from(HTTP_CLIENT.PUT(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.data;
            }
            return false;
        }))
}


export const deleteNotification = (id) => {
    let args = {};
    args.url = `${AppConfig.APIS.notification
        }/${id}`;

    return from(HTTP_CLIENT.DELETE(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.results;
            }
            return false;
        }))
}

export const actiavteNotification = (id) => {
    let args = {};
    args.url = `${AppConfig.APIS.notification
        }/${id}`;

    return from(HTTP_CLIENT.PATCH(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.results;
            }
            return false;
        }))
}


export const markAllReadNotification = (id) => {
    let args = {};
    args.url = `${AppConfig.APIS.mark_read}`;

    return from(HTTP_CLIENT.POST(args)).pipe(
        map((res) => {
            if (res && res.status === HTTP_STATUS.OK) {
                return res.results;
            }
            return false;
        }))
}






