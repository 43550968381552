import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./lib/static/css/style.css";
import { ErrorBoundary } from './lib/components/Common/ErrorHandler/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary showErrorDetails={true}>
    <App />
  </ErrorBoundary>
);

reportWebVitals();
