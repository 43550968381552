import React from 'react';
import { useSelector } from 'react-redux';
import { loginSelector } from '../../redux/auth/auth-reducer';
import Footer from './Footer/Footer';
import Header from './Header/Header';

const AppLayout = (props) => {
    const { isLoggedIn } = useSelector(loginSelector);

    return <>
        
        {isLoggedIn && <Header />}
        
        <div className="page-wrapper" id="page-wrapper">
            {props.children}
        </div>
        {isLoggedIn && <Footer />}
    </>
    
}

export default AppLayout