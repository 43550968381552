import axios from 'axios';
import { store } from "../../redux/store";
import * as constantType from "../../utility/constants/common-constants";

export function jwtInterceptor() {
    axios.defaults.headers.common['app-source'] = constantType.CUSTOMER_APP_SOURCE;
    axios.defaults.headers.common['access-key'] = constantType.hash;


    axios.interceptors.request.use(request => {
        // add auth header with jwt if account is logged in and request is to the api url
        const loggedInUser = store.getState().login;
        // const isAuthHeaderRequired = AppConfig.isAuthHeaderRequired(request.url);
        if (loggedInUser.isLoggedIn && loggedInUser.payloadAccessToken) {
            request.headers.authorization = `Bearer ${loggedInUser.payloadAccessToken}` || null;
        }

        return request;
    });
}
