import { createSlice } from '@reduxjs/toolkit';
import * as constantType from "../../utility/constants/common-constants";

const initialState = {
    loading: false
}

const GlobalLoaderSlice = createSlice({
    name: constantType.GLOBAL_LOADER,
    initialState,
    reducers: {
        setGlobalLoader: (state, { payload }) => {
            state.loading = payload
        }
    }
});

export const { setGlobalLoader, loading } = GlobalLoaderSlice.actions;
export const GlobalLoaderSelector = state => state.globalLoader;
export default GlobalLoaderSlice.reducer;