import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { DutyCalculatorPage } from '../components/DutyCalculator/DutyCalculatorPage';
import { hasRouteAccess } from '../factory/helpers/permission-service';
import { PermissionSelector } from '../redux/permission/permission-reducer';
import { isLogin } from "../services/auth-service";
import { VIEW } from '../utility/constants/module-action-constants';
import { DUTY_CALCULATOR_URL, FORBIDDEN_PAGE, LOGIN_URL } from "./router-constatns";

const ProtectedRoute = ({ component, componentKey, feature, byPassRoutes }) => {
    // const { permission } = useSelector(PermissionSelector);

    const clearDutyCalStroage = () => {
        localStorage.removeItem('selectedHsCode');
        localStorage.removeItem('selectedCountry');
        localStorage.removeItem('step3State');
        localStorage.removeItem('partnerCode');
        localStorage.removeItem('ptaData');
        localStorage.removeItem("selectedGST");
        localStorage.removeItem("calculated_total");
        clearCessColumnValues();

    }

     
    const clearCessColumnValues = () => {
        let obj = Object.assign({}, localStorage)
        for (const [key, value] of Object.entries(obj)) {
            if(key.includes('-custom-cess')){
                console.log(`${key}: ${value}`);
                localStorage.removeItem(key)
            }
        }
    }

    feature = feature ? feature : VIEW;

    if (isLogin()) {
        if (componentKey == DUTY_CALCULATOR_URL) {
            clearDutyCalStroage()
        }
    }

    if (!isLogin()) {
        return <Navigate to={LOGIN_URL} replace />;
    }

    if (byPassRoutes) {
        return component;
    }



    // if (!hasRouteAccess(permission, componentKey, feature)) {
    //     return <Navigate to={FORBIDDEN_PAGE} replace />;
    // }

    return component;
};

export default ProtectedRoute;
