import React, { useState, useEffect, useCallback } from 'react';
import "../../../static/css/pages/users/user.css";
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { finalize } from "rxjs";
import "../../../../lib/static/css/global/global.css";
import { getCustomDutyRates, getCustomDutyValues } from '../../../services/duty-calc-service';
import { Button } from '@mui/material';
import { showWarnToaster } from '../../../utils/toaster/toaster';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logoInvert from "../../../static/img/logo-invert.png";

export const Step5 = () => {

    const [rateList, setRateList] = useState([]);
    const [totalCustomDuty, setTotalCustomDuty] = useState(0);
    const [cifValue, setCifValue] = useState(null);
    const [qtyValue, setQtyValue] = useState(null);
    const [isPdfShow, setPdfShow] = useState(false);
    const [isItemHide, setItemHide] = useState(true);

    let pdf = new jsPDF("l", "cm", "a4");

    const tempHideItems = () => {
        if (isPdfShow == true) {
            setItemHide(false);
            setTimeout(() => {
                setItemHide(true)
            }, 2000)
        }
    }

    useEffect(() => {
    }, [])

    const PTA = () => {
        let data = localStorage.getItem("ptaData");
        return (data) ? JSON.parse(data) : null;
    }

    let getNotification = () => {
        let data = localStorage.getItem("notification_data");
        return (data) ? JSON.parse(data) : null;
    }

    let getGSTData = () => {
        let data = localStorage.getItem("selectedGST");
        return (data) ? JSON.parse(data) : null;
    }

    const GST = () => {
        let data = localStorage.getItem("gstfield");
        return (data) ? JSON.parse(data) : null;
    }

    const getUnitPercentageValue = () => {
        return localStorage.getItem('isUnitOtherthanPerctange');
    }

    const getCustomeCessPayload = (val) => {
        return {
            'value': val.value,
            'specific_rate': val.value,
            'tariff_text': val.value,
            'unit': '%',
        }
    }

    // unit% == all -> hide
    // unit% !== any -> show and madatory

    const fetchCustomDutyValues = () => {
        let unitValue = localStorage.getItem('isUnitOtherthanPerctange');

        if (unitValue == 'true') {
            if (!qtyValue) {
                showWarnToaster("Please Enter Quantity Value");
                return
            }
        }

        if (!cifValue) {
            showWarnToaster("Please Select CIF Value");
            return
        }

        let gstField;
        if (GST()) {
            gstField = GST().column_name;
        }
        setPdfShow(true)
        showLoader();


        let hscode = localStorage.getItem('selectedHsCode');
        // let selectedGSTData = getGSTData() ? getGSTData().value : null;
        let payload = {
            hs_code: hscode,
            query_id: localStorage.getItem('query_id'),
            cif_value: cifValue,
            qty: qtyValue,
            custom_values: {

                preferential_rates: {

                }
            }

        }

        // if (gstField) {
        //     payload.custom_values[gstField] = {
        //         'value': selectedGSTData,
        //         'specific_rate': selectedGSTData,
        //         'tariff_text': selectedGSTData,
        //         'unit': '%',
        //     }
        // }

        const CustomCessArr = JSON.parse(localStorage.getItem('CustomCess'));
        CustomCessArr.forEach(element => {
            let val;
            if (element.key.toLocaleLowerCase() == 'gst') {
                val = localStorage.getItem('selectedGST') ? JSON.parse(localStorage.getItem('selectedGST')) : {}
            } else {
                val = localStorage.getItem(element.key + '-custom-cess') ? JSON.parse(localStorage.getItem(element.key + '-custom-cess')) : {}
            }

            debugger
            let customCessObj = getCustomeCessPayload(val)
            payload.custom_values[element.key] = customCessObj;
        });




        let ptaData = PTA();
        let notificationData = getNotification() || "";
        let bcd_rate = notificationData ? notificationData.bcd_rate : '';

        debugger
        if (ptaData) {
            payload.custom_values['preferential_rates']['specific_rate'] = bcd_rate ? bcd_rate : (ptaData.specific_rate || "");
            payload.custom_values['preferential_rates']['tariff_text'] = bcd_rate ? bcd_rate : (ptaData.tariff_text || "");
            payload.custom_values['preferential_rates']['value'] = bcd_rate ? bcd_rate : (ptaData.value !== null ? ptaData.value : "");
            payload.custom_values['preferential_rates']['unit'] = ptaData.unit;
        }

        let subscription = getCustomDutyValues(payload)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let rateList = response.data.custom_duties;
                let pDList = response.data.preferential_duties;

                if (pDList && pDList.length) {
                    rateList.forEach((rateObj) => {
                        pDList.forEach((pdObj) => {
                            if (rateObj.column_name == pdObj.column_name) {
                                rateObj['pd_value'] = pdObj.val;
                            }
                        })
                    })
                }

                setRateList(rateList);

            });
    };

    const calculateSum = (arr, key) => {
        let sum = arr.reduce((a, b) => {
            return a + b[key]
        }, 0)

        return sum && sum.toFixed(2);
    }

    let exportAsPDF = (div_id) => {
        showLoader();
        tempHideItems();
        setTimeout(() => {
            let data = document.getElementById(div_id);
            const quality = 2;
            html2canvas(data, { scale: quality }).then((canvas) => {
                const contentDataURL = canvas.toDataURL("image/png");
                pdf.setFontSize(30);
                pdf.addImage(contentDataURL, "PNG", 1, 0.4, 26.7, 19.0);
                pdf.save("Duty-Calculation.pdf");
                hideLoader();
            });
        }, 2000);
    }







    return (
        <React.Fragment>
            <div className="primary-panel">
                <div className={'primary-panel ' + !isItemHide ? 'padt0' : ''} id="MyDIv" style={{ position: "relative" }}>
                    {!isItemHide ?
                        <span className='watermark-calc'>
                            <img src={logoInvert} />
                        </span>
                        : null
                    }
                    {!isItemHide ? <>
                        <div className="row">
                            <div className="col-md-12 text-center" >
                                <span className='dashboard-header-logo' style={{ textAlign: "center" }}>
                                    <img src={logoInvert} style={{ width: "240px" }} />
                                </span>
                            </div>

                        </div>
                        <br></br>
                    </> : null}
                    <h4 className='theme-statement'>Duty Calculation of {localStorage.getItem('selectedHsCode')} </h4>
                    <br></br>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label style={{ fontWeight: 500 }}>Assessable Value</label><br /><br />
                            <input type="number" min="0" placeholder="Assessable Value" value={cifValue} onChange={(e) => setCifValue(e.target.value)} className='input-control res-mb1' />
                        </div>

                        {/* other than persantage -> milta hai -> show */}
                        {getUnitPercentageValue() == "true" ?
                            <>
                                <div className='col-md-6'>
                                    <label style={{ fontWeight: 500 }}>Quantity {localStorage.getItem('isUnitOtherthanPerctange') ? <>
                                        ({
                                            localStorage.getItem('OtherthanPerctangeUnit')}
                                        )
                                    </> : ''}
                                    </label><br /><br />
                                    <input type="number" min="0" placeholder="Quantity" value={qtyValue} onChange={(e) => setQtyValue(e.target.value)} className='input-control' />
                                </div>
                            </> : null
                        }
                    </div>
                    {/* <br></br>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label style={{ fontWeight: 500 }}>Assessable Value </label><br /><br />
                            <input style={{ cursor: 'not-allowed' }} type="number" min="0" placeholder="Assessable Value" value={parseFloat(cifValue) + parseFloat((cifValue * 0.01))} disabled className='input-control' />
                        </div>
                    </div> */}

                    <br></br>

                    {isItemHide ?
                        <Button className='btn-theme' variant="contained" onClick={() => fetchCustomDutyValues()}>Calculate</Button>

                        : null

                    }




                    <br></br>
                    <br></br>
                    {rateList.length ?

                        <table className="table table-user">

                            <thead>
                                <tr >
                                    <th>Duty/Cess </th>
                                    <th>Amount (₹)</th>
                                    <th style={{ textAlign: 'center' }}>Preferential Duty Amount (₹)<br />
                                        <sub>{PTA()?.pta_name && (PTA()?.pta_name)}</sub>
                                    </th>
                                </tr >
                            </thead >
                            <tbody>
                                {rateList && rateList.map((item, index) => {
                                    return <>
                                        <tr key={index}>
                                            <td>{item.column_name}</td>
                                            <td>{item.val?.toFixed(2)}</td>
                                            <td style={{ textAlign: 'center' }}>{item?.pd_value?.toFixed(2)}</td>

                                        </tr>
                                    </>
                                })}
                                <tr className='theme-row'>
                                    <td >Total Custom Duty</td>
                                    <td>{calculateSum(rateList, 'val') ? calculateSum(rateList, 'val') : ''}</td>
                                    <td style={{ textAlign: 'center' }}>{calculateSum(rateList, 'pd_value') ? calculateSum(rateList, 'pd_value') : ''}</td>

                                </tr>
                            </tbody>
                        </table > : null}
                    {/* <br></br> */}
                    {isItemHide ?
                        <div className='row'>
                            <div className='col-lg-4'></div>
                            <div className='col-lg-4'>
                                <div class="col-md-4 offset-md-4 text-center vieww-box">
                                    {rateList.length ?
                                        <Button title="Current view will be exported to PDF" className='btn-theme pdf-button duty-button step1-hscodebox hs-code-finder insight-hscode-box btnmobhs' onClick={() => exportAsPDF('MyDIv')} variant="contained">Export to Pdf
                                            <svg style={{ marginLeft: "10px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                                <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                                <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                            </svg>
                                        </Button>
                                        : null}
                                </div>
                            </div>
                            <div className='col-lg-4'></div>
                        </div> : null}
                    <br></br>
                </div>
            </div>




        </React.Fragment>
    );
}
