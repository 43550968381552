

import React, { useState, useEffect, useCallback } from 'react';
import "../../static/css/pages/subscription.css";
import { showLoader, hideLoader } from "../../services/core/loader-service";
import { finalize } from "rxjs";
import "../../../lib/static/css/global/global.css";
import { getTrnxDetails } from '../../services/subscription-service';
import { useParams } from 'react-router-dom';
import done from "../../static/img/done.png";
import error from "../../static/img/error.png";

export const TranxDetails = () => {

    const [txnDetails, setTrnx] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        fetchTrnxData();
    }, []);

    const fetchTrnxData = () => {
        showLoader();

        let subscription = getTrnxDetails(id)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                setTrnx(response.data);
                // if (response.data?.payment_status?.toLowerCase() == 'success') {
                //     seoPaymentPage(response.data);
                // }
            });


    }

    // const seoPaymentPage = (paymentData) => {
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push({
    //         'event': 'purchaseComplete',
    //         'pricing_plan': paymentData.subscription_name,
    //         'formType': 'PaidPurchase',
    //         'formPosition': 'Footer',
    //         'isPaidPlan': paymentData.amount > 0 ? true : false
    //     });
    // }




    return (
        <React.Fragment>
            <div class="primary-panel success-content">
                <div class="text-section text-center">
                    {txnDetails?.payment_status?.toLowerCase() == 'success' ?
                        <img style={{ height: '110px', width: '110px' }} src={done} /> :
                        <img style={{ height: '130px', width: '110px' }} src={error} />
                    }
                    {txnDetails?.payment_status?.toLowerCase() == 'success' ?
                        <h1 style={{ color: 'green', marginTop: '8px' }}>Payment Successful</h1> :
                        <h1 style={{ color: 'red', marginTop: '-19px' }}>Payment Failed</h1>
                    }
                    <br></br>
                    <div className="primary-panel">

                        <div className="container">

                            <br />
                            <div  >
                                <p>Hi {txnDetails?.customer?.name},</p>
                                <p>
                                    Your Payment has  {txnDetails?.payment_status?.toLowerCase() == 'success' ? 'been successfully processed' : 'failed'} . Your Order Id is
                                    <strong style={{ fontWeight: '600' }}> &nbsp;&nbsp;{txnDetails?.order_id}.</strong>
                                </p>


                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-3" style={{ marginTop: "47px" }}>
                                            <h5 class="side-headings">Customer Name<br /></h5>
                                            <span className='trnx-span'>{txnDetails?.customer_name} </span><br /><br />
                                            <h5 class="side-headings">Email<br /></h5>
                                            <span className='trnx-span'>{txnDetails?.email}</span>
                                            <br /><br />
                                        </div>
                                        <div class="col-lg-3" style={{ marginTop: "47px" }}>
                                            <h5 class="side-headings">Mobile<br /></h5>
                                            <span className='trnx-span'>{txnDetails?.customer_mobile}</span>
                                            <br /><br />

                                            <h5 class="side-headings">Order Id<br /></h5>
                                            <span className='trnx-span'>{txnDetails?.order_id}</span>
                                            <br /><br />
                                        </div>

                                        <div
                                            class="col-lg-6"
                                            style={{ marginTop: "47px" }}
                                        >
                                            <div >
                                                <div class="left-side-details">Subscription Name</div>
                                                <div class="right-side-details">{txnDetails?.subscription_name}</div>
                                            </div><br />
                                            <div>
                                                {/* {JSON.stringify(txnDetails)} */}
                                                <div class="left-side-details blank">Payment Status</div>
                                                <div class="right-side-details blank" style={{ color: "green", textTransform: 'capitalize' }}>{txnDetails?.payment_status}</div>


                                            </div><br />
                                            <div style={{ backgroundColor: '#f8f8f8 !important' }}>
                                                <div class="left-side-details">Amount</div>
                                                <div class="right-side-details">{txnDetails?.amount}</div>
                                            </div><br />
                                            <div>
                                                <div class="left-side-details blank">Currency</div>
                                                <div class="right-side-details blank">{txnDetails?.currency}</div>
                                            </div><br />
                                            <div style={{ backgroundColor: '#f8f8f8 !important' }}>
                                                <div class="left-side-details">Transaction Date</div>
                                                <div class="right-side-details">{txnDetails?.payment_date ? txnDetails?.payment_date : '-'}</div>
                                            </div><br />
                                            <div>
                                                <div class="left-side-details blank">Valid Till</div>
                                                <div class="right-side-details blank">{txnDetails?.validity}</div>
                                            </div><br />
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}
