import React, { useState, useEffect, useCallback } from 'react';
import "../../../static/css/pages/users/user.css";
import '../../../../lib/static/css/modal/modal.module.css';
import Modal from '../../Common/Modal/Modal';
import { AddEditLookupPage } from "./AddEditLookup";
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import * as constantsType from "../../../utility/constants/common-constants";
import { ConfirmModal } from "../../Common/ConfirmModal/ConfirmModal";
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import * as moduleConstants from "../../../utility/constants/module-action-constants";
import * as actionConstants from "../../../utility/constants/module-action-constants";
import { PermissionSelector } from "../../../redux/permission/permission-reducer";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../../factory/helpers/permission-service";
import { DataTable } from "../../Common/DataTable/DataTable";
import { listLookup, deleteLookup } from "../../../services/lookup-service";
import { finalize } from "rxjs";
import Search from "../../Common/Search/Search";
import "../../../../lib/static/css/global/global.css";
import { LOOKUP_PAGE, MASTER_MANAGEMENT } from '../../../utility/constants/page-label-constants';
import { Breadcrumb } from '../../../components/Common/Breadcrumb/Breadcrumb';
import { listLookupType } from '../../../services/lookup-type-service';
import DropDown from '../../../utils/Filter/DropDown';

export const LookupPage = () => {

    const { permission } = useSelector(PermissionSelector);
    const [showDeletConfirmModal, setDeleteShowConfirmModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [selectedAction, setAction] = useState('Add');
    const [Lookup, setLookup] = useState([]);
    const [selectedLookup, setSelectedLookup] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortable, setSortable] = useState([]);
    const PER_PAGE = 10;
    const [pageCount, setPageCount] = useState(0);
    const [sort, SetSort] = useState(new Map())
    const [lookupType, setLookupType] = useState([]);


    useEffect(() => {
        getLookupTypeList('');
    }, [])

    useEffect(() => {
        showLoader();
        getLookupList('', currentPage);
    }, [currentPage]);



    const handlePageClick = (selectedObject) => {
        let selectedPage = selectedObject.selected + 1;
        setCurrentPage(selectedPage)
        showLoader();
    }


    const isShow = (actionName) => {
        let child = moduleConstants.LOOKUP;
        return hasPermission(permission, child, actionName)
    }


    const getLookupList = (params, selectedPage = 1) => {
        showLoader();
        let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE, _page: selectedPage, _page_size: PER_PAGE };
        let lookupubscription = listLookup({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                lookupubscription = null;
            }))
            .subscribe(response => {
                setLookup(response.data);
                setPageCount(response.count);
                setSortable(response.sortable);
            });
    }


    const onSearchHandler = useCallback((searchValue) => {
        let params = { _q: searchValue };
        getLookupList(params);
    }, [])

    const onHideModalHandler = () => {
        getLookupList('');
    }

    const onEditHandler = (action, rowData) => {
        setShowAddEditModal(true);
        setAction('Edit')
        setSelectedLookup(rowData);
    }

    const onAddHandler = (action, rowData) => {
        setShowAddEditModal(true);
        setAction('Add')
        setSelectedLookup(rowData);
    }

    const onDeleteHandler = (event, rowData) => {
        setDeleteShowConfirmModal(true);
        setSelectedLookup(rowData);
    }

    const applyDelete = (data) => {
        showLoader();
        const id = data['id'];
        let deleteSubscription = deleteLookup(id)
            .pipe(finalize(() => {
                hideLoader();
                deleteSubscription = null;
            }))
            .subscribe(response => {
                showSuccessToaster(response.message);
                getLookupList('');
            });
    }

    const onSortingChange = (params, key, sortBy) => {
        let sortPair = new Map()
        if (key && sortBy) {
            sortPair.set(key, sortBy)
            SetSort(sortPair);
        } else {
            SetSort(sortPair);
        }

        getLookupList(params);
    }

    const onFilterChange = (e) => {
        getLookupList({
            lookup_type_id: e.target.value
        });
    }

    const getLookupTypeList = (params, selectedPage = 1) => {
        let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE, _page: selectedPage, _page_size: 10000 };
        let lookupTypeubscription = listLookupType({ params: filter })
            .pipe(finalize(() => {
                lookupTypeubscription = null;
            }))
            .subscribe(response => {
                setLookupType(response.data);
            });
    }


    let headerConfig = [
        { colLabel: 'Id', colKey: 'id' },
        { colLabel: 'Name', colKey: 'name' },
        { colLabel: 'key', colKey: 'key' },
        { colLabel: 'Order', colKey: 'order' },
        { colLabel: 'Lookup Type Name', colKey: 'lookup_type_name' },
    ];

    let actionList = [
        { actionName: 'edit', faIcon: 'edit', hasPermission: isShow, callEvent: onEditHandler },
        { actionName: 'delete', faIcon: 'trash', hasPermission: isShow, callEvent: onDeleteHandler },
    ];


    const BreadcrumbConfig = [
        { label: MASTER_MANAGEMENT, link: null, highlight: true },
        { label: LOOKUP_PAGE, link: null, },
    ];



    return (
        <React.Fragment>

            <Breadcrumb breadcrumbList={BreadcrumbConfig} />

            <section className='users-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='title-buttons'>
                                <h2 className='title'>Lookup</h2>
                                <div className='buttons ml-auto'>
                                    <button style={{ display: isShow(actionConstants.ADD) ? 'block' : 'none' }} onClick={onAddHandler} type='button' className='btn btn-primary'>Add Lookup</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-2'>
                            <div className='form-inline'>
                                <DropDown
                                    itemlist={lookupType}
                                    onFilterChange={onFilterChange}
                                    itemLabelKey={'name'}
                                    filterKey={'id'}
                                />
                            </div>
                        </div>

                        <div className='col-md-5'>
                            <div className='form-inline'>

                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='search-group mt-2 mb-2'>
                                <Search onSearch={onSearchHandler} placeHolder={"Search..."} />
                            </div>
                        </div>
                    </div>

                    <DataTable rowData={Lookup} headerGroup={headerConfig}
                        totalPage={pageCount} onPageChange={handlePageClick}
                        actionGroup={actionList}
                        sortGroup={sortable}
                        sort={sort}
                        onSortingChange={onSortingChange}>
                    </DataTable>




                </div>
            </section>


            {showAddEditModal && <Modal show={showAddEditModal} onClose={() => setShowAddEditModal(false)} modal_title={`${selectedAction} Lookup`}>
                {<AddEditLookupPage action={selectedAction} closeModal={setShowAddEditModal} editmode={true} onHide={onHideModalHandler} initialData={{ 'data': (selectedLookup ? selectedLookup : {}) }} />}
            </Modal>}

            {showDeletConfirmModal && <Modal show={showDeletConfirmModal} onClose={() => setDeleteShowConfirmModal(false)} modal_title="Confirm">
                <ConfirmModal initialData={{
                    'data': selectedLookup,
                    'action': 'delete'
                }} onClose={applyDelete}
                    hideModal={setDeleteShowConfirmModal} />
            </Modal>}

        </React.Fragment>
    );
}
