import React from "react";
import "../../../static/css/global/buttons.css";

const Button = ({ children, onClick, ...props }) => {
    return (
        <button type="button" onClick={onClick} className={"btn "  + props.btnClass} {...props}>
            {children}
        </button>
    );
};

export default Button;
