import React from 'react';
import { showLoader, hideLoader } from "../../services/core/loader-service";
import { showSuccessToaster } from "../../utils/toaster/toaster";
import { updateProfile } from '../../services/user-service';
import { ForgotPasswordValidation, profileFormValidation } from '../../utils/schemas';
import { useFormik } from 'formik';
import { finalize } from 'rxjs';

export const UpdateProfilePage = (props) => {

    let initialValues = { first_name: '', last_name: '', email: '' };

    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: props.initialData.data || initialValues,
        validationSchema: profileFormValidation,
        enableReinitialize: true,
        onSubmit: (value) => {
            update(value)
        }
    })

    const update = (params) => {
        let payload = { ...params };
        showLoader();
        let subscription = updateProfile({ data: payload })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                showSuccessToaster(response.message);
                props.closeModal(false);
                props.onHide();
            });
    }


    return (


        <form onSubmit={handleSubmit}>

            <div className="input-container">
                <label>First Name </label>
                <input type="text" className="input-control" placeholder="Enter First Name" name="first_name" value={values.first_name} onChange={handleChange} />
                {errors.first_name && <p className='error'>{errors.first_name}</p>}
            </div>
            <div className="input-container">
                <label>Last Name </label>
                <input type="text" className="input-control" placeholder="Enter Last Name" name="last_name" value={values.last_name} onChange={handleChange} />
                {errors.last_name && <p className='error'>{errors.last_name}</p>}
            </div>

            <div className="input-container">
                <label>Mobile </label>
                <input type="text" className="input-control" placeholder="Ex. +9188**********" name="mobile_number" value={values.mobile_number} onChange={handleChange}
                />
                {errors.mobile_number && <p className='error'>{errors.mobile_number}</p>}
            </div>

            <div className="input-container">
                <label>Email </label>
                <input disabled type="text" className="input-control" placeholder="Enter Email" name="email" value={values.email} onChange={handleChange}
                    style={{ 'cursor': 'not-allowed' }} />
                {errors.email && <p className='error'>{errors.email}</p>}
            </div>


            <div className='input-container'>
                <button type="submit" className="btn btn-primary" >Update</button>&nbsp;
            </div>
        </form>
    );
}