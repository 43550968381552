import { Autocomplete, Button, Card, CardActionArea, CardContent, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { finalize } from 'rxjs';
import { hideLoader, showLoader } from '../../services/core/loader-service';
import { getCountries, getHsCode, getInsights, productSearch } from '../../services/duty-calc-service';
import * as constantsType from "../../utility/constants/common-constants";
import Modal from '../Common/Modal/Modal';
import { HsCodeModal } from '../Common/HsCode/HsCodeModal';
import { showWarnToaster } from '../../utils/toaster/toaster';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../../static/icon/logo.svg";
import logoInvert from "../../static/img/logo-invert.png";
import { lastestSubscription } from '../../services/subscription-service';

export const DashboardPage = () => {

  let pdf = new jsPDF("l", "cm", "a4");
  const [analyticsData, setAnalyticsData] = useState([]);
  let [hsCodes, setHsCodeList] = useState([]);
  let [countries, setcountryList] = useState([]);
  let [products, setProductList] = useState([]);
  const [selectedHsCode, setSelectedHsCode] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [showHsCodeModal, setShowHsCodeModal] = useState(false);
  const [isPdfShow, setPdfShow] = useState(false);
  const [isItemHide, setItemHide] = useState(true);
  const [lastestSub, setLatestSubscription] = useState([]);

  useEffect(() => {
    onCountryChange();
    getLastestSubscription();

  }, []);



  const tempHideItems = () => {
    if (isPdfShow == true) {
      setItemHide(false);
      setTimeout(() => {
        setItemHide(true)
      }, 2000)
    }
  }


  const getAnalytics = () => {
    showLoader();

    let filter = {
      hs_code: selectedHsCode,
      country: selectedCountry,
      product: selectedProduct
    }
    let subscription = getInsights(filter)
      .pipe(finalize(() => {
        hideLoader();
        subscription = null;
      }))
      .subscribe(response => {
        if (!response) return;
        setAnalyticsData(response.data);
        getLastestSubscription();
      });
  }

  const fetchProducts = (query) => {
    showLoader();

    let filter = { q: query, app_source: constantsType.ADMIN_APP_SOURCE };
    let subscription = productSearch({ params: filter })
      .pipe(finalize(() => {
        hideLoader();
        subscription = null;
      }))
      .subscribe(response => {
        if (!response) return;
        let productList = response.results;
        setProductList(productList);

      });
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, 600);
    }
  }
  const handleChange = (value) => {
    if (value) {
      fetchHsCode(value);
    }
  }

  const onProductSearch = (event) => {

    // if (event.target.value) {
    setSelectedProduct(event.target.value);
    // }
  }

  const fetchHsCode = (query) => {
    showLoader();

    let filter = {
      hs_code: query,
      app_source: constantsType.ADMIN_APP_SOURCE,
      page: 1,
      _page_size: 300,
    };



    let subscription = getHsCode({ params: filter })
      .pipe(finalize(() => {
        hideLoader();
        subscription = null;
      }))
      .subscribe(response => {
        if (!response) return;
        let hsCodeList = response.results;
        hsCodeList.forEach((list) => {
          list['label'] = list.code;
        });
        setHsCodeList(hsCodeList);

      });
  };

  const onCountryChange = (params = {}) => {
    showLoader();

    let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE };
    let subscription = getCountries({ params: filter })
      .pipe(finalize(() => {
        hideLoader();
        subscription = null;
      }))
      .subscribe(response => {
        if (!response) return;
        let countryList = response.results;
        countryList.forEach((list) => {
          list['label'] = list.name;
        })
        setcountryList(countryList);

      });
  };

  const optimisedVersion = useCallback(debounce(handleChange), []);
  const optimisedVersionOfProduct = useCallback(debounce(onProductSearch), []);

  const onHsCodeModalDismiss = (res) => {
    if (Array.isArray(res)) {
      setSelectedHsCode(res.length ? res.pop() : '')
    }
  }

  const onHsCodeHandler = () => {
    setShowHsCodeModal(true);
  }

  const generateInsight = () => {
    if (selectedHsCode || selectedProduct) {
      setPdfShow(true)
      getAnalytics();
    } else {
      showWarnToaster('Please Select Hs Code or Product to View Insights')
    }
  }

  let exportAsPDF = (div_id) => {
    showLoader();
    tempHideItems();
    setTimeout(() => {
      let data = document.getElementById(div_id);
      const quality = 2;
      html2canvas(data, { scale: quality }).then((canvas) => {
        const contentDataURL = canvas.toDataURL("image/png");
        pdf.setFontSize(30);
        pdf.addImage(contentDataURL, "PNG", 1, 0.4, 26.7, 19.0);
        pdf.save("Insight Report.pdf");
        hideLoader();
      });
    }, 2000);
  }

  const getLastestSubscription = (params) => {
    showLoader();

    let filter = { ...params };
    let subscriptionListubscription = lastestSubscription({ params: filter })
      .pipe(finalize(() => {
        hideLoader();
        subscriptionListubscription = null;
      }))
      .subscribe(response => {
        if (!response) return;
        setLatestSubscription(response.data[0]);

      });
  }

  return (

    <section className={'profile-wrapper ' + !isItemHide ? 'padt0' : ''} id="MyDIv" style={{ position: "relative" }}>
      {!isItemHide ?
        <span className='watermark'>
          <img src={logoInvert} />
        </span>
        : null
      }
      <div className='container-fluid'>
        {!isItemHide ? <>
          <div className="row">
            <div className="col-md-12 text-center" >
              <span className='dashboard-header-logo' style={{ textAlign: "center" }}>
                <img src={logoInvert} style={{ width: "240px" }} />
              </span>
            </div>

          </div>
          <br></br>
        </> : null}

        <div className='row'>
          <div className='col-lg-12'>
            <div className='title-buttons'>
              <h2 className='title'>Insights </h2>
              <div className='buttons ml-auto'>

              </div>
            </div>
          </div>
        </div>
        <div className="primary-panel">
          <div>
            <div style={{ float: 'right' }}>
              <b className='theme-sub-statement'>Balance Insights</b> &nbsp;&nbsp;: &nbsp;&nbsp; 
              <span>{lastestSub?.balance_analytics_limit ? lastestSub?.balance_analytics_limit : 'Unlimited'}</span>
            </div>
          </div>


          <div className="container">
            {/* {!isItemHide ? */}

            {/* : null} */}

            <div className="row">

              <div className="col-md-4">
                {
                  <>
                    <h4 className='mbb-20 insight-selectbox-label'>Hs Code <sup className='sup-madatory'>*</sup></h4>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={hsCodes}
                      value={selectedHsCode}
                      onChange={(event, newValue) => {
                        let selectedValue = newValue ? newValue.code : "";
                        setSelectedHsCode(selectedValue);

                      }}
                      onInputChange={(event, newInputValue) => {
                        optimisedVersion(newInputValue);
                      }}
                      sx={{ marginBottom: '15px' }}
                      renderInput={(params) => <TextField {...params} label="Select Hs Code" />}
                    />
                  </>
                }

              </div>
              <div className="col-md-3">
                <Button className='btn-theme btn-block duty-button step1-hscodebox hs-code-finder insight-hscode-box btnmobhs' onClick={() => onHsCodeHandler()} variant="contained">HS CODE FINDER</Button>
              </div>
              <div className='col-lg-4'>

              </div>
              {/* <div className='row'>
                <div className='col-lg-12'>
                  <p style={{ height: '120px' }}></p>
                </div>
              </div> */}
              <div className="col-lg-4">
                {
                  <>
                    <h4 className='mbb-20 insight-selectbox-label lh'>Product <sup className='sup-madatory'>*</sup></h4>
                    <input className='input-control input-box' placeholder='Product Name' onChange={(event) => {
                      optimisedVersionOfProduct(event);
                    }}></input>
                    {/* w-86 insight-product-box */}
                  </>
                }
              </div>
              <div className="col-lg-4">
                {
                  <>
                    <h4 className='mbb-20 insight-selectbox-label res-mt1 lh'>Country </h4>
                    <Autocomplete
                      className=''
                      disablePortal
                      id="combo-box-demo"
                      options={countries}
                      sx={{}}
                      value={selectedCountry}
                      onChange={(event, newValue) => {
                        let selectedValue = newValue ? newValue.name : "";
                        setSelectedCountry(selectedValue)
                      }}

                      renderInput={(params) => <TextField {...params} label="Select Country" />}
                    />
                  </>
                }
              </div>
              <div className='col-lg-3'>
                {isItemHide ?
                  <Button className='btn-theme insigt-gen btn-block' onClick={() => generateInsight()} variant="contained">Generate Insights</Button>
                  : null}
              </div>
              <br></br><br></br>
              <div className='col-lg-3'>
              </div>
              <div className='col-lg-5'>
                {/* <Button className='btn-theme duty-button insight-gen-btn' onClick={() => generateInsight()} variant="contained">Generate Insights</Button> */}
              </div>
              <div className='col-lg-4'>
              </div>
            </div>
            <br /><br />
            {Object.entries(analyticsData).length ? <>
              <div className="row widget-container">

                <div className={!isItemHide ? 'col-lg-4 dashborder' : "col-lg-4"}>
                  <Card sx={{ maxWidth: 345 }} className="insight-card dashcard">
                    <CardActionArea>

                      <CardContent>
                        <Typography className='insight-card-heading' gutterBottom variant="h5" component="div">
                          Top Indian Ports <i title='Top 5 Indian ports of Imports' className="fa fa-info-circle info-icon" style={{ color: 'white !important', fontSize: '15px', marginLeft: '10px' }} aria-hidden="true"></i>
                        </Typography>


                      </CardContent>
                    </CardActionArea>
                    <CardContent>
                      {analyticsData.top_indian_ports.length ?
                        <>
                          {analyticsData.top_indian_ports &&
                            analyticsData.top_indian_ports.map((data) => {
                              return <>
                                <Typography variant="body2" color="text.secondary">
                                  {data.value}<br />
                                </Typography>
                              </>
                            })}
                        </> : 'No Data Found'}
                    </CardContent>
                  </Card>
                </div>
                <div className={!isItemHide ? 'col-lg-4 dashborder' : "col-lg-4"}>
                  <Card sx={{ maxWidth: 345 }} className="insight-card dashcard">
                    <CardActionArea>

                      <CardContent>
                        <Typography className='insight-card-heading' gutterBottom variant="h5" component="div">
                          Top Foreign Countries <i title='Top 5 countries from where HS code/product can be imported' className="fa fa-info-circle info-icon" style={{ color: 'black', fontSize: '15px', marginLeft: '10px' }} aria-hidden="true"></i>
                        </Typography>
                      </CardContent>

                    </CardActionArea>
                    <CardContent>
                      {analyticsData.top_foreign_countries.length ?
                        <>
                          {analyticsData.top_foreign_countries &&
                            analyticsData.top_foreign_countries.map((data) => {
                              return <>
                                <Typography variant="body2" color="text.secondary">
                                  {data.value}<br />
                                </Typography>
                              </>
                            })}
                        </> : 'No Data Found'}
                    </CardContent>

                  </Card>
                </div>
                <div className={!isItemHide ? 'col-lg-4 dashborder' : "col-lg-4"}>
                  <Card sx={{ maxWidth: 345 }} className="insight-card dashcard">
                    <CardActionArea>

                      <CardContent>
                        <Typography className='insight-card-heading' gutterBottom variant="h5" component="div">
                          Top HS Codes <i title='Top 5 HS Codes for your product' className="fa fa-info-circle info-icon" style={{ color: 'black', fontSize: '15px', marginLeft: '10px' }} aria-hidden="true"></i><br></br>
                          {!selectedProduct ? <sub className='sub-heading-insight'>( To view this section, please enter product )</sub> : null}

                        </Typography>


                      </CardContent>
                    </CardActionArea>
                    <CardContent>
                      {analyticsData.top_hs_codes.length ?
                        <>
                          {analyticsData.top_hs_codes &&
                            analyticsData.top_hs_codes.map((data) => {
                              return <>
                                <Typography variant="body2" color="text.secondary">
                                  {data.value}<br />
                                </Typography>
                              </>
                            })}

                        </> : 'No Data Found'}
                    </CardContent>
                  </Card>
                </div>



                <div className={!isItemHide ? 'col-lg-4 dashborder' : "col-lg-4"}>
                  <Card sx={{ maxWidth: 345 }} className="insight-card dashcard">
                    <CardActionArea>

                      <CardContent>
                        <Typography className='insight-card-heading' gutterBottom variant="h5" component="div">
                          <i title='Top 5 relevant Exemption notifications claimed in last 6 months for chosen Hs Code & Country' className="fa fa-info-circle info-icon" style={{ color: 'black', fontSize: '15px', marginLeft: '10px' }} aria-hidden="true"></i>
                          Top Relevant Exemption Notifications<br></br>
                          {analyticsData.top_bcd_notification.length ? <sub className='sub-heading-insight'>{analyticsData.bcd_notification_message}</sub> : null}
                        </Typography>


                      </CardContent>
                    </CardActionArea>
                    <CardContent>
                      {analyticsData.top_bcd_notification.length ?
                        <>
                          {
                            analyticsData.top_bcd_notification &&
                            analyticsData.top_bcd_notification.map((data) => {
                              return <>
                                <Typography variant="body2" color="text.secondary">
                                  {data.value}<br />
                                </Typography>
                              </>
                            })
                          }
                        </> : 'No Data Found'}

                    </CardContent>
                  </Card>
                </div>
                <div className='col-lg-4'></div>
                <div className='col-lg-4'></div>
              </div>
            </> : null}

          </div>
          <br />
          {isItemHide ?
            <div className='row'>
              {/* <div className='col-lg-4'></div> */}

              <div className="col-md-3 m-auto text-center vieww-box">
                {isPdfShow ?
                  <Button title="Current view will be exported to PDF" className='btn-theme pdf-button duty-button step1-hscodebox hs-code-finder insight-hscode-box btnmobhs' onClick={() => exportAsPDF('MyDIv')} variant="contained">Export to Pdf
                    <svg style={{ marginLeft: "10px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                      <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                      <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                    </svg>
                  </Button>
                  // <button title="Current view will be exported to PDF" className="btn-viewdetails" onClick={() => exportAsPDF('MyDIv')}>
                  //   Export to Pdf
                  //   <img title="Current view will be exported to PDF" class="pdf-svg" src="https://e7.pngegg.com/pngimages/91/886/png-clipart-adobe-acrobat-pdf-adobe-reader-document-adobe-flash-pdf-blue-text.png" />
                  // </button> 
                  : null}
              </div>

            </div> : null}

            <br></br>

          {/* <span style={{ float: 'right' }}>* Above data is based on last 6 months Imports</span> */}
          <br />
        </div >
      </div >
      {showHsCodeModal && <Modal modalStyle={{
        height: '560px'
      }} show={showHsCodeModal} onClose={() => setShowHsCodeModal(false)} modal_title="Hs Code Finder">
        <HsCodeModal initialData={{
          'data': hsCodes,
        }} onClose={onHsCodeModalDismiss}
          hideModal={setShowHsCodeModal} />
      </Modal>}
    </section >

  );
}