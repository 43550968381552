import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Button, TextField } from '@mui/material';
import * as constantsType from "../../../utility/constants/common-constants";
import { getCountries, getHsCode, getPartnerCode } from '../../../services/duty-calc-service';
import { hideLoader, showLoader } from '../../../services/core/loader-service';
import { finalize } from 'rxjs';
import { HsCodeModal } from '../../Common/HsCode/HsCodeModal';
import Modal from '../../Common/Modal/Modal';
import { ProductSearchModal } from '../../Common/Product/ProductModal';
import { PTAModal } from '../../Common/PTA/PTAModal';
import { showWarnToaster } from '../../../utils/toaster/toaster';
import { lastestSubscription } from '../../../services/subscription-service';


export const Step1 = () => {

    let [hsCodes, setHsCodeList] = useState([]);
    let [countries, setcountryList] = useState([]);
    const [showHsCodeModal, setShowHsCodeModal] = useState(false);
    const [showProductSearchModal, setShowProductSearchModal] = useState(false);
    const [showPTAModal, setShowPTAModal] = useState(false);
    const [selectedHsCode, setSelectedHsCode] = useState(localStorage.getItem('selectedHsCode'));
    const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('selectedCountry'));
    const [lastestSub, setLatestSubscription] = useState({});

    useEffect(() => {
        onCountryChange();
        getLastestSubscription();
        fetchHsCode('');
    }, [])

    var getPreservePTAData = () => {
        let data = localStorage.getItem("ptaData");
        return (data) ? JSON.parse(data) : {};
    }

    let [selectedPTAData, setPTAData] = useState(getPreservePTAData());

    const fetchHsCode = (query) => {
        showLoader();

        let filter = {

            hs_code: query,
            app_source: constantsType.ADMIN_APP_SOURCE,
            page: 1,
            _page_size: 300,
            level: 8,
            value_exists: true
        };

        let subscription = getHsCode({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let hsCodeList = response.results;
                hsCodeList.forEach((list) => {
                    list['label'] = list.code;
                });
                setHsCodeList(hsCodeList);

            });
    };

    const onCountryChange = (params = {}) => {
        showLoader();

        let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE };
        let subscription = getCountries({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let countryList = response.results;
                countryList.forEach((list) => {
                    list['label'] = list.name;
                })
                setcountryList(countryList);

            });
    };

    const getLastestSubscription = (params) => {
        showLoader();

        let filter = { ...params };
        let subscriptionListubscription = lastestSubscription({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscriptionListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                setLatestSubscription(response.data.length ? response.data[0] : {});

            });
    }

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 600);
        }
    }
    const handleChange = (value) => {
        if (value) {
            fetchHsCode(value);
        }
    }

    const optimisedVersion = useCallback(debounce(handleChange), []);


    const onHsCodeHandler = () => {
        setShowHsCodeModal(true);
    }

    const onHsCodeModalDismiss = (res) => {
        if (Array.isArray(res)) {
            setSelectedHsCode(res.length ? res.pop() : '')
        }
    }


    const onProductSearchHandler = () => {
        setShowProductSearchModal(true);
    }

    const onPTAHandler = () => {
        if (!selectedHsCode || !selectedCountry) {
            showWarnToaster("Please Select Hs code and Country");
            return
        }

        fetchPartnerCode();
    }

    const fetchPartnerCode = () => {
        showLoader();
        let subscription = getPartnerCode(selectedCountry)
            .pipe(finalize(() => {

                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                setShowPTAModal(true);
                
            })
    };

    const onProductSearchModalDismiss = (res) => {
        console.info(res, "res");
    }

    const onPTAModalDismiss = (res) => {
        console.info(res, "res");
        if (res) {
            localStorage.setItem("ptaData", JSON.stringify(res))
            setPTAData(res)
        }
    }

    const clearPTA = () => {
        localStorage.removeItem("ptaData")
        setPTAData(null);
    }


    return <>

        <div className="primary-panel">
            <br /><br />
            <div className="container">
                <div className="row" data-tut="reactour__goTo2">
                    <div className="col-md-5">
                        {
                            <Autocomplete
                                className='mb-1'
                                disablePortal
                                id="combo-box-demo"
                                options={hsCodes}
                                value={selectedHsCode}
                                onChange={(event, newValue) => {
                                    let selectedValue = newValue ? newValue.code : "";
                                    setSelectedHsCode(selectedValue);
                                    localStorage.setItem('selectedHsCode', selectedValue);

                                }}
                                onInputChange={(event, newInputValue) => {
                                    optimisedVersion(newInputValue);
                                }}
                                sx={{}}
                                renderInput={(params) => <TextField {...params} label="Select Hs Code" />}
                            />
                        }
                    </div>

                    <div className="col-md-4">
                        <Button className='btn-theme duty-button step1-hscodebox hs-code-finder mt-1 btn-block' onClick={() => onHsCodeHandler()} variant="contained">HS CODE FINDER</Button>
                    </div>

                    {/* <div className="col-lg-1">
                        <span style={{ marginLeft: "-90px", }}>Or</span>
                    </div>
                    <div className="col-lg-2">
                        <Button className='btn-theme duty-button ml-30 step1-hscodebox' onClick={() => onProductSearchHandler()} variant="contained"
                        >Product</Button>
                    </div> */}
                </div>

                <br />
                <div className="row" data-tut="reactour__goTo3">
                    <div className="col-md-5">
                        <Autocomplete
                            className='mb-1'
                            disablePortal
                            id="combo-box-demo"
                            options={countries}
                            sx={{}}
                            value={selectedCountry}
                            onChange={(event, newValue) => {
                                let selectedValue = newValue ? newValue.name : "";
                                localStorage.setItem('selectedCountry', selectedValue);
                                setSelectedCountry(selectedValue)


                            }}

                            renderInput={(params) => <TextField {...params} label="Select Country *" />}
                        />


                    </div>
                    <div className="col-md-4">
                        {
                            // (lastestSub?.has_pta_data_access)
                            //     ?
                                <Button className='btn-theme pta-button ml-0  btn-block' variant="contained" onClick={() => onPTAHandler()} >SELECT TRADE AGREEMENT</Button>
                                // :
                                // <Button className='disable-theme-btn pta-button ml-0  btn-block' style={{ background: '#98d4d3 !important', cursor: 'not-allowed' }} variant="contained"  >SELECT TRADE AGREEMENT</Button>
                        }
                        {selectedPTAData?.pta_name ?
                            <> <label className='slectedpta'>
                                <i className="fa fa-times closepta" onClick={() => clearPTA()} ></i>
                                {selectedPTAData?.pta_name}</label>
                                {/* <input title={selectedPTAData?.pta_name} disabled value={selectedPTAData?.pta_name} className="input-control pta-input selected-pta selected-pta" /> */}
                            </> : null
                        }
                    </div>
                </div>
                <br />
                <div className='row' style={{ display: 'none' }}>
                    {/* <div className="col-md-5"> */}
                    {/* {selectedPTAData?.pta_name && <input title={selectedPTAData?.pta_name} disabled value={selectedPTAData?.pta_name} className="input-control pta-input selected-pta selected-pta newselectedpta" />} */}
                    <div className="col-md-5">
                        {selectedPTAData?.pta_name ?
                            <> <label className='slectedpta'>
                                <i className="fa fa-times" onClick={() => clearPTA()} style={{ marginLeft: '308px', position: 'absolute' }}></i>
                                {selectedPTAData?.pta_name}</label>
                                {/* <input title={selectedPTAData?.pta_name} disabled value={selectedPTAData?.pta_name} className="input-control pta-input selected-pta selected-pta" /> */}
                            </> : null
                        }


                    </div>

                </div>
                <br /> <br />
                <div className='msgleft'>* Select Trade Agreement to see Prefential duties</div>
                <br />
            </div>
        </div >

        {showHsCodeModal && <Modal modalStyle={{
            height: '560px'
        }} show={showHsCodeModal} onClose={() => setShowHsCodeModal(false)} modal_title="Hs Code Finder">
            <HsCodeModal initialData={{
                'data': hsCodes,
                "hs_code_filter": {
                    level: 8,
                    value_exists: true,
                }
            }} onClose={onHsCodeModalDismiss}
                hideModal={setShowHsCodeModal} />
        </Modal>}

        {
            showProductSearchModal && <Modal show={showProductSearchModal} onClose={() => setShowProductSearchModal(false)} modal_title="Product Locator">
                <ProductSearchModal initialData={{
                    'data': hsCodes,
                }} onClose={onProductSearchModalDismiss}
                    hideModal={setShowProductSearchModal} />
            </Modal>
        }

        {
            showPTAModal && <Modal show={showPTAModal} onClose={() => setShowPTAModal(false)} modal_title="Select Trade Agreement">
                <PTAModal initialData={{
                    'data': hsCodes,
                    country: selectedCountry,
                    hs_code: selectedHsCode
                }} onClose={onPTAModalDismiss}
                    hideModal={setShowPTAModal} />
            </Modal>
        }
    </>

}