

import React, { useState, useEffect, useCallback } from 'react';
import "../../static/css/pages/users/user.css";
import '../../../lib/static/css/modal/modal.module.css';
import Modal from '../Common/Modal/Modal';
import { showLoader, hideLoader } from "../../services/core/loader-service";
import { ConfirmModal } from "../Common/ConfirmModal/ConfirmModal";
import * as constantsType from "../../utility/constants/common-constants";
import { showSuccessToaster } from "../../utils/toaster/toaster";
import { DataTable } from "../Common/DataTable/DataTable";
import { actiavteUser, deleteUser, listUsers } from "../../services/user-service";
import { finalize } from "rxjs";
import Search from "../Common/Search/Search";
import "../../../lib/static/css/global/global.css";
import { USER_MANAGEMENT, USER_PAGE } from '../../utility/constants/page-label-constants';
import { Breadcrumb } from '../Common/Breadcrumb/Breadcrumb';
import { AddEditSubscriptionPage } from './AddEditSubscription';
import { lastestSubscription, listSubscription } from '../../services/subscription-service';
import Button from '@mui/material/Button';
import { SUBSCRIPTION_PLAN_PAGE } from '../../utility/constants/page-route-constants';

export const SubscriptionHistory = () => {

    const [showDeletConfirmModal, setDeleteShowConfirmModal] = useState(false);
    const [showActivateConfirmModal, setActivateShowConfirmModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [selectedAction, setAction] = useState('Add');
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [lastestSub, setLatestSubscription] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [sortable, setSortable] = useState([]);
    const [sort, SetSort] = useState(new Map())
    const PER_PAGE = 10;

    useEffect(() => {
        getSubscriptionList('', currentPage);
        getLastestSubscription('', currentPage);
    }, [currentPage]);

    const handlePageClick = (selectedObject) => {
        let selectedPage = selectedObject.selected + 1;
        setCurrentPage(selectedPage)
    }

    const getSubscriptionList = (params, selectedPage = 1) => {
        showLoader();

        let filter = { ...params, app_source: constantsType.ADMIN_APP_SOURCE, page: selectedPage, _page_size: PER_PAGE };
        let subscriptionListubscription = listSubscription({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscriptionListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                setSubscriptionList(response.results);
                setPageCount(response.count);
                setSortable(['first_name', 'last_name', 'email', 'mobile_number']);
            });
    }

    const getLastestSubscription = (params) => {
        showLoader();

        let filter = { ...params };
        let subscriptionListubscription = lastestSubscription({ params: filter })
            .pipe(finalize(() => {
                hideLoader();
                subscriptionListubscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                setLatestSubscription(response.data[0]);

            });
    }



    const onSearchHandler = useCallback((searchValue) => {
        let params = { _q: searchValue };
        getSubscriptionList(params);
    }, []);




    const onSortingChange = (params, key, sortBy) => {
        let sortPair = new Map()
        if (key && sortBy) {
            sortPair.set(key, sortBy)
            SetSort(sortPair);
        } else {
            SetSort(sortPair);
        }

        getSubscriptionList(params);
    }



    let headerConfig = [
        { colLabel: 'Date', colKey: 'created_at' },
        { colLabel: 'Subscription Name', colKey: 'subscription_name' },
        { colLabel: 'No of Users', colKey: 'no_of_users' },
        { colLabel: 'Alerts Count', colKey: 'alerts_count' },
        { colLabel: 'Validity', colKey: 'validity' },
    ];


    return (
        <React.Fragment>
            <br /><br />
            <div className='title-buttons'>
                <h2 className='subcriptionlabel' >&nbsp; &nbsp; Subscription Status</h2>
            </div>

            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <label style={{ color: 'black', fontWeight: '500' }} >&nbsp;Current Subscription</label>
                        <input disabled type="text" className='input-control sub-input' placeholder='Subscription Name' value={lastestSub?.subscription_name}></input>
                    </div>
                    <div className='col-lg-4'>
                        <label style={{ color: 'black', fontWeight: '500' }} >&nbsp;No of Sub Users</label>
                        <input disabled type="text" className='input-control sub-input' placeholder='No of Sub Users' value={lastestSub?.no_of_users}></input>
                    </div>
                    <div className='col-lg-4'>
                        <label style={{ color: 'black', fontWeight: '500' }} >&nbsp;Validity</label>
                        <input disabled type="text" className='input-control sub-input' placeholder='Validity' value={lastestSub?.validity}></input>
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-4'>
                        <label style={{ color: 'black', fontWeight: '500' }} >&nbsp;Alerts Count</label>
                        <input disabled type="text" className='input-control sub-input' placeholder='Alerts Count' value={lastestSub?.alerts_count}></input>
                    </div>
                    <div className='col-lg-4'>
                        <label style={{ color: 'black', fontWeight: '500' }} >&nbsp;Balance Alerts</label>
                        <input disabled type="text" className='input-control sub-input' placeholder='Balance Alerts' value={lastestSub?.balance_alerts}></input>
                    </div>

                    <div className='col-lg-4'>
                        <label style={{ color: 'black', fontWeight: '500' }} >&nbsp;Insights</label>
                        <input disabled type="text" className='input-control sub-input' placeholder='Insights' value={lastestSub?.analytics ? 'Yes' : 'No'}></input>
                    </div>


                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <label style={{ color: 'black', fontWeight: '500' }} >&nbsp;PTA Data Access</label>
                        <input disabled type="text" className='input-control sub-input' placeholder='Pta Data Access' value={lastestSub?.has_pta_data_access ? 'Yes' : 'No'}></input>
                    </div>
                    <div className='col-lg-4'>
                        <label style={{ color: 'black', fontWeight: '500' }} >&nbsp;Query Limit</label>
                        <input disabled type="text" className='input-control sub-input' placeholder='Query Limit' value={lastestSub?.queries_limit}></input>
                    </div>
                    <div className='col-lg-4'>
                        <label style={{ color: 'black', fontWeight: '500' }} >&nbsp;Balance Query</label>
                        <input disabled type="text" className='input-control sub-input' placeholder='Balance Query' value={lastestSub?.balance_query_limit}></input>
                    </div>


                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <label style={{ color: 'black', fontWeight: '500' }} >&nbsp;Insights Limit</label>
                        <input disabled type="text" className='input-control sub-input' placeholder='Insights Limit' value={lastestSub?.analytics_limit ? lastestSub?.analytics_limit : 'Unlimited'}></input>
                    </div>
                    <div className='col-lg-4'>
                        <label style={{ color: 'black', fontWeight: '500' }} >&nbsp;Balance Insights</label>
                        <input disabled type="text" className='input-control sub-input' placeholder='Balance Insights' value={lastestSub?.balance_analytics_limit ? lastestSub?.balance_analytics_limit : 'Unlimited'}></input>
                    </div>
                     


                </div>
            </div>
            <br /><br />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-4'></div>
                    <div className='col-lg-4'>
                        <Button className='btn-block' variant="contained" href={SUBSCRIPTION_PLAN_PAGE} style={{ background: 'var(--special-color)' }}>
                            Renew / Upgrade Subscription
                        </Button>
                    </div>
                    <div className='col-lg-4'></div>
                </div>
            </div>

            <section className='users-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-8'>
                            <div className='title-buttons'>
                                <h2 >&nbsp; Subscription History</h2>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='search-group  '>
                                <Search onSearch={onSearchHandler} placeHolder={"Search..."} />
                            </div>
                        </div>

                    </div>

                    <DataTable rowData={subscriptionList} headerGroup={headerConfig}
                        totalPage={pageCount} onPageChange={handlePageClick}
                        sortGroup={sortable}
                        sort={sort}
                        onSortingChange={onSortingChange}
                    >
                    </DataTable>

                </div>
            </section>







        </React.Fragment >
    );
}
