import React, { Component } from "react";
import PropTypes from "prop-types";

import AccordionSection from "./AccordionSection";

class Accordion extends Component {
    static propTypes = {
        allowMultipleOpen: PropTypes.bool,
        children: PropTypes.instanceOf(Object).isRequired,
    };

    static defaultProps = {
        allowMultipleOpen: false,
    };

    constructor(props) {
        super(props);

        const openSections = {};

        this.props.children.forEach(child => {
            if (child.props.isOpen) {
                openSections[child.props.label] = true;
            }
        });

        this.state = { openSections };
    }

    onClick = label => {
        const { props: { allowMultipleOpen }, state: { openSections } } = this;

        const isOpen = !!openSections[label];

        if (allowMultipleOpen) {
            this.setState({
                openSections: {
                    ...openSections,
                    [label]: !isOpen
                }
            });
        } else {
            this.setState({
                openSections: {
                    [label]: !isOpen
                }
            });
        }
    };

    render() {
        const {
            onClick,
            props: { children },
            state: { openSections },
        } = this;

        return (
            <div style={{ border: "1px solid #ccc" }}>
                {children.map((child, key) => (
                    <AccordionSection
                        // isOpen={!!openSections[child.props.label]}
                        isOpen={true}
                        label={child.props.label}
                        onClick={onClick}
                        key={key}
                    >
                        {child.props.children}
                    </AccordionSection>
                ))}
            </div>
        );
    }
}

export default Accordion;