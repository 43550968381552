import React, { useState, useEffect, useCallback } from 'react';
import "../../../static/css/pages/users/user.css";
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { finalize } from "rxjs";
import "../../../../lib/static/css/global/global.css";
import { getCustomDutyRates, getNtmData } from '../../../services/duty-calc-service';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { DotLoader } from '../../Common/Loader/DotLoader';
import { Skeleton } from '@mui/material';

export const RegulatoryRequirement = () => {

    let initData = {
        hsRevision: '',
        ntmYear: '',
        dataSource: '',
        ntmClassification: ''
    }
    const [allProductList, SetAllProductList] = useState(null);
    const [thisProductList, SetThisProductList] = useState(null);
    const [npmMesureData, SetNpmMeasureData] = useState(initData);

    useEffect(() => {
        if (localStorage.getItem('selectedCountry')) {
            fetchNtmData();
        } else {
            SetThisProductList([])
        }

    }, [])

    const fetchNtmData = (params = {},) => {
        
        let hscode = localStorage.getItem('selectedHsCode');
        let pCode = localStorage.getItem("partnerCode");
        if(!pCode){
            SetThisProductList([])
            return
        }
        showLoader();

        let subscription = getNtmData(hscode, pCode)
            .pipe(finalize(() => {
                hideLoader();
                subscription = null;
            }))
            .subscribe(response => {
                if (!response) return;
                let AList = Object.entries(response.data).length ? response.data?.measures['All products'] : {};
                let TList = Object.entries(response.data).length ? response.data?.measures['Product specific'] : {};

                SetAllProductList(AList);
                SetThisProductList(TList);

                if (Object.entries(response.data).length) {
                    SetNpmMeasureData({
                        hsRevision: response.data.hsRevision,
                        ntmYear: response.data.ntmYear,
                        dataSource: response.data.dataSource,
                        ntmClassification: response.data.ntmClassification
                    })
                }


            });
    };




    return (
        <React.Fragment>

            <h4 className='theme-statement'>Regulatory Requirements</h4><br></br>
            For Product: <b> {localStorage.getItem('selectedHsCode')}</b> <br></br>
            HS Revision: <b> {npmMesureData.hsRevision} <br></br></b >
            NTM Classification: <b> {npmMesureData.ntmClassification} <br></br></b >
            NTM Year: <b> {npmMesureData.ntmYear} <br></br></b >
            Source:  <b> {"ITC (International Trade Centre)"} <br></br></b >
            <br></br>

            {thisProductList ?
                <>
                    {localStorage.getItem('partnerCode') ?
                        <>

                            {
                                Object.entries(thisProductList).length ?
                                    <>
                                        <>
                                            <Accordion className='border-line'>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <>
                                                            <span style={{ color: 'white' }}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-angle-down" style={{ color: 'white' }} aria-hidden="true"></i>
                                                            </span>
                                                        </>}
                                                    className='theme-widget '
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <span style={{ paddingRight: '15px' }}> {thisProductList?.title}</span>
                                                    <span style={{ marginLeft: 'auto' }}>Total Measures : {Object.entries(thisProductList?.measures).length}</span>

                                                </AccordionSummary>
                                                <AccordionDetails  >

                                                    {Object.entries(thisProductList?.measures).map(([key, value]) => {
                                                        return <>
                                                            <br></br>
                                                            <Accordion className='border-line'>
                                                                <AccordionSummary
                                                                    expandIcon={
                                                                        <>
                                                                            <span style={{ color: 'white' }}>
                                                                                <i className="fa fa-angle-down" style={{ color: 'white' }} aria-hidden="true"></i>
                                                                            </span>
                                                                        </>}
                                                                    className='theme-widget'
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <span style={{ paddingRight: '15px' }}> {value.title}</span>
                                                                    {/* <span style={{  }}> {value.count}</span> */}
                                                                </AccordionSummary>
                                                                <AccordionDetails>

                                                                    {Object.entries(value?.measures).map(([key, inner]) => {
                                                                        return <>
                                                                            <br />
                                                                            <Accordion>
                                                                                <AccordionSummary
                                                                                    expandIcon={<i className="fa fa-angle-down" style={{ color: 'white' }} aria-hidden="true"></i>}
                                                                                    className='theme-widget'
                                                                                    aria-controls="panel1a-content"
                                                                                    id="panel1a-header"
                                                                                >

                                                                                    <span style={{ paddingRight: '15px' }}>  {inner.measureComment}</span>

                                                                                </AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    <i className="fa fa-balance-scale" aria-hidden="true"></i>&nbsp; &nbsp; <b>Legislation Title:</b>  {inner.legislationTitle}<br /><br />
                                                                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp; &nbsp; <b>Legislation Summary:</b>  {inner.legislationSummary}<br /><br />
                                                                                    <i className="fa fa-bank" aria-hidden="true"></i>&nbsp; &nbsp; <b>Implementation Authority:</b>  {inner.implementationAuthority}<br /><br />
                                                                                    <i className="fa fa-hourglass-half" aria-hidden="true"></i>&nbsp; &nbsp; <b>Validity:</b>  from {inner.startDate}<br /><br />
                                                                                    <i className="fa fa-globe" aria-hidden="true"></i>&nbsp; &nbsp; <b>Other countries affected:</b>  {inner.additionalCommentCountry}<br /><br />
                                                                                    <a download href={inner.webLink}>Document</a><br /><br />
                                                                                    <a target="_blank" download href={'https://www.macmap.org' + inner.textLink}>Link</a>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </>

                                                                    })}


                                                                </AccordionDetails>
                                                            </Accordion>


                                                        </>
                                                    })}

                                                </AccordionDetails>
                                            </Accordion>
                                        </>

                                    </> :
                                    <i>India does not apply any regulatory requirements on the selected product. </i>
                            }
                        </>
                        : <i>To see this Section, Please Select Trade Agreement from screen 1 (Search custom duty).</i>}

                </>
                : <Skeleton variant="rectangular" sx={{ fontSize: '1rem' }} height={100} />
            }
            <br /><br />

            {
                allProductList ?
                    <>

                        {Object.entries(allProductList).length ?
                            <>

                                <>
                                    <Accordion className='border-line'>
                                        <AccordionSummary
                                            expandIcon={<i className="fa fa-angle-down" style={{ color: 'white' }} aria-hidden="true"></i>}
                                            className='theme-widget'
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <span style={{ paddingRight: '15px' }}> {allProductList.title}</span>
                                            <span style={{ marginLeft: 'auto' }}>Total Measures : {Object.entries(allProductList?.measures).length}</span>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            {Object.entries(allProductList?.measures).map(([key, value]) => {
                                                return <>
                                                    <br></br>
                                                    <Accordion className='border-line'>
                                                        <AccordionSummary
                                                            expandIcon={<i className="fa fa-angle-down" style={{ color: 'white' }} aria-hidden="true"></i>}
                                                            className='theme-widget'
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            {value.title}
                                                        </AccordionSummary>
                                                        <AccordionDetails>

                                                            {Object.entries(value?.measures).map(([key, inner]) => {
                                                                return <>
                                                                    <br></br>
                                                                    <Accordion className='border-line'>
                                                                        <AccordionSummary
                                                                            expandIcon={<i className="fa fa-angle-down" style={{ color: 'white' }} aria-hidden="true"></i>}
                                                                            className='theme-widget'
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                        >

                                                                            {inner.measureComment}

                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <i className="fa fa-balance-scale" aria-hidden="true"></i>&nbsp; &nbsp; <b>Legislation Title:</b>  {inner.legislationTitle}<br /><br />
                                                                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp; &nbsp; <b>Legislation Summary:</b>  {inner.legislationSummary}<br /><br />
                                                                            <i className="fa fa-bank" aria-hidden="true"></i>&nbsp; &nbsp; <b>Implementation Authority: </b> {inner.implementationAuthority}<br /><br />
                                                                            <i className="fa fa-hourglass-half" aria-hidden="true"></i>&nbsp; &nbsp; <b>Validity: </b> from {inner.startDate}<br /><br />
                                                                            <i className="fa fa-globe" aria-hidden="true"></i>&nbsp; &nbsp; <b>Other countries affected:</b>  {inner.additionalCommentCountry}<br /><br />
                                                                            <i className="fa fa-book" aria-hidden="true"></i>&nbsp;&nbsp; <a target="_blank" download href={inner.webLink}>Document</a> <br /><br />
                                                                            <i className="fa fa-link" aria-hidden="true"></i>&nbsp;&nbsp; <a target="_blank" download href={'https://www.macmap.org' + inner.textLink}>Link</a>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </>

                                                            })}


                                                        </AccordionDetails>
                                                    </Accordion>


                                                </>
                                            })}

                                        </AccordionDetails>
                                    </Accordion>
                                </>

                            </> :
                            null}
                    </>
                    : null
            }



        </React.Fragment >
    );
}
