import {createSlice} from "@reduxjs/toolkit";

const initialState = {url: null}

const RouterRedirectSlice = createSlice({
    name: 'routerRedirect',
    initialState,
    reducers: {
        redirectTo: (state, action) => {
            console.log(action);
            state.url = action.payload
        }
    }
})

export const {redirectTo} = RouterRedirectSlice.actions;
export default RouterRedirectSlice.reducer;
