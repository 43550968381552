import React from 'react';
import { lookupTypeFormValidation } from '../../../utils/schemas/index';
import { useFormik } from 'formik';
import { showLoader, hideLoader } from "../../../services/core/loader-service";
import { showSuccessToaster } from "../../../utils/toaster/toaster";
import { addLookupType, editLookupType } from "../../../services/lookup-type-service";
import { finalize } from "rxjs";


export const AddEditLookupTypePage = (props) => {

    let initValue = {
        name: '',
        key: '',
    };


    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: props.editmode ? props.initialData.data : initValue,
        validationSchema: lookupTypeFormValidation,
        onSubmit: (value) => {
            if (props.action == 'Add') {
                create(value)
            } else {
                update(value)
            }
        }
    })


    const create = (params) => {
        let payload = { ...params };
        showLoader();
        let addSubscription = addLookupType({ data: payload })
            .pipe(finalize(() => {
                hideLoader();
                addSubscription = null;
            }))
            .subscribe(response => {
                showSuccessToaster(response.message);
                closeAndUpdateModel();
            });
    }


    const closeAndUpdateModel = () => {
        props.closeModal(false);
        props.onHide();
    }


    const update = (params) => {
        let payload = { ...params };
        showLoader();
        let id = props.initialData.data.id;
        let updateSubscription = editLookupType({ data: payload }, id)
            .pipe(finalize(() => {
                hideLoader();
                updateSubscription = null;
            }))
            .subscribe(response => {
                showSuccessToaster(response.message);
                closeAndUpdateModel();
            });
    }

    return (

        <form onSubmit={handleSubmit}>
            <div className="input-container">
                <label>Name <sup>*</sup></label>
                <input type="text" className="input-control" placeholder="Enter Name" name="name" value={values.name} onChange={handleChange} />
                {errors && <p className='error'>{errors.name}</p>}
            </div>
            <div className="input-container">
                <label>Key <sup>*</sup></label>
                <input type="text" className="input-control" placeholder="Enter Key" name="key" value={values.key} onChange={handleChange} />
                {errors && <p className='error'>{errors.key}</p>}
            </div>

            <div className='input-container'>
                <button type="submit" className="btn btn-primary" >Submit</button>&nbsp;
            </div>
        </form>
    );
}